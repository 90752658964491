import styled from "styled-components";
import { Props } from "./index";
import icon from "../../../../assets/images/list_circle.svg";

const MaterialsListContainer = styled.ul<Props>`
	display: flex;
	flex-direction: column;
	min-width: 270px;
	max-width: 350px;
	min-height: 200px;
	padding-left: 21px;
	position: relative;
    z-index: 1;
	li {
		font-weight: normal;
		font-size: 14px;
		line-height: 17px;
		list-style: none;
		color: ${(props) => props.theme.color.primary};
		a {
			color: ${(props) => props.theme.color.primary};
			text-decoration: none;
			transition: 0.3s;
			&:hover {
				opacity: 0.6;
			}
		}
		&:not(:last-child) {
			margin-bottom: 9px;
		}
		&:before {
			content: "";
			display: inline-block;
			height: 10px;
			width: 10px;
			background-image: url(${icon});
			background-size: contain;
			background-repeat: no-repeat;
			padding-left: 10px;
		}
	}
`;
export { MaterialsListContainer };
