import styled from "styled-components";
import media from "../../../../styles/media";

const ViewClassHeaderContainer = styled.div`
	width: 100%;
	span.release {
		${media.lessThan("small")`
        visibility: hidden;
    `}
	}
`;

export { ViewClassHeaderContainer };
