import React from 'react';
import {
  CardWrapper,
  LoadingPlaceholder,
} from '../..';
import {
  LessonCardContainer, LessonCardHeader, LessonCardContent, LessonCardFooter,
} from './LessonCardStyled';

const LessonCardWrapper: React.FC = () => (
  <LessonCardContainer>
    <CardWrapper padding="0" margin="0">
      <LessonCardHeader>
        <LoadingPlaceholder variant="onelinetext" />
      </LessonCardHeader>
      <LessonCardContent>
        <LoadingPlaceholder variant="text" linesNumber={12} />
      </LessonCardContent>
      <LessonCardFooter>
        <LoadingPlaceholder variant="onelinetext" width="70%" />
        <LoadingPlaceholder variant="roundedImage" height="20px" width="20px" />
      </LessonCardFooter>
    </CardWrapper>
  </LessonCardContainer>
);

export { LessonCardWrapper as LessonCardLoading };
