import styled, { css } from "styled-components";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { IconContainer } from "../../../Icons/IconWrapperStyled";
import { CardWrapper, CardFooter } from "../CardStyleBase";
import { Props } from "./index";
import media from "../../../../../styles/media";

const ModuleCardContainer = styled(CardWrapper)<Props>`
	cursor: pointer;
	background: ${(props) => (props.module.cor ? fade(props.module.cor, 0.2) : fade(props.theme.color.primary, 0.05))};
	border-radius: 6px;
	${IconContainer} {
		border: 2px solid ${(props) => fade(props.theme.color.primary, 0.5)};
	}
	${CardFooter} {
		margin-top: 18px;
	}
	.arrowWrapper {
		svg {
			color: ${(props) => {
				if (props.module.cor) {
					return props.module.cor;
				}
				return props.theme.color.primary;
			}};
		}
	}
	${media.lessThan("small")`
        width: 100% !important;
    `}
`;
const CommentsTag = styled.span`
	white-space: nowrap;
	display: inline-block;
	margin-top: 4px;
	padding: 5px;
	background: red;
	color: #fff;
	border-radius: 6px;
	font-size: 0.7rem;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	font-weight: 700;
	max-width: 145px;
`;

export { ModuleCardContainer, CommentsTag };
