import React from 'react';
import { Emoji } from 'emoji-mart';

import { Reaction } from '../../../types/debate';
import { ReactionEmojiCounterContainer } from './ReactionEmojiCounterStyled';

export interface Props extends React.HTMLAttributes<HTMLElement> {
    emoji: Reaction
    emojiSize?: number
    fontSize?: string
    fontColor?: string
    padding?: string
    backgroundColor?: string
    transparent?: boolean
  height?: string
}

const ReactionEmojiCounter: React.FC<Props> = ({
  emoji,
  emojiSize,
  ...props
}: Props) => (
  <ReactionEmojiCounterContainer emoji={emoji} {...props}>
    <Emoji emoji={emoji.name} size={emojiSize || 16} />
    <span className="counter">{emoji.count}</span>
  </ReactionEmojiCounterContainer>
);

ReactionEmojiCounter.defaultProps = {
  backgroundColor: '#EFEFEF',
  transparent: false,
  fontSize: '0.75rem',
  fontColor: '#828282',
  padding: '4px 7px',
  height: 'auto',
};

export { ReactionEmojiCounter };
