import React, { useCallback, useEffect, useState } from "react";

import { LastArticlesContainer } from './LastArticlesStyled'
import ArticlesService from "../../services/articles.service";
import loadingGif from "../../assets/images/loading.gif";
import { IArticle } from "../../types/articles"
import { Typography } from "../Typography";
import { Button } from "../elements";
import { useHistory } from "react-router-dom";

export interface Props {
  limit?: number
}

const LastArticlesWrapper: React.FC<Props> = ({
  ...props
}: Props) => {
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory()


  const getArticles = useCallback(async () => {
    setLoading(true)

    const articlesService = new ArticlesService();
    const response = await articlesService.list();

    const l = props.limit ? props.limit : 1

    setArticles(response.results.slice(0, l));
    setLoading(false)
  }, []);

  useEffect(() => {
    getArticles();
  }, []);
  return (
    <LastArticlesContainer {...props}>
      <Typography variant="title" primary>Último Artigo</Typography>
      {loading ?
        <img src={loadingGif} />
        : (<>{
          articles.map((article: IArticle) => {
            return (<React.Fragment key={article.slug}>
              <Typography variant="uppertitle" size="1.3rem" margin="1rem 0 .5rem">{article.titulo}</Typography>
              <Typography variant="text" margin="1rem 0">
                {article.resumo ? `${article.resumo.substring(0, 300)}...` : ""}
              </Typography>
              <div style={{ textAlign: 'center' }}>
                <Button variant="outlined" color="primary" onClick={() => history.push(`/artigo/${article.slug}`)}>Ler Mais</Button>
              </div>
            </React.Fragment>)
          })
        }</>)}
    </LastArticlesContainer>
  );
};

LastArticlesWrapper.defaultProps = {

}

export { LastArticlesWrapper as LastArticles };