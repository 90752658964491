import styled from "styled-components";
import { Props } from "./index";

const FloatingCardContainer = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid ${(props) => props.theme.color.primary};
	border-bottom: 0px;
	border-radius: 6px;
	position: relative;
	width: 300px;
	background: #fff;

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: -11px;
		right: 41px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 20.5px 27px 20.5px;
		border-color: transparent transparent #fff transparent;
	}
	&::after {
		z-index: -1;
		border-width: 0 9.5px 12px 9.5px;
		top: -12px;
		right: 52px;
		border-color: transparent transparent ${(props) => props.theme.color.primary} transparent;
	}
`;
const FloatingCardFooter = styled.div`
	background-color: ${(props) => props.theme.color.primary};
	padding: 10px 15px;
	display: flex;
	justify-content: space-between;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
`;

const FloatingCardHeader = styled.div`
	display: flex;
	align-items: center;
	padding: 15px;
	border-bottom: 1px solid ${(props) => props.theme.color.primary};
`;

const FloatingCardWrapper = styled.div<Props>`
	position: absolute;
	right: ${(props) => props.right};
	left: ${(props) => props.left};
	top: ${(props) => props.top};
	bottom: ${(props) => props.bottom};
`;

export { FloatingCardContainer, FloatingCardFooter, FloatingCardHeader, FloatingCardWrapper };
