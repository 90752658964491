import styled, { isStyledComponent } from "styled-components";
import { Props } from "./index";
import { darken } from "polished";
import { SquareCardLink } from "../../components/elements";

let tcomp = isStyledComponent(SquareCardLink) ? SquareCardLink : styled(SquareCardLink);

const DownloadsContainer = styled.div<Props>`
	//display: flex;
	.favWrapper {
		position: relative;
		.favBtn {
			position: absolute;
			top: 10px;
			right: 10px;
			z-index: 20;
			transition: 0.3s;
		}
	}
	.clearFilter {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 30px;
		span {
			text-decoration: none;
			white-space: nowrap;
			border-radius: 100px;
			font-weight: 400;
			height: 30px;
			padding: 0 20px;
			line-height: 30px;
			font-size: 0.75rem;
			color: #222b37;
			cursor: pointer;
			box-sizing: border-box;
			background-color: #f2f2f2;
			border: 1px solid #e0e0e0;
			transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

			margin: 5px 5px 0 5px;

			&:focus {
				border: 1px solid #e0e0e0;
			}
			&:hover {
				background-color: ${darken(0.2, "#E0E0E0")};
			}
		}
	}
`;
const ViewMoreWrapper = styled.div<Props>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 120px;
`;
export { DownloadsContainer, ViewMoreWrapper };
