import React from 'react';
import { RecomendedRibbonContainer } from './RecomendedRibbonStyled';

export interface Props {
background?: string;
}

const RecomendedRibbonWrapper: React.FC<Props> = ({
  ...props
}: Props) => (
  <RecomendedRibbonContainer {...props}>
    <span>
      Recomendo
    </span>
  </RecomendedRibbonContainer>
);

export { RecomendedRibbonWrapper as RecomendedRibbon };
