import styled, { keyframes } from "styled-components";
import { lighten } from "polished";


const pulse = keyframes`
  0% {
    top: 75px;
    left: 75px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 150px;
    height: 150px;
    opacity: 0;
  }
`;

const simpleKeyframe = keyframes`
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
`;

const LoadingContainer = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f6f8f9;
	.lds-ripple {
		display: inline-block;
		position: relative;
		width: 200px;
		height: 200px;

		div {
			position: absolute;
			border: 4px solid #c7c4c4;
			opacity: 1;
			border-radius: 50%;
			animation: ${pulse} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
		}
		div:nth-child(2) {
			animation-delay: -0.5s;
		}
	}
`;


const ModalLoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 60px 0;
	.ldio-7wm71l9lbps {
		width: 100%;
		height: 100%;
		position: relative;
		transform: translateZ(0) scale(1);
		backface-visibility: hidden;
		transform-origin: 0 0;
		div {
			box-sizing: border-box !important;
			box-sizing: content-box;
		}
		& > div {
			position: absolute;
			width: 74.88px;
			height: 74.88px;
			top: 14.56px;
			left: 14.56px;
			border-radius: 50%;
			border: 8.32px solid #000;
			border-color: ${(props) => props.theme.color.primary} transparent ${(props) => props.theme.color.primary} transparent;
			animation: ${simpleKeyframe} 1s linear infinite;
			&:nth-child(2) {
				border-color: transparent ${(props) => lighten(0.15, props.theme.color.primary)} transparent ${(props) => lighten(0.15, props.theme.color.primary)};
			}
			&:nth-child(3) {
				border-color: transparent;
				div {
					position: absolute;
					width: 100%;
					height: 100%;
					transform: rotate(45deg);
					&:after {
						left: -8.32px;
						top: 24.96px;
						box-shadow: 66.56px 0 0 0 ${(props) => props.theme.color.primary};
					}
				}
			}
			&:nth-child(4) {
				border-color: transparent;
				div {
					position: absolute;
					width: 100%;
					height: 100%;
					transform: rotate(45deg);
					&:after {
						left: -8.32px;
						top: 15.600000000000001px;
						box-shadow: 47.84px 0 0 0 ${(props) => lighten(0.15, props.theme.color.primary)};
					}
				}
			}
		}
	}
	.ldio-7wm71l9lbps > div:nth-child(2),
	.ldio-7wm71l9lbps > div:nth-child(4) {
		width: 56.160000000000004px;
		height: 56.160000000000004px;
		top: 23.92px;
		left: 23.92px;
		animation: ${simpleKeyframe} 1s linear infinite reverse;
	}
	.ldio-7wm71l9lbps > div:nth-child(3) div:before,
	.ldio-7wm71l9lbps > div:nth-child(3) div:after {
		content: "";
		display: block;
		position: absolute;
		width: 8.32px;
		height: 8.32px;
		top: -8.32px;
		left: 24.96px;
		background: ${(props) => props.theme.color.primary};
		border-radius: 50%;
		box-shadow: 0 66.56px 0 0 ${(props) => props.theme.color.primary};
	}
	.ldio-7wm71l9lbps > div:nth-child(4) div:before,
	.ldio-7wm71l9lbps > div:nth-child(4) div:after {
		content: "";
		display: block;
		position: absolute;
		width: 8.32px;
		height: 8.32px;
		top: -8.32px;
		left: 15.600000000000001px;
		background: ${(props) => lighten(0.15, props.theme.color.primary)};
		border-radius: 50%;
		box-shadow: 0 47.84px 0 0 ${(props) => lighten(0.15, props.theme.color.primary)};
	}
	.loadingio-spinner-double-ring-l3mpkapk3qg {
		width: 104px;
		height: 104px;
		display: inline-block;
		overflow: hidden;
		background: #ffffff;
	}
`;

export { LoadingContainer, ModalLoadingContainer };
