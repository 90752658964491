import styled from "styled-components";
import _ from "lodash";
import media from "../../styles/media";
import { darken } from "polished";
import { Props } from "./index";
import { CircularAvatarPhotoContainer } from "../elements/CircularAvatarPhoto/CircularAvatarPhotoStyled";
// import { ChangeUserAvatarButtonContainer } from "../features/ChangeUserAvatarButton/ChangeUserAvatarButton/ChangeUserAvatarButtonStyled";

const topPosition = function () {
	return window.pageYOffset !== 0 ? "15px" : "30px";
};

window.addEventListener(
	"scroll",
	_.debounce((v) => {
		topPosition();
	}, 50)
);

topPosition();

const StudentDashboardHeaderContainer = styled.div<Props>`
	display: flex;
	flex-wrap: wrap;
	background: #fff;
	width: 100%;
	height: calc(100% - (${(props) => props.theme.headerHeight} - 50px));
	z-index: 1200;
	top: -25px;
	border-bottom: 1px solid #f2f2f2;
	${media.lessThan("medium")`
	  top: 0px;
    justify-content: center;
    height: auto;
  `}
	${CircularAvatarPhotoContainer} {
		margin: 0 auto 20px;
		position: fixed;
		top: ${topPosition};
		left: calc(52.5% - 7px);
		transform: translateX(-50%);
		z-index: 1205;
		${media.lessThan("medium")`
			margin: 0 0 20px;
			top: 20px;
			left: 0;
			z-index: 0;
			position: relative;
			transform: translateX(0);
		`}
	}
`;

const StudentInfoWrapper = styled.div<Props>`
	width: 100%;
	text-align: center;
	margin-bottom: 15px;
	padding: 120px 0 0;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	.info {
		width: 100%;
	}
	span {
		display: block;
		font-size: 1.25rem;
	}
	address {
		opacity: 0.7;
		font-style: normal;
	}
	${media.lessThan("medium")`
    	padding: 20px 0 0;
  	`}
	.logoutPill {
		display: inline-block;
		background: ${(props) => props.theme.color.primary};
		color: #fff;
		margin: 20px 5px 0;
		padding: 4px 15px;
		border-radius: 20px;
		font-size: 0.9rem;
		text-transform: uppercase;
		transition: 0.3s;
		cursor: pointer;
		&:hover {
			background: ${(props) => darken(0.08, props.theme.color.primary)};
		}
	}
`;

const BadgesGroup = styled.div<Props>`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 8px;
	.badgesTitle {
		width: 100%;
		font-weight: bold;
		text-align: center;
		opacity: 0.7;
	}
`;

export { StudentDashboardHeaderContainer, StudentInfoWrapper, BadgesGroup };
