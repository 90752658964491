import styled from 'styled-components';
import theme from 'styled-theming';
import { Props } from './index';

const tagBackgroundColor = theme.variants('mode', 'type', {
  general: { default: '#DD0000' },
  class_released: { default: '#FF884D' },
  assistant: { default: '#DFB63A' },
  performance: { default: '#9B51E0' },
  monitor: { default: '#05BEB6' },
});

const tagColor = theme.variants('mode', 'type', {
  general: { default: '#FFF' },
  class_released: { default: '#FFF' },
  assistant: { default: '#FFF' },
  performance: { default: '#FFF' },
  monitor: { default: '#FFF' },
});

const NotificationTagContainer = styled.div<Props>`
  background-color: ${(props) => (props.type !== 'custom' ? tagBackgroundColor : props.backgroundColor)};
  border-radius: 6px;
  padding: 6px 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${(props) => (props.type !== 'custom' ? tagColor : props.color)};
  text-transform: uppercase;
  display: inline-block;
`;

export { NotificationTagContainer };
