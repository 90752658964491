import BaseService from "./base.service";
import api from "./api";
import { headers } from "./config";

export default class ResetPasswordService extends BaseService {
	constructor() {
		super(`usuarios/reset-password`);
	}
	async reset(email) {
		let resourceURL = `usuarios/reset-password/`;

		try {
			const response = await api.post(
				resourceURL,
				{
					"email": email,
				},
				headers
			);
			console.log("RESPONSE RESET PASS ==>", response.data);
			return response.data;
		} catch (err) {
			console.error(`Erro Reset Pass ${resourceURL} base service:`, err);
			console.error(`Erro Reset Pass ${resourceURL} base service:`, err.response);
			return {
				error: true,
				status: err.response ? err.response.status : null,
				data: err.response ? err.response.data : null,
			};
		}
	}
}
