import styled from "styled-components";
import media from "../../styles/media";
import { ICourseContainerProps } from "./CourseDetailContent";
import loadingGif from "../../assets/images/loading.gif";

const CourseDetailContainer = styled.div<ICourseContainerProps>`
	display: grid;
	grid-template-columns: 350px calc(100% - 360px);
	grid-template-rows: ${(props) => (props.hasTwoButton ? "180px" : props.hasOneButton ? "90px" : "0px")} auto;
	//grid-auto-rows: auto;
	//align-items: start;
	${media.lessThan("medium")`
        display: flex;
        flex-direction: column;
  	`}
	.colunaModulos {
		${media.lessThan("medium")`
			position: relative;
			z-index: 9999999;
		`}
		${media.greaterThan("medium")`
			position: fixed;
			/* top: 161px;
			left: 112px;
			left: calc(8vw - 10px); */
			top: 161px;
			max-height: 65vh;
			overflow: auto;
			padding-right: 10px;
			transition: .4s ease;
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: thin;  /* Firefox */
			scrollbar-color: #f1f1f1 #888;
			&.multimediaModule {
				top: 201px;
				max-height: 55vh;
				padding: 0 10px 0 0;
				width: 360px;
			}
		`}
		${media.greaterThan("huge")`
			/* top: 17vh;
			left: calc(15vw - 10px); */
			max-height: 75vh;
		`}
		&::-webkit-scrollbar {
			width: 5px;
		}
		/* Track */
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #888;
		}
		&.scroll {
			${media.greaterThan("medium")`
				top: 92px;
				max-height: 75vh;
			`}
			${media.greaterThan("huge")`
				/* top: 8.5vh; */
				max-height: 82vh;
			`}
		}
	}
`;

const ClassContentWrapper = styled.div`
	margin: 23px 0 41px 0;
	/* display: flex;
    flex-direction: column; */
	background: linear-gradient(180deg, rgba(254, 254, 254, 0) 89.06%, #fefefe 94.65%, #fefefe 98.01%);
	border: 1px solid #efefef;
	box-sizing: border-box;
	border-radius: 6px;
	width: 100%;
	p {
		line-height: 1.2em;
		margin: 0 0 20px;
		a {
			color: ${(props) => props.theme.color.primary};
			text-decoration: underline;
			transition: 0.3s;
			word-break: break-all;
			&:hover {
				opacity: 0.6;
			}
		}
	}
`;

const VideoWrapper = styled.div`
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	overflow: hidden;
	width: 100%;
	position: relative;
	&:before {
		display: block;
		content: "";
		padding-top: 56.25%;
	}
	${media.lessThan("medium")`
        height: auto;
    `}
	iframe, &:after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	iframe {
		z-index: 10;
	} //
	&:after {
		display: block;
		content: "";
		background: url(${loadingGif}) no-repeat center white;
		background-size: 80px;
		z-index: 5;
	}
`;

const ModulesListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	${media.lessThan("medium")`
        position: fixed;
        top: 60px;
        width: 100%;
        z-index: 1204;
        left: 0;
        background: #FFF;
        height: calc(100vh - 60px);
        overflow: scroll;
        align-items: center;
        padding-top: 70px;
    `}
`;

const ShowModulesListButton = styled.div`
	padding: 10px 0;
	width: 100%;
	background: ${(props) => props.theme.color.primary};
	font-weight: 400;
	color: #fff;
	line-height: 20px;
	font-size: 0.75rem;
	text-transform: uppercase;
	text-align: center;
	display: none;
	margin-top: 20px;
	${media.lessThan("medium")`
    	display: block;
  	`}
`;

const CloseModulesListButtonWrapper = styled.div`
	background: #fff;
	position: fixed;
	height: 70px;
	top: 60px;
	z-index: 1300;
	width: 100%;
	display: flex;
	justify-content: center;
`;

const CloseModulesListButton = styled.div`
	width: 40px;
	height: 40px;
	background: ${(props) => props.theme.color.primary};
	border-radius: 100px;
	position: absolute;
	top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const LeiaMaisButtonWrapper = styled.div`
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 34%);
	min-height: 80px;
	padding: 25px 0 15px;
	text-align: center;
	margin: -20px 0 0;
	position: absolute;
	width: 100%;
	z-index: 10;
	bottom: 0;
	left: 0;
	${media.lessThan("medium")`
		position: relative;
		background: #FFF;
  	`}
	button {
		background: none;
		font-size: 1.125rem;
		color: ${(props) => props.theme.color.primary};
		cursor: pointer;
	}
`;

const RefreshCommentsButton = styled.button`
	display: flex;
	align-items: center;
	color: rgba(0, 0, 0, 0.7);
	background: none;
	cursor: pointer;
	padding: 8px;
	border-radius: 5px;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0);
	transition: 0.3s ease;
	position: relative;
	top: 0;
	&:hover {
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
		color: rgba(0, 0, 0, 0.8);
		top: -2px;
	}
	${media.lessThan("medium")`
		margin: 0 0 30px;
  	`}
`;

export {
	CourseDetailContainer, //
	ClassContentWrapper,
	VideoWrapper,
	ModulesListWrapper,
	ShowModulesListButton,
	CloseModulesListButton,
	CloseModulesListButtonWrapper,
	LeiaMaisButtonWrapper,
	RefreshCommentsButton,
};
