import styled from "styled-components";

const TeacherRatingContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
`;
export { TeacherRatingContainer };
