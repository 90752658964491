import api from "./api";
import BaseService from "./base.service";

export default class ArticleDebatesService extends BaseService {
	constructor(id, page) {
		if (!id) throw new Error("Missing Article Id when trying to retrieve debates");
		if (page) {
			super(`artigos/${id}/comentarios/?page=${page}`);
		} else {
			super(`artigos/${id}/comentarios`);
		}
	}

	async like(commentId) {
		try {
			const response = await api.post(`artigos/comentarios/like/`, {
				"entidade_pk": commentId,
			});
			//console.log("<< CURTIDO >>", response);
			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao curtir ${this.resource} :`, err);
			console.error(`[BS] Erro ao curtir ${this.resource} :`, err.response);
			return {
				error: true,
				status: err.response ? err.response.status : null,
				data: err.response ? err.response.data : null,
			};
		}
	}
}
