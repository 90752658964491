import React, { useCallback, useEffect, useState } from "react";
import { Box, Divider } from "@material-ui/core";
import { Typography, PageContainer, GreyContainer, InnerContentContainer, ContentLoadingError, FavoriteButton } from "../../components";
import loadingGif from "../../assets/images/loading.gif";
import { Button, SquareCard } from "../../components/elements";
import { CreateArticleDebate } from "../../components/features/Debate";

import { useUserContext } from "../../context/user/AuthContext";

import { IoMdThumbsUp } from "react-icons/io";

import {
	ArticleDetailContainer,
	ArticleHeaderSection,
	TitleSection,
	ArticleTitle,
	ArticleSubTitle,
	ArticleMeta,
	ButtonSection,
	ArticleFeaturePhoto,
	SuggestedArticlesContainer,
	ArticleBody,
	ArticleLoader,
	//LoadErrorMessage,
	LikeButton,
	ArticleCredits,
} from "./ArticleDetailStyled";

// import ArticleMock from "./ArticleMock";
import ArticlesService from "../../services/articles.service";
import ArticleDebatesService from "../../services/articleDebatesService.service";
import { useLocation, useParams } from "react-router-dom";
import { DebateItem } from "../../components/features/Debate";

import formatComments from "../../helpers/formatDebateItem";

export interface Props {
	background?: string;
}

interface IArticle {
	categorias: any[];
	comentarios__count: number;
	comentarios__criado_por__count: number;
	criado_por: {
		id: number;
		imagem_url: string;
		nome: string;
		username: string;
	};
	curtido: boolean;
	curtidas__count: number;
	data_publicacao: string;
	id: number;
	imagens: any[];
	last_comment: string;
	resumo?: string;
	slug: string;
	subtitulo: string | null;
	tags: any[];
	texto: string;
	titulo: string;
	credito: string;
	fonte: string;
}
interface IOtherArticle {
	categorias: any[];
	data_publicacao: string;
	resumo?: string;
	slug: string;
	subtitulo?: string;
	tags: any[];
	titulo: string;
	url: string;
	capa: {
		imagem: string;
		imagem_thumbnail: string;
		titulo: string;
	};
}

const ArticleDetail: React.FC<Props> = ({ children, ...props }) => {
	const { userData } = useUserContext();
	const { slug } = useParams<{ slug: string }>();
	const { pathname } = useLocation();
	const [article, setArticle] = useState({} as IArticle);
	const [otherArticles, setOtherArticles] = useState<IOtherArticle[]>([]);
	const [loadingArticle, setLoadingArticle] = useState(true);
	const [loadError, setLoadError] = useState(false);
	const [loadOthersError, setLoadOthersError] = useState(false);
	const [isLiked, setIsLiked] = useState(false);
	const [likesCount, setLikesCount] = useState(0);

	const [articleDebatesPage, setArticleDebatesPage] = useState(1);
	const [articleDebates, setArticleDebates] = useState<any[]>([]);

	const [displayLoadMoreDebatesButton, setDisplayLoadMoreDebatesButton] = useState(false);

	const setPageNumber = useCallback((nextUrl) => {
		if (nextUrl) {
			const page = nextUrl.split("=")[1];
			setArticleDebatesPage(page);
		}
	}, []);

	const getDebates = useCallback(
		async (id) => {
			try {
				const articleDebatesService = new ArticleDebatesService(id);
				const response = await articleDebatesService.list(true);

				//console.log("Comentários do Artigo", response.results);
				//console.log("Comentarios Formatados => ", formatComments(response.results));
				setArticleDebates(formatComments(response.results));
				if (response.next) {
					setPageNumber(response.next);
					setDisplayLoadMoreDebatesButton(true);
				}
			} catch (error) {
				//console.log("Error fetching debates for this article", error);
			}
		},
		// eslint-disable-next-line
		[article, slug]
	);

	const loadMoreComments = useCallback(async () => {
		if (articleDebatesPage < 2) return;
		try {
			const articleDebatesService = new ArticleDebatesService(article.id, articleDebatesPage);
			const response = await articleDebatesService.list();

			//console.log("Debates da aula", response);
			setArticleDebates([...articleDebates, ...formatComments(response.results)]);

			if (response.next) {
				setPageNumber(response.next);
				setDisplayLoadMoreDebatesButton(true);
			}
		} catch (error) {
			//console.log("error fetching comments", error);
		}
		// eslint-disable-next-line
	}, [article, slug]);

	const getArticle = useCallback(async () => {
		setLoadingArticle(true);
		try {
			const articlesService = new ArticlesService();
			const response = await articlesService.get_by_slug(slug);
			//console.log("response from article details", response);
			getDebates(response.id);
			setArticle(response);
			setIsLiked(response.curtido);
			setLikesCount(response.curtidas__count);
		} catch (error) {
			setLoadError(true);
		}
		setLoadingArticle(false);
	}, [article, slug]);

	const getOtherArticles = useCallback(async () => {
		try {
			const articlesService = new ArticlesService();
			const response = await articlesService.list();

			//console.log("list from article detail", response);

			const articlesToShow = response.results.filter((artigo: any) => {
				return artigo.slug !== slug;
			});

			setOtherArticles(articlesToShow.slice(0, 4));
		} catch (error) {
			setLoadOthersError(true);
		}
	}, [slug]);

	const convertYoutubeLinkToIframes = useCallback((articleBody: string) => {
		// eslint-disable-next-line
		const regExYoutube = /\s*[a-zA-Z\/\/:\.]*youtu(be.com\/watch\?v=|.be\/)([a-zA-Z0-9\-_]+)([a-zA-Z0-9\/\*\-\_\?\&\;\%\=\.]*)/gi;

		const newArticleBody = articleBody.replace(regExYoutube, '<div class="iframeContainerArtigo"><iframe src="//www.youtube.com/embed/$2" allowfullscreen></iframe></div>');
		return newArticleBody;
	}, []);

	const trucateString = useCallback((txt: string | undefined, charCount: number) => {
		if (txt && txt.length > charCount) {
			return `${txt.substring(0, charCount)}[...]`;
		}
		return txt;
	}, []);

	const normalizeDate = useCallback((date: string) => {
		const dateToNormalize = new Date(date);

		const parsedDate = Date.parse(date);

		if (isNaN(parsedDate)) {
			if (date === undefined) return "Sem data";
			const arrayOfDateComponents = date.split("/");
			return `
      ${arrayOfDateComponents[0]}/
      ${arrayOfDateComponents[1]}/
      ${arrayOfDateComponents[2].split(" ")[0]}`;
		}

		return dateToNormalize.toLocaleDateString("pt-BR");
	}, []);

	const likeArticle = useCallback(
		async (id) => {
			const articlesService = new ArticlesService();
			const response = await articlesService.like(id);
			//console.log("CURTIDA ==> ", response);
			if (isLiked) {
				setLikesCount(likesCount - 1);
			} else {
				setLikesCount(likesCount + 1);
			}
			setIsLiked(!isLiked);
		},
		[isLiked, likesCount]
	);

	useEffect(() => {
		window.scrollTo(0, 0);
		getArticle();
		getOtherArticles();
	}, [slug, pathname]);

	return (
		<PageContainer showBreadcrumb pageTitle='Exibindo Artigo' plusExclusive>
			<GreyContainer>
				<InnerContentContainer>
					<ArticleDetailContainer {...props}>
						{loadingArticle && ( // pq eu fiz assim? não faço ideia, me pareceu mais bonitinho na hora =D
							<ArticleLoader>
								<img src={loadingGif} alt='' className='loadingGif' />
							</ArticleLoader>
						)}
						{!loadingArticle && (
							<>
								{!loadError ? (
									<>
										<ArticleHeaderSection>
											<TitleSection>
												<ArticleTitle>{article.titulo}</ArticleTitle>
												{article.subtitulo && <ArticleSubTitle>{article.subtitulo}</ArticleSubTitle>}
												<ArticleMeta>{`${article.criado_por.nome} em ${article.data_publicacao}`}</ArticleMeta>
											</TitleSection>
											<ButtonSection>
												<LikeButton isLiked={isLiked} onClick={() => likeArticle(article.id)}>
													{isLiked ? (
														<>
															<span>Você curtiu este artigo</span>
															<IoMdThumbsUp size={20} />
															<span>{likesCount > 0 && likesCount}</span>
														</>
													) : (
														<>
															<span>Curtir Artigo</span>
															<IoMdThumbsUp size={20} />
															<span>{likesCount > 0 && likesCount}</span>
														</>
													)}
												</LikeButton>
												<FavoriteButton refID={article.id} favType="ARTIGO" buttonStyle="full" />
											</ButtonSection>
										</ArticleHeaderSection>
										{article.imagens.length > 0 && (
											<ArticleFeaturePhoto>
												<img src={article.imagens[0].imagem} alt='' />
												<img src={loadingGif} alt='' className='loadingGif' />
											</ArticleFeaturePhoto>
										)}
										<ArticleBody
											dangerouslySetInnerHTML={{
												__html: convertYoutubeLinkToIframes(article.texto),
											}}></ArticleBody>
										{article.fonte && (
											<>
												<ArticleCredits>
													<b>Fonte: </b>
													<span dangerouslySetInnerHTML={{ __html: article.fonte }}></span>
												</ArticleCredits>
											</>
										)}
										{article.credito && (
											<>
												<ArticleCredits>
													<b>Créditos: </b>
													<span dangerouslySetInnerHTML={{ __html: article.credito }}></span>
												</ArticleCredits>
											</>
										)}
										<Box margin='0 0 30px 0' display='flex' justifyContent='space-between'>
											<Typography variant='title'>Comentários</Typography>
											<CreateArticleDebate articleId={article.id} successCallback={() => getDebates(article.id)} />
										</Box>
										{articleDebates ? (
											<>
												{articleDebates.map((debate) => (
													<DebateItem
														isAuthorUser={debate.comment.author.id === userData.user_id}
														entityId={article.id}
														key={debate.id}
														debateItem={debate}
														type='article'
														replySuccessCallBack={() => getDebates(article.id)}
														onAction={() => { }}
													/>
												))}
												{displayLoadMoreDebatesButton && (
													<>
														<Box margin='20px 0 10px 0' display='flex' justifyContent='center'>
															<Button
																variant='contained'
																color='primary'
																onClick={() => {
																	loadMoreComments();
																}}>
																Carregar mais comentários
															</Button>
														</Box>
													</>
												)}
											</>
										) : (
											<ContentLoadingError refresh={() => getDebates(article.id)} loadedResourceErrorMsg='os comentários' />
										)}
									</>
								) : (
									<ContentLoadingError refresh={getArticle} loadedResourceErrorMsg='o artigo' />
								)}
							</>
						)}
					</ArticleDetailContainer>
				</InnerContentContainer>

				<Divider />

				<InnerContentContainer>
					<SuggestedArticlesContainer>
						<Typography variant='title' color='#014491' margin='0 0 20px'>
							Leia Também
						</Typography>
						<Box
							width='100%'
							display='grid'
							gridTemplateColumns={{
								xs: "100%",
								sm: "100%",
								md: "24% 24% 24% 24%",
								// xl: '19% 19% 19% 19% 19%',
							}}
							gridRowGap='15px'
							gridColumnGap='1%'>
							{loadOthersError ? (
								<>
									<ContentLoadingError refresh={getOtherArticles} loadedResourceErrorMsg='as recomendações' />
								</>
							) : (
								<>
									{otherArticles ? (
										otherArticles.map((article, index) => {
											return (
												<SquareCard
													key={index}
													to={`/artigo/${article.slug}`}
													imageurl={article.capa?.imagem_thumbnail || "https://ajuda.nossomundoazul.com.br/other-assets/artigos-fallback.jpg"}
													title={article.titulo}
													description={trucateString(article.resumo, 100)}
													meta={`${normalizeDate(article.data_publicacao)}`}
													tags={article.tags}></SquareCard>
											);
										})
									) : (
										<ContentLoadingError refresh={getOtherArticles} loadedResourceErrorMsg='as recomendações' />
									)}
								</>
							)}
						</Box>
					</SuggestedArticlesContainer>
				</InnerContentContainer>
			</GreyContainer>
		</PageContainer>
	);
};

export { ArticleDetail };
