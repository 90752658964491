import styled from "styled-components";
import { Props } from "./index";

const CommentLikeButtonContainer = styled.div<Props>`
	display: flex;
	align-items: center;
	.counter {
		font-size: 0.9rem;
		color: ${(props) => props.theme.color.primary};
		padding: 0 4px 0 0;
	}
	button {
		background: none;
		outline: none;
		cursor: pointer;
		margin-right: 4px;
		&:hover {
			svg {
				fill: ${(props) => props.theme.color.primary};
			}
		}
	}
	svg {
		fill: #fff;
		stroke: ${(props) => props.theme.color.primary};
		stroke-width: 30;
		transition: 0.2s;
		&.liked {
			fill: ${(props) => props.theme.color.primary};
		}
	}
`;
export { CommentLikeButtonContainer };
