import api from "./api";
import { getRefreshToken } from "./auth";
import { headers } from "./config";

export default class LoginService {
	private resource = "";
	private instance = api;
	constructor() {
		this.resource = "token/";
	}

	async login(username: string, password: string) {
		try {
			const response = await api.post(this.resource, { username, password }, headers);
			console.log("response from service", response);
			return { success: response.data };
		} catch (err) {
			console.log("erro durante login:", err);
			return { error: "usuário ou senha incorreto." };
		}
	}

	async refreshToken() {
		try {
			const response = await api.post(`${this.resource}refresh/`, { refresh: getRefreshToken() }, headers);
			localStorage.setItem("token", response.data.access);
			return response.data;
		} catch (err) {
			console.log("erro durante refresh:", err);
			// console.log("erro durante refresh:", err.response);
			localStorage.removeItem("token");
			localStorage.removeItem("refresh_token");
			if (window.location.pathname !== "/") {
				localStorage.setItem("redirectUrl", window.location.pathname);
			}
			const redirectLogin = `${window.location.origin}/`;
			//window.location = redirectLogin;
		}
	}
}
