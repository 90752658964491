import styled from 'styled-components'
import { Props } from './index';

const ImageWrapperContainer = styled.div<Props>`
  width: ${props => props.width};
  height: ${props => props.height};
  img {
    filter: ${props => props.locked ? 'blur(2px) saturate(0)' : ''}
  }
`


export { ImageWrapperContainer }