import styled, { keyframes, css } from "styled-components";

import { fade, darken } from "@material-ui/core/styles";
import media from "../../../styles/media";

interface iButtonContainer {
	variant?: "outlined" | "contained" | "link" | "save" | "add" | "loading";
	color?: "primary" | "default" | undefined;
	display?: string;
	margin?: string;
	title?: string;
}

const buttonVariations = {
	default: css`
		background-color: #fff;
		color: #333333;
	`,
	primary: css`
		background-color: ${(props) => props.theme.color.primary};
		color: #fff;
	`,
};

const ButtonContainer = styled.button<iButtonContainer>`
	border-radius: 100px;
	margin: ${(props) => props.margin};
	display: ${(props) => props.display};
	font-weight: 600;
	height: 45px;
	padding: 0 30px;
	line-height: 45px;
	font-size: 0.75rem;
	cursor: pointer;

	border: 0;
	box-sizing: border-box;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	text-transform: uppercase;
	&:focus,
	&::visited {
		outline: none;
	}
	${(props) => buttonVariations[props.color || "default"]}
	:disabled {
		background-color: lightgray;
		cursor: default;
		&:hover {
			background-color: lightgray;
		}
	}
	${media.lessThan("medium")`
    height: 35px;
    line-height: 35px;
    padding: 0 25px;
  `}
	&.outlined {
		color: ${(props) => (props.color === "primary" ? props.theme.color.primary : "#FFF")};
		border: 1px solid;
		background-color: transparent;
		border-color: ${(props) => {
			const color = props.color === "primary" ? props.theme.color.primary : "#FFF";
			return fade(color, 0.3);
		}};
	}
	&.link,
	&.add {
		background-color: transparent;
		color: ${(props) => (props.color === "primary" ? props.theme.color.primary : "#FFF")};
		border-radius: 0;
		padding: 0;
		height: auto;
		line-height: 1;
		a {
			padding: 5px;
			line-height: 2;
			height: auto;
		}
	}
	&.add {
		display: flex;
		align-items: center;
		color: ${(props) => props.theme.color.primary};
		div {
			margin-left: 12px;
			background: ${(props) => props.theme.color.primary};
		}
	}
	&.loading {
		display: flex;
		align-items: center;
	}
	&:hover {
		background-color: ${(props) => {
			const color = props.color === "primary" ? props.theme.color.primary : "#FFF";
			if (props.variant === "outlined" || props.variant === "link" || props.variant === "add") {
				return fade(color, 0.1);
			}
			return darken(color, 0.5);
		}};
	}
	a {
		height: 45px;
		color: inherit;
		text-decoration: inherit;
	}
	&.save,
	&.loading {
		> div {
			margin-right: 15px;
		}
		display: flex;
		align-items: center;
		padding: 0 30px 0 5px;
		color: ${(props) => (props.color === "primary" ? props.theme.color.primary : "#FFF")};
		border: 1px solid;
		background-color: transparent;
		background-color: ${(props) => {
			if (props.color === "primary") {
				const color = props.theme.color.primary;
				return fade(color, 0.3);
			} else {
				return "transparent";
			}
		}};
	}
`;

const rotateAnimation = keyframes`
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
`;

const LoadingButton = styled.div`
	height: 45px;
	display: flex;
	align-items: center;
	.ldio-tqeh7dyshyf {
		width: 100%;
		height: 100%;
		position: relative;
		transform: translateZ(0) scale(0.35);
		backface-visibility: hidden;
		transform-origin: 0 0;
		div {
			box-sizing: border-box !important;
			box-sizing: content-box;
		}
		& > div {
			position: absolute;
			width: 72px;
			height: 72px;
			top: 14px;
			left: 14px;
			border-radius: 50%;
			border: 8px solid #000;
			border-color: #fff transparent #fff transparent;
			animation: ${rotateAnimation} 1s linear infinite;
			&:nth-child(2) {
				border-color: transparent;
				div {
					position: absolute;
					width: 100%;
					height: 100%;
					transform: rotate(45deg);
					&:after {
						left: -8px;
						top: 24px;
						box-shadow: 64px 0 0 0 #fff;
					}
				}
			}
		}
	}
	.ldio-tqeh7dyshyf > div:nth-child(2) div:before,
	.ldio-tqeh7dyshyf > div:nth-child(2) div:after {
		content: "";
		display: block;
		position: absolute;
		width: 8px;
		height: 8px;
		top: -8px;
		left: 24px;
		background: #fff;
		border-radius: 50%;
		box-shadow: 0 64px 0 0 #fff;
	}
	.loadingio-spinner-dual-ring-xvfvwingcv {
		width: 35px;
		height: 35px;
		display: inline-block;
		overflow: hidden;
		background: none;
	}
`;

export { ButtonContainer, LoadingButton };
