import React from 'react';

import { IoMdClose } from 'react-icons/io';
import { IconWrapper } from '../IconWrapper';

export interface Props extends React.HTMLAttributes<HTMLElement> {
    size?: string
}

const RemoveIconWrapper: React.FC<Props> = ({
  children,
  ...props
}: Props) => (
  <IconWrapper
    {...props}
    size={props.size}
    rounded
    primaryColor
    filled={false}
    primaryBorder
    tooltipText="Remover"
    icon={<IoMdClose size={16} />}
  />
);

RemoveIconWrapper.defaultProps = {
  size: '28px',
};

export { RemoveIconWrapper as RemoveIcon };
