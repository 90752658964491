import React, { useState, useEffect, useCallback } from "react";
import {
	Box,
	Select,
	InputLabel,
	TextField,
	//FormControl,
	MenuItem,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import axios from "axios";

import { Button } from "../../../elements";
import { ModalBase } from "../../Producer_bkp/ModalBase";
import StudentProfileService, { IStudentApiResponse } from "../../../../services/student.profile.service";
import { phoneMask, postalCodeMask } from "../../../../lib/string";
import { Typography } from "../../../Typography";

export interface Props {
	student: IStudentApiResponse;
	onUpdateAction?: () => void;
}

interface IIBGEState {
	id: number;
	sigla: string;
	nome: string;
}

interface IIBGECity {
	id: number;
	nome: string;
}

interface IStudentFlags {
	notifica_artigo: boolean;
	notifica_curso: boolean;
	notifica_material: boolean;
	notifica_multimidia: boolean;
	notifica_posts: boolean;
	notifica_posts_interesse: boolean;
	is_familiar: boolean;
	is_profissional: boolean;
	is_publico: boolean;
	[key: string]: string | boolean; // ppermite usar key de forma dinâmica para acessar uma proriedade do objeto.
}

const UpdateUserProfileWrapper: React.FC<Props> = ({ student, onUpdateAction, ...props }: Props) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [states, setStates] = useState([""]);
	const [cities, setCities] = useState([""]);
	const [name, setName] = useState("");
	const [email, setEmail] = useState<string | undefined>("");
	const [nameInformation, setNameInformation] = useState("");
	const [presentation, setPresentation] = useState("");
	const [district, setDistrict] = useState("");
	const [state, setState] = useState("");
	const [city, setCity] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [age, setAge] = useState("");
	const [cellPhone, setCellPhone] = useState("");
	const [streetName, setStreetName] = useState("");
	const [streetNumber, setStreetNumber] = useState(0);
	const [streetAmmend, setStreetAmmend] = useState("");
	const [profession, setProfession] = useState("");
	const [studentFlags, setStudentFlags] = useState({} as IStudentFlags);
	const [dateOfBirth, setDateOfBirth] = useState("");

	// Popula a lista de estados
	useEffect(() => {
		try {
			axios.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados").then((response) => {
				const foundStates = response.data.map((IBGEstate: IIBGEState) => IBGEstate.sigla);
				setStates(foundStates);
			});
		} catch (error) {
			setStates([student?.uf]);
			setCities([student?.cidade]);
		}
	}, [student]);

	// Popula a lista de cidades
	useEffect(() => {
		try {
			axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`).then((response) => {
				const foundCities = response.data.map((IBGEcity: IIBGECity) => IBGEcity.nome);
				setCities(foundCities);
				if (!foundCities.includes(student.cidade) && student.cidade !== "") setCity(foundCities[0]);
			});
		} catch (error) {
			setCities([student.cidade]);
		}
	}, [state, student]);

	useEffect(() => {
		setName(student.nome);
		setEmail(student.email || undefined);
		setPresentation(student.apresentacao);
		setProfession(student.profissao);
		setCellPhone(phoneMask(student.celular || ""));
		setAge("20");
		setDateOfBirth(student.data_nascimento || "10/12/2018");

		if (student.cidade && student.uf) {
			setState(student.uf);
			setCity(student.cidade);
			setDistrict(student.bairro);
			if (student.cep) {
				setPostalCode(postalCodeMask(student.cep));
			}
		}
		setStreetName(student.logradouro);
		setStreetAmmend(student.complemento);
		setStreetNumber(Number(student.numero));

		setStudentFlags({
			notifica_artigo: student.notifica_artigo,
			notifica_curso: student.notifica_curso,
			notifica_material: student.notifica_material,
			notifica_multimidia: student.notifica_multimidia,
			notifica_posts: student.notifica_posts,
			notifica_posts_interesse: student.notifica_posts_interesse,
			is_familiar: student.is_familiar,
			is_profissional: student.is_profissional,
			is_publico: student.is_publico,
		});
	}, [student, modalOpen]);

	function handleModalClose() {
		setModalOpen(false);
	}

	function handleModalOpen() {
		setModalOpen(true);
	}

	const handleFlagChange = useCallback(
		(key: string) => {
			const getFlag = studentFlags[key];
			setStudentFlags({ ...studentFlags, [key]: !getFlag });
		},
		[studentFlags]
	);

	function handleUpdateStudentProfile(event?: React.FormEvent<HTMLFormElement>) {
		if (name.length === 0) {
			setNameInformation("Nome não pode estar em branco.");
			return;
		}
		if (event) {
			event.preventDefault();
		}
		const studentProfileService = new StudentProfileService();
		studentProfileService
			.update(
				student.id,
				{
					nome: name,
					uf: state,
					cidade: city,
					celular: cellPhone,
					bairro: district,
					cep: postalCode,
					apresentacao: presentation,
					logradouro: streetName,
					numero: streetNumber,
					profissao: profession,
					complemento: streetAmmend,
					// Flags
					notifica_artigo: studentFlags.notifica_artigo,
					notifica_curso: studentFlags.notifica_curso,
					notifica_material: studentFlags.notifica_material,
					notifica_multimidia: studentFlags.notifica_multimidia,
					notifica_posts: studentFlags.notifica_posts,
					notifica_posts_interesse: studentFlags.notifica_posts_interesse,
					is_familiar: studentFlags.is_familiar,
					is_profissional: studentFlags.is_profissional,
					is_publico: studentFlags.is_publico,
				},
				true // isUser
			)
			.then((response) => {
				if (response.error) {
					const fieldNamesError = Object.keys(response.data);
					fieldNamesError.forEach((fieldNameError) => {
						switch (fieldNameError) {
							case "nome":
								setNameInformation(response.data[fieldNameError]);
								break;
							default:
								break;
						}
					});
				} else {
					if (onUpdateAction) {
						onUpdateAction();
					}
					setModalOpen(false);
				}
			});
	}

	return (
		<>
			<Box display='flex' alignItems='center' justifyContent='center'>
				<span className='logoutPill' onClick={handleModalOpen}>
					EDITAR Perfil
				</span>
			</Box>
			<ModalBase title={`Editando perfil de ${student.nome}`} open={modalOpen} handleClose={handleModalClose} saveAction={handleUpdateStudentProfile}>
				<Box
					display='flex'
					alignItems='center'
					padding={{
						sm: "20px",
						md: "50px 100px",
					}}>
					<Box width='100%'>
						<form noValidate autoComplete='off' onSubmit={handleUpdateStudentProfile}>
							<Box
								display='grid'
								gridGap='20px'
								gridTemplateColumns={{
									sm: "1fr",
									//md: "25% 25% 25% 25%",
									md: "1fr 1fr 2fr",
								}}
								marginTop='16px'
								justifyContent='space-between'>
								<TextField
									id='name'
									fullWidth
									label='Nome'
									value={name}
									onChange={(e) => {
										setName(e.target.value);
									}}
									required
									helperText={nameInformation}
									error={nameInformation.length > 0}
									onFocus={() => setNameInformation("")}
									disabled
									margin='dense'
								/>
								<TextField
									id='email'
									fullWidth
									label='Email'
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									required
									helperText={nameInformation}
									error={nameInformation.length > 0}
									onFocus={() => setNameInformation("")}
									disabled
									margin='dense'
								/>

								<TextField
									margin='dense'
									id='profession'
									fullWidth
									label='Profissão'
									value={profession}
									onChange={(e) => {
										setProfession(e.target.value);
									}}
								/>
							</Box>

							<TextField
								margin='dense'
								id='presentation'
								fullWidth
								label='Apresentação Pessoal'
								value={presentation}
								onChange={(e) => {
									setPresentation(e.target.value);
								}}
							/>
							<Box
								display='grid'
								gridGap='20px'
								gridTemplateColumns={{
									sm: "100%",
									//md: "25% 25% 25% 25%",
									md: "2fr 0.4fr 1fr 1fr 1fr",
								}}
								marginTop='16px'
								justifyContent='space-between'>
								<TextField
									margin='dense'
									id='streetName'
									fullWidth
									label='Logradouro'
									value={streetName}
									onChange={(e) => {
										setStreetName(e.target.value);
									}}
								/>
								<TextField
									type='number'
									margin='dense'
									id='streetName'
									fullWidth
									label='Número'
									value={streetNumber}
									onChange={(e) => {
										setStreetNumber(Number(e.target.value));
									}}
								/>
								<TextField
									margin='dense'
									id='streetAmmend'
									fullWidth
									label='Complemento'
									value={streetAmmend}
									onChange={(e) => {
										setStreetAmmend(e.target.value);
									}}
								/>
								<TextField
									id='district'
									margin='dense'
									fullWidth
									label='Bairro'
									value={district}
									onChange={(e) => {
										setDistrict(e.target.value);
									}}
								/>
								<TextField
									id='postalCode'
									margin='dense'
									fullWidth
									label='CEP'
									value={postalCode}
									onChange={(e) => {
										setPostalCode(postalCodeMask(e.target.value));
									}}
								/>
							</Box>
							<Box
								display='grid'
								gridGap='20px'
								gridTemplateColumns={{
									sm: "100%",
									//md: "25% 25% 25% 25%",
									md: "auto 1fr 0.3fr 1fr",
								}}
								marginTop='16px'
								justifyContent='space-between'>
								<div>
									<InputLabel id='state'>Estado</InputLabel>
									<Select id='state' label='Estado' onChange={(e) => setState(String(e.target.value))} value={state}>
										{states.map((IBGEState) => (
											<MenuItem key={IBGEState} value={IBGEState}>
												{IBGEState}
											</MenuItem>
										))}
									</Select>
								</div>
								<div>
									<InputLabel id='city'>Cidade</InputLabel>
									<Select id='city' label='Cidade' onChange={(e) => setCity(String(e.target.value))} value={city} fullWidth>
										{cities.map((IBGECity) => (
											<MenuItem key={IBGECity} value={IBGECity} selected={IBGECity === city}>
												{IBGECity}
											</MenuItem>
										))}
									</Select>
								</div>
								<TextField
									id='age'
									fullWidth
									label='Dt. de Nasc.'
									value={dateOfBirth}
									disabled
									onChange={(e) => {
										setDateOfBirth(e.target.value);
									}}
								/>
								<TextField
									id='cellPhone'
									fullWidth
									label='Celular'
									value={cellPhone}
									onChange={(e) => {
										setCellPhone(phoneMask(e.target.value));
									}}
								/>
							</Box>
							<Box
								display='grid'
								gridGap='20px'
								gridTemplateColumns={{
									sm: "100%",
									//md: "25% 25% 25% 25%",
									md: "1fr 1fr 1fr",
								}}
								marginTop='16px'
								justifyContent='space-between'>
								<FormControlLabel
									control={<Checkbox checked={studentFlags.is_familiar} onChange={() => handleFlagChange("is_familiar")} name='is_familiar' color='primary' />}
									label='Familiar de pessoa com TEA?'
								/>
								<FormControlLabel
									control={<Checkbox checked={studentFlags.is_profissional} onChange={() => handleFlagChange("is_profissional")} name='is_profissional' color='primary' />}
									label='Profissional atuando com TEA?'
								/>
							</Box>
							<Typography variant='title' margin='25px 0 0'>
								Notificações
							</Typography>
							<Box
								display='grid'
								gridGap='20px'
								gridTemplateColumns={{
									sm: "100%",
									//md: "25% 25% 25% 25%",
									md: "1fr 1fr 1fr",
								}}
								marginTop='16px'
								justifyContent='space-between'>
								<FormControlLabel
									control={<Checkbox checked={studentFlags.notifica_artigo} onChange={() => handleFlagChange("notifica_artigo")} name='notifica_artigo' color='primary' />}
									label='Novos Artigos'
								/>
								<FormControlLabel
									control={<Checkbox checked={studentFlags.notifica_material} onChange={() => handleFlagChange("notifica_material")} name='notifica_material' color='primary' />}
									label='Novos Materiais'
								/>
								<FormControlLabel
									control={<Checkbox checked={studentFlags.notifica_multimidia} onChange={() => handleFlagChange("notifica_multimidia")} name='chenotifica_multimidiackedB' color='primary' />}
									label='Novas Séries'
								/>
							</Box>
							<Box
								display='grid'
								gridGap='20px'
								gridTemplateColumns={{
									sm: "100%",
									//md: "25% 25% 25% 25%",
									md: "1fr 1fr 1fr",
								}}
								marginTop='16px'
								justifyContent='space-between'>
								<FormControlLabel
									control={<Checkbox checked={studentFlags.notifica_curso} onChange={() => handleFlagChange("notifica_curso")} name='notifica_curso' color='primary' />}
									label='Atualizações de Cursos'
								/>
								<FormControlLabel
									control={<Checkbox checked={studentFlags.notifica_posts} onChange={() => handleFlagChange("notifica_posts")} name='notifica_posts' color='primary' />}
									label='Novos Tópicos da Comunidade'
								/>
								<FormControlLabel
									control={<Checkbox checked={studentFlags.notifica_posts_interesse} onChange={() => handleFlagChange("notifica_posts_interesse")} name='notifica_posts_interesse' color='primary' />}
									label='Novas respostas a seus comentários'
								/>
							</Box>
							<Box display='grid' gridGap='20px' gridTemplateColumns='1fr' marginTop='16px' justifyContent='space-between'>
								<FormControlLabel
									control={<Checkbox checked={studentFlags.is_publico} onChange={() => handleFlagChange("is_publico")} name='is_publico' color='primary' />}
									label='Disponibilizar informações publicamente para recomendação no site da Academia do Autismo'
								/>
							</Box>
						</form>
					</Box>
				</Box>
			</ModalBase>
		</>
	);
};

// UpdateUserProfileWrapper.defaultProps = {
// };

export { UpdateUserProfileWrapper as UpdateUserProfile };
