import styled from "styled-components";
import { lighten } from "polished";
import { Props } from "./index";

const ListPageResultContainer = styled.div<Props>`
	padding: 0 20px;
	width: 100%;
	background: #fff;
	border-radius: 6px;
	border: 1px solid #f2f2f2;
	margin: 15px 20px 0 15px;
	padding: 10px;
	position: relative;
	.icon {
		height: 100%;
		position: absolute;
		right: 10px;
		top: 0%;
		display: flex;
		align-items: center;
		svg {
			fill: ${(props) => props.theme.color.primary};
		}
	}
	&:hover {
		background: ${(props) => lighten(0.43, props.theme.color.primary)};
	}
`;
export { ListPageResultContainer };
