import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Box from "@material-ui/core/Box";
import _ from "lodash";
import {
	ViewClassHeader, MaterialsList, CourseInfo,
	// PreviewModuleClassCard, 
	NextPrevNavigation
} from "../../components/features/CourseDetail";

// import { DebateItem } from "../../components/features/Debate";
import { Typography, PageContainer, InnerContentContainer, GreyContainer, QuizContainer, ContentLoadingError, ClassFileUploader, ClassSentFilesList, VideoChapters, FavoriteButton, PandaVideo } from "../../components";
import { ModuleCardWrapper } from "../../components/elements";
import CourseDetailDebates from "./CourseDetailDebates";

import { IoIosClose } from "react-icons/io";

import loadingGif from "../../assets/images/loading.gif";

import {
	CourseDetailContainer,
	ClassContentWrapper,
	VideoWrapper,
	ModulesListWrapper,
	ShowModulesListButton,
	CloseModulesListButton,
	CloseModulesListButtonWrapper,
	LeiaMaisButtonWrapper,
} from "./CourseDetailStyled";
import { IVideoStats, Material, QuizAnswerResponse } from "../../types/course";
import { useCourseDetail } from "../../context/courseDetail/courseDetailContext";
// import { isMobile } from "../../isMobile";
import { DebateProvider } from "../../context/debate/debateContext";
import { IssueCertificateLink } from "../../components/elements/IssueCertificateLink";
import { Link } from "react-router-dom";
import VideoPlayerEventsService from "../../services/videoPlayerEvents.service";
import { ClassRatings } from "../../components/features/ClassRatings";

interface IPandaEvent {
	"message": string
	"video": string
	"currentTime": number,
	"isMutedIndicator": boolean,
	"videoDuration": number
}

export interface ICourseContainerProps {
	hasOneButton?: boolean;
	hasTwoButton?: boolean;
}

const CourseDetail: React.FC = ({ ...props }) => {
	const {
		loading, //
		course,
		openModuleList,
		showModuleList,
		closeModuleList,
		activeModule,
		activeModuleClass,
		isCourseFinished,
		courseCertificateUrl,
		sealAvailable,
		sealUrl,
	} = useCourseDetail()!;
	// const [hackKey, setHackKey] = useState(0);
	const [truncateDescription, setTruncateDescription] = useState(true);
	const [showTruncateButton, setshowTruncateButton] = useState(true);
	const [previousAnswers, setPreviousAnswers] = useState<QuizAnswerResponse[]>([]);
	const [hasScrolled, setHasScrolled] = useState(false);
	const [playing, setPlaying] = useState(false);

	const [lessonRating, setLessonRating] = useState<number>(0);
	const [lessonRatingKey, setLessonRatingKey] = useState<number>(0);

	const [sessionRatings, setSessionRatings] = useState<{ lid: number, rating: number }[]>([])

	const playerRef = useRef<ReactPlayer>(null);

	const toggleTruncateDescription = useCallback(() => {
		setTruncateDescription(!truncateDescription);
	}, [truncateDescription]);

	const addAnswerToList = (answer: QuizAnswerResponse) => {
		// console.log("ANSWER FROM PARENT => ", answer);
		setPreviousAnswers([...previousAnswers, answer]);
	};

	useEffect(() => {
		if (activeModuleClass) {
			if (activeModuleClass.isQuiz && activeModule?.quizObject) {
				setPreviousAnswers(activeModule.quizObject.avaliacoes_respondidas);
			}
			if (
				// Cortar a descrição apenas caso haja vídeo na aula. Se não houver, deixar o texto direto
				activeModuleClass.videoUrl &&
				activeModuleClass.description.length > 1600
			) {
				setTruncateDescription(true);
				setshowTruncateButton(true);
			} else {
				setTruncateDescription(false);
				setshowTruncateButton(false);
			}
		}

		windowVideoStats({
			isPaused: true,
			tempoPause: 0,
			tempoAtual: 0,
			ultimoTempoConhecido: 0,
		});


		const lessonSessionRating = sessionRatings.find((item: any) => {
			return item.lid === activeModuleClass?.id;
		})

		if (lessonSessionRating) {
			setLessonRating(lessonSessionRating.rating);
		} else {
			setLessonRating(activeModuleClass?.rating ? activeModuleClass?.rating : 0)
		}

		setLessonRatingKey(lessonRatingKey + 1);

	}, [activeModuleClass]);

	const handleModuleListPosition = useCallback(
		_.debounce(() => {
			let colunaModulos = document.querySelector("#colunaModulos");
			if (!colunaModulos) return;
			if (hasScrolled && window.pageYOffset > 100) return;
			if (window.pageYOffset === 0) {
				colunaModulos.classList.remove("scroll");
				setHasScrolled(false);
			} else {
				colunaModulos.classList.add("scroll");
				setHasScrolled(true);
			}
		}, 20),
		[]
	);

	const handleSessionRating = (lid: number, rating: number) => {
		let sIndex = sessionRatings.findIndex((item: any) => {
			return item.lid === lid
		})

		if (sIndex >= 0) {
			setSessionRatings([...sessionRatings.splice(sIndex, 1), { lid, rating }])
		} else {
			setSessionRatings([...sessionRatings, { lid, rating }])
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", handleModuleListPosition);
		// window.addEventListener("orientationchange", function () {
		// });
	}, []);

	const windowVisualizationID = (id: number) => {
		//@ts-ignore
		window.visualizationID = id
	}

	const getWindowVisualizationID = () => {
		//@ts-ignore
		return window.visualizationID
	}

	const windowVideoStats = (stats: IVideoStats) => {
		//@ts-ignore
		window.videoStats = { ...stats }
	}

	const getWindowVideoStats = (): IVideoStats => {
		//@ts-ignore
		return window.videoStats
	}

	const handleStartVideo = async (isPanda: boolean, pandaEvent?: IPandaEvent) => {
		let inicio, tempoTotal, ultTempo: number;
		let response;

		setPlaying(true);


		if (isPanda) {
			//@ts-ignore
			inicio = pandaEvent ? Math.round(pandaEvent.currentTime) : 0;
			//@ts-ignore
			tempoTotal = pandaEvent ? Math.round(pandaEvent.videoDuration) : 0;

		} else {
			//@ts-ignore
			inicio = Math.round(playerRef.current.getCurrentTime());
			//@ts-ignore
			tempoTotal = Math.round(playerRef.current.getDuration());

		}

		ultTempo = getWindowVideoStats().tempoAtual;

		windowVideoStats({
			...getWindowVideoStats(),
			isPaused: false,
			tempoAtual: inicio,
			ultimoTempoConhecido: ultTempo === inicio ? null : ultTempo,
		});

		const videoPlayerEventsService = new VideoPlayerEventsService();

		if (!getWindowVisualizationID()) {
			response = await videoPlayerEventsService.iniciarAssistir(activeModuleClass?.id, inicio, tempoTotal, getWindowVideoStats());
			windowVisualizationID(response.id);
		} else {
			response = await videoPlayerEventsService.iniciarAssistir(activeModuleClass?.id, inicio, tempoTotal, getWindowVideoStats(), getWindowVisualizationID());
			if (response.id !== getWindowVisualizationID()) {
				windowVisualizationID(response.id);
			}
		}
	};

	const handlePauseVideo = async (isPanda: boolean, pandaEvent?: IPandaEvent) => {
		setPlaying(false);

		if (!getWindowVisualizationID()) return;

		let tempoPause, tempoTotal: number;

		if (isPanda) {
			console.log("PANDA PAUSE", pandaEvent)
			//@ts-ignore
			tempoPause = Math.round(pandaEvent?.currentTime);
			//@ts-ignore
			tempoTotal = Math.round(pandaEvent?.videoDuration);

		} else {
			//@ts-ignore
			tempoPause = Math.round(playerRef.current.getCurrentTime());
			//@ts-ignore
			tempoTotal = Math.round(playerRef.current.getDuration());
		}

		windowVideoStats({
			isPaused: true,
			tempoPause,
			tempoAtual: tempoPause,
			ultimoTempoConhecido: tempoPause,
		});

		const videoPlayerEventsService = new VideoPlayerEventsService();

		await videoPlayerEventsService.pausarVideo(getWindowVisualizationID(), tempoPause, tempoTotal, getWindowVideoStats());
	};

	const handleOnProgressVideo = async (isFinished: boolean, isPanda: boolean, pandaEvent?: IPandaEvent) => {

		console.log({
			vid: getWindowVisualizationID(),
			vs: getWindowVideoStats()
		})

		if (!getWindowVisualizationID()) return;
		if (getWindowVideoStats().isPaused) return;

		if (isFinished) {
			setPlaying(false);
		}

		let tempoAtual: number;

		if (isPanda) {
			tempoAtual = pandaEvent ? Math.round(pandaEvent?.currentTime) : 0;
		} else {
			//@ts-ignore
			tempoAtual = Math.round(playerRef.current.getCurrentTime());
		}

		windowVideoStats({
			...getWindowVisualizationID(),
			isPaused: false,
			tempoAtual,
			ultimoTempoConhecido: tempoAtual,
		});

		const videoPlayerEventsService = new VideoPlayerEventsService();

		await videoPlayerEventsService.assistirVideo(getWindowVisualizationID(), tempoAtual, isFinished, getWindowVideoStats());
	};

	const pauseVideo = async () => {
		setPlaying(false);
	};

	return (
		<PageContainer hideBanner pageTitle={course?.name} showBreadcrumb showSearch={false} searchHelpText='Neste curso'>
			{loading ? (
				<Box display='flex' alignItems='center' justifyContent='center'>
					<img src={loadingGif} alt='' />
				</Box>
			) : (
				<>
					{course ? (
						<>
							<ShowModulesListButton onClick={() => openModuleList()}> Ver mais aulas </ShowModulesListButton>
							<GreyContainer>
								<InnerContentContainer>
									{" "}
									<CourseDetailContainer
										hasOneButton={isCourseFinished && course.isComplete && (!!courseCertificateUrl || sealAvailable)}
										hasTwoButton={sealAvailable && isCourseFinished && course.isComplete && courseCertificateUrl && courseCertificateUrl !== "#" && course.hasCertificate ? true : false}>
										<Box gridColumn='1' id='colunaModulos' className='colunaModulos'>
											{course && (
												<div>
													{isCourseFinished && course.isComplete && courseCertificateUrl && courseCertificateUrl !== "#" ? (
														<>
															<IssueCertificateLink certificateUrl={courseCertificateUrl} />
														</>
													) : (
														""
													)}
													{sealAvailable && isCourseFinished && course.isComplete && sealUrl ? (
														<>
															<IssueCertificateLink isSeal certificateUrl={sealUrl} />
														</>
													) : (
														""
													)}
												</div>
											)}
											{showModuleList && (
												<ModulesListWrapper>
													{window.innerWidth < 900 && (
														<CloseModulesListButtonWrapper>
															<CloseModulesListButton
																onClick={() => {
																	closeModuleList();
																}}>
																<IoIosClose size={30} color='#FFF' />
															</CloseModulesListButton>
														</CloseModulesListButtonWrapper>
													)}
													{course.modules?.map((module) => (
														<ModuleCardWrapper hasComments={module.hasComments} showArrow key={module.id} module={module} />
													))}
												</ModulesListWrapper>
											)}
										</Box>
										<Box gridColumn='2' gridRow='1/3' paddingLeft={{ xs: "0px", md: "21px" }} width='100%'>
											{!activeModule ? (
												<div>Nenhum modulo encontrado.</div>
											) : (
												<>
													{activeModule.name ? (
														<>
															{activeModuleClass && (
																<>
																	<ViewClassHeader activeLesson={activeModuleClass} name={activeModuleClass.name} moduleName={activeModule.nome} releaseDate={activeModuleClass.releaseDate} />
																	<ClassContentWrapper>
																		{activeModuleClass.videoUrl && (
																			<VideoWrapper>
																				{activeModuleClass.videoUrl.includes('pandavideo') ?
																					<PandaVideo url={activeModuleClass.videoUrl}
																						onPlay={(e) => handleStartVideo(true, e)}
																						onPause={(e) => handlePauseVideo(true, e)}
																						onProgress={(e) => handleOnProgressVideo(false, true, e)}
																						onEnded={(e) => handleOnProgressVideo(true, true, e)}
																						progressInterval={10}
																					/>
																					: <>
																						<ReactPlayer
																							controls //
																							width='100%'
																							height='100%'
																							url={activeModuleClass.videoUrl}
																							pip
																							progressInterval={10000}
																							ref={playerRef}
																							onPlay={() => handleStartVideo(false)}
																							onPause={() => handlePauseVideo(false)}
																							onProgress={() => handleOnProgressVideo(false, false)}
																							onEnded={() => handleOnProgressVideo(true, false)}
																							playing={playing}
																						/></>}
																			</VideoWrapper>
																		)}
																		{!activeModuleClass.isQuiz && <NextPrevNavigation />}
																		{!activeModuleClass.isQuiz &&
																			<ClassRatings
																				lessonID={activeModuleClass.id}
																				currentLessonRating={lessonRating}
																				key={lessonRatingKey}
																				onRate={(lid, rating) => handleSessionRating(lid, rating)}
																			/>}
																		<Box
																			display='flex'
																			flexDirection={{
																				xs: "column",
																				sm: "column",
																				md: "row",
																			}}
																			style={{ background: "#FFF", position: "relative" }}
																			justifyContent='space-between'
																			padding='31px 20px 31px 20px'>
																			<div>
																				{activeModuleClass.isQuiz && (
																					<>
																						{activeModule.quizObject ? (
																							<>
																								<p>
																									Você tem <b>{activeModule.quizObject?.chances}</b> chances para responder a este questionário. O gabarito das suas respostas será exibido ao esgotar
																									todas as chances de responder.
																								</p>
																								<p>
																									Seus Resultados Anteriores:
																									<br />
																									<br />
																									{previousAnswers.map((av: any, index) => {
																										return (
																											<p>
																												Tentativa {index + 1}: <b>{av.qtd_acertos}</b> acertos em <b>{av.qtd_questoes}</b> questões -{" "}
																												<b>
																													Nota:
																													{av.nota}%
																												</b>
																											</p>
																										);
																									})}
																									<br />
																								</p>
																							</>
																						) : (
																							""
																						)}
																						{activeModuleClass.description && (
																							<p style={{ marginBottom: "-10px" }}>
																								<b>Descrição do Questionário</b>
																							</p>
																						)}
																					</>
																				)}
																				{/*  */}
																				{activeModuleClass.videoUrl && activeModuleClass.timeline && activeModuleClass.timeline.length > 0 &&
																					<VideoChapters timeline={activeModuleClass.timeline} seekFunction={(to) => playerRef.current?.seekTo(to)} />
																				}
																				<CourseInfo
																					name={activeModuleClass.isQuiz ? "" : activeModuleClass.name}
																					favBtn={
																						<FavoriteButton
																							refID={activeModuleClass.id}
																							buttonStyle="simple"
																							favType="AULA"
																						/>
																					}
																					description={activeModuleClass.description}
																					truncateDescription={truncateDescription}
																				/>
																				{activeModuleClass.acceptsUpload && (
																					<>
																						<ClassFileUploader maxFiles={activeModuleClass.maxUploadableFiles} classId={activeModuleClass.id} />
																					</>
																				)}
																				{showTruncateButton && (
																					<LeiaMaisButtonWrapper>
																						<button
																							onClick={() => {
																								toggleTruncateDescription();
																							}}>
																							{truncateDescription ? "Leia Mais" : "Recolher"}
																						</button>
																					</LeiaMaisButtonWrapper>
																				)}
																			</div>

																			{activeModuleClass.materials.length > 0 && (
																				<MaterialsList>
																					<Typography variant='title' size='18px' margin='0 0 21px 0' fontWeight='bold'>
																						Materiais para Download
																					</Typography>
																					{activeModuleClass.materials.map((material: Material) => (
																						<li key={material.id}>
																							<a href={material.url} target='_blank' title={material.name}>
																								{material.name}
																							</a>
																						</li>
																					))}
																				</MaterialsList>
																			)}
																		</Box>
																		{/* BANNER MINI CURSO APLICADORES NO CURSO ABA PARA PAIS 2021 */}
																		{activeModuleClass.id === 855 && (
																			<Link to='/cursos/mini-curso-para-aplicadores' style={{ display: "block", width: "100%" }}>
																				<img
																					style={{ display: "block", width: "100%", zIndex: 1, position: "relative" }}
																					src='https://www.nossomundoazul.com.br/static/images/banner-modulo-extra-aba.jpg'
																				/>
																			</Link>
																		)}
																		{/* BANNER PÓS FORMAÇÃO 2020 */}
																		{activeModuleClass.id === 1042 && (
																			<a
																				href='https://pos.academiadoautismo.com.br/?utm_campaign=ciaautismo&utm_source=formatura-formacao&utm_medium=banner-aula'
																				target='_blank'
																				style={{ display: "block", width: "100%" }}>
																				<img style={{ display: "block", width: "100%" }} src='https://imersao.academiadoautismo.com.br/images/banner-pos.jpg' />
																			</a>
																		)}
																		<Box style={{ background: "#FFF", position: "relative", marginTop: "-50px" }} padding='0px 20px 31px 20px'>
																			{activeModuleClass.isQuiz && <QuizContainer onAnswer={addAnswerToList} moduleId={activeModule.id} quizObject={activeModule.quizObject} />}
																		</Box>
																	</ClassContentWrapper>
																	{activeModuleClass.showComments &&
																		<DebateProvider>
																			{!activeModuleClass.isQuiz && (
																				<CourseDetailDebates timestamp={activeModuleClass.timestamp} course={course} activeModuleClass={activeModuleClass} onAction={pauseVideo} />
																			)}
																		</DebateProvider>
																	}
																</>
															)}
														</>
													) : (
														<>
														</>
													)}
												</>
											)}
										</Box>
									</CourseDetailContainer>
								</InnerContentContainer>
							</GreyContainer>
						</>
					) : (
						<GreyContainer>
							<InnerContentContainer>
								<ContentLoadingError refresh={() => window.location.reload()} loadedResourceErrorMsg='as aulas' />
							</InnerContentContainer>
						</GreyContainer>
					)}
				</>
			)}
		</PageContainer>
	);
};

export default CourseDetail;
