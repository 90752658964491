import React, { ReactNode } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link, { LinkProps } from "@material-ui/core/Link";
import { IoIosArrowForward } from "react-icons/io";
import Typography from "@material-ui/core/Typography";
import { Route } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { BreadcrumbContainer } from "./BreadcrumbStyled";

export interface Props {
	background?: string;
	children?: ReactNode;
}

interface LinkRouterProps extends LinkProps {
	to: string;
	replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

const breadcrumbNameMap: { [key: string]: string } = {
	"/cursos": "cursos",
	"/cursos/meus-cursos": "meus cursos",
	"/cursos/minhas-trilhas": "minhas trilhas",
	"/noticias": "Notícias",
	"/artigos": "Artigos",
	"/materiais": "Materiais para Download",
	"/multimidia": "Séries e Palestras",
};

const BreadcrumbWrapper: React.FC<Props> = ({ children, ...props }: Props) => (
	<BreadcrumbContainer>
		<Route>
			{({ location }) => {
				const pathnames = location.pathname.split("/").filter((x) => x);
				return (
					<Breadcrumbs separator={<IoIosArrowForward size='9px' />} aria-label='Breadcrumb'>
						<RouterLink color='inherit' to='/'>
							Home
						</RouterLink>
						{/* Mudei a forma aqui pra botar os IF pra parar de dar os erros de que Breadcrumbs não aceitam fragment como retorno */}
						{pathnames.map((value, index) => {
							const last = index === pathnames.length - 1;
							const to = `/${pathnames.slice(0, index + 1).join("/")}`;
							if (breadcrumbNameMap[to]) {
								if (last) {
									return (
										<Typography color='textPrimary' key={to}>
											{breadcrumbNameMap[to]}
										</Typography>
									);
								} else {
									return (
										<LinkRouter color='inherit' to={to} key={to}>
											{breadcrumbNameMap[to]}
										</LinkRouter>
									);
								}
							} else {
								return "";
							}
						})}
					</Breadcrumbs>
				);
			}}
		</Route>
	</BreadcrumbContainer>
);

BreadcrumbWrapper.defaultProps = {
	background: "#FFF",
};

export { BreadcrumbWrapper as Breadcrumb };
