import React from 'react';
import { StudentsAvatarGroupContainer } from './StudentsAvatarGroupStyled';

import { CircularAvatarPhoto } from '../CircularAvatarPhoto';

import { Student } from '../../../types/course';
import { Typography } from '../../Typography';

export interface Props {
  students: Student[]
  avatarSize?: string
  studentsCount?: number
  fontSize?: string
}

const StudentsAvatarGroupWrapper: React.FC<Props> = ({
  students,
  avatarSize,
  studentsCount,
  ...props
}: Props) => (
  <StudentsAvatarGroupContainer {...props} avatarSize={avatarSize} studentsCount={studentsCount} students={students}>
    {students.map((student: Student) => (
      <CircularAvatarPhoto
        key={student.id}
        avatarImg={student.imageUrl}
        avatarName={student.name}
        borderColor="white"
        borderType="single"
        size={avatarSize}
        innerBorderSize="2px"
      />
    ))}
    <div className="studentsCount">
      <div className="roundContainer">
        {studentsCount
          ? <span className="studentsCount">{studentsCount > 30 ? '+30' : studentsCount}</span>
          : ''}
      </div>
    </div>
    <div className="helpText">
      <Typography variant="text" size="0.75rem">Alunos </Typography>
    </div>

  </StudentsAvatarGroupContainer>
);

StudentsAvatarGroupWrapper.defaultProps = {
  avatarSize: '35px',
  fontSize: '11px',
};

export { StudentsAvatarGroupWrapper as StudentsAvatarGroup };
