import React, { ReactNode } from 'react';
import styled from 'styled-components';

export interface Props {
    padding?: string;
    margin?: string;
    children?: ReactNode;
}

const CardFooterContainer = styled.div<Props>`
    padding: ${(props) => props.padding};
    margin: ${(props) => props.margin};
    margin-top: auto;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
`;

const CardFooter: React.FC<Props> = ({
  children,
  ...props
}: Props) => (
  <CardFooterContainer {...props}>
    {children}
  </CardFooterContainer>
);

CardFooter.defaultProps = {
  padding: '20px',
  margin: '0',
};

export { CardFooter };
