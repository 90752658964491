import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: será calculada com base em palette.primary.main,
      main: "#2D9CDB",
      // dark: será calculada com base em palette.primary.main,
      // contrastText: será calculada para contrastar com palette.primary.main
    },
    secondary: {
      light: "#0066ff",
      main: "#0044ff",
      // dark: será calculada com base palette.secondary.main,
      contrastText: "#ffcc00",
    },
    // Usado por `getContrastText()` para maximizar o contraste entre
    // o plano de fundo e o texto.
    contrastThreshold: 3,
    // Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
    // dois índices dentro de sua paleta tonal.
    // Por exemplo, mude de Red 500 para Red 300 ou Red 700.
    tonalOffset: 0.2,
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: "#2D9CDB",
    },
    MuiDrawer: {
      root: {
        backgroundColor: "#FFF",
      },
    },

    MuiDialogTitle: {
      root: {
        background: "#F2F2F2",
        color: "#2D9CDB",
      },
    },

    MuiDialogActions: {
      root: {
        backgroundColor: "#2D9CDB",
        justifyContent: "space-between",
        padding: "10px 20px",
      },
    },
  },
});

export default theme;
