import styled from "styled-components";
import media from "../../../../styles/media";
import { Props } from "./index";

const IssueCertificateButtonContainer = styled.div<Props>`
	margin-bottom: 10px;
	border-radius: 6px;
	padding: 25px 0;
	width: 100%;
	height: 80px;
	font-size: 1.125rem;
	font-weight: 700;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	background: ${(props) => props.theme.color.primary};
	svg {
		margin-right: 10px;
	}
	${media.lessThan("medium")`
  padding: 15px 0;
  height: auto;
 `}
`;
export { IssueCertificateButtonContainer };
