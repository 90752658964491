import styled from "styled-components";
import { darken } from "polished";
import { Link } from "react-router-dom";
import media from "../../../styles/media";

const SquareCardContainer = styled.a`
	text-decoration: none;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-radius: 5px;
	text-decoration: none;
	transition: 0.3s ease;
	position: relative;
	top: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	&:hover {
		top: -10px;
	}
`;

const CardImage = styled.div`
	width: 100%;
	position: relative;
	&:before {
		content: "";
		display: block;
		padding-top: 56.9%;
	}
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		&.loadingGif {
			z-index: 1;
			object-fit: none;
			width: 90px;
			height: 90px;
		}
	}
`;

const CardBottom = styled.div`
	background-color: #fff;
	padding: 13px 22px;
	border: 1px solid #efefef;
	border-top: none;
	flex: 1;
	small {
		color: #828282;
	}
`;

const CardTitle = styled.span`
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.2em;
	display: block;
	color: ${(props) => darken(0.01, props.theme.color.primary)};
`;

const CardMeta = styled.small`
	display: block;
	font-size: 0.7rem;
	line-height: 1.2em;
	opacity: 0.4;
`;

const CardDescription = styled.p`
	font-size: 0.9rem;
	line-height: 1.2em;
	margin: 27px 0 15px;
	color: #828282;
`;

const CardTagsWrapper = styled.div`
	/* background-color: #fff; */
	padding: 8px 0px;
	display: flex;
	//flex-wrap: wrap;
	overflow: auto;
	scrollbar-width: thin;
	scrollbar-color: #eee #fff;

	&::-webkit-scrollbar {
		//display: none;
		height: 10px;
		padding: 1px;
	}
	&::-webkit-scrollbar-thumb {
		background: #eee;
		opacity: 0.4;
		border-radius: 5px;
		border: 3px solid #fff;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;

const Tag = styled(Link)`
	text-decoration: none;
	white-space: nowrap;
	border-radius: 100px;
	font-weight: 400;
	height: 35px;
	padding: 0 20px;
	line-height: 30px;
	font-size: 0.75rem;
	color: #222b37;
	cursor: pointer;
	box-sizing: border-box;
	background-color: #f2f2f2;
	border: 1px solid #e0e0e0;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	margin: 5px 5px 0 0;

	&:focus {
		border: 1px solid #e0e0e0;
	}
	&:hover {
		background-color: ${darken(0.2, "#E0E0E0")};
	}
`;

const MaterialModal = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0%;
	left: 0%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
	.overlayModal {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.4);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999998;
	}
	.modalBody {
		position: relative;
		z-index: 999999;
		width: 90%;
		max-width: 450px;
		padding: 25px;
		border-radius: 10px;
		background: #fff;
		.buttonWrapper {
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
			margin-top: 24px;
			a,
			span {
				border-radius: 6px;
				padding: 12px;
				text-decoration: none;
				font-weight: 700;
				text-transform: uppercase;
				color: white;
				transition: 0.3s;
				cursor: pointer;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				&:hover {
					opacity: 0.7;
				}
				svg {
					margin-right: 6px;
				}
				${media.lessThan("medium")`
					width: 100%;
				`}
			}
			span {
				background: #ff4e50;
				width: 40%;
			}
		}
	}
`;
const ModalTitle = styled.div`
	color: ${(props) => props.theme.color.primary};
	font-weight: 700;
	text-align: center;
	margin-bottom: 24px;
	font-size: 1.125rem;
`;

const ModalDescription = styled.div`
	p,
	ul,
	ol {
		margin: 0 0 16px;
	}
	ul,
	ol {
		list-style-position: inside;
	}
	li {
		margin-bottom: 8px;
	}
	b,
	strong {
		font-weight: 700;
	}

	a {
		color: ${(props) => props.theme.color.primary};
	}
`;

const ModalButton = styled.a`
	background: #b3cc57;
	width: 51%;
	${media.lessThan("medium")`
		width: 100%;
		margin-bottom: 12px;
	`}
`;

export {
	SquareCardContainer, //
	CardImage,
	CardBottom,
	CardTitle,
	CardMeta,
	CardDescription,
	CardTagsWrapper,
	Tag,
	MaterialModal,
	ModalTitle,
	ModalDescription,
	ModalButton,
};
