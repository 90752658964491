import styled, { css } from 'styled-components';
import media from 'styled-media-query';

interface Props{
  isOpen: boolean
}

const DashboardCourseAccordionContainer = styled.div<Props>`
  display: flex;
  cursor: pointer;

  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  border: 1px;
  background: #FFF;
  background-clip: padding-box; /* !importanté */
  border: solid 1px transparent; /* !importanté */
  margin-bottom: 10px;
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  transition: all 5ms;

  .wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 3;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0px, #fafafa);
    height: 100%;
    padding: 20px;
    border-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
    ${(props) => !props.isOpen && css`
       border-bottom-left-radius: 6px;
       border-bottom-right-radius: 6px;
       background: #fff;
    `}
    ${media.lessThan('medium')`
        flex-wrap: wrap;
        padding: 15px;
    `}
    .iconSetinha {
      ${media.lessThan('medium')`
        display: none;
      `}
    }
    .typographyTop {
      ${media.lessThan('medium')`
          margin: 0 0 15px;
          width: 100%;
          position: relative;
      `}
      .iconSetinhaMobile {
        position: absolute;
        top: 5px; right: 5px;
        ${media.greaterThan('small')`
          display: none;
        `}
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 1;
    margin: -1px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(to bottom, rgba(237, 237, 237, 1), rgba(237, 237, 237, 0));
    transition: all 5ms;
  }
  ${(props) => !props.isOpen && css`
      border-bottom-left-radius: 1em;
      border-bottom-right-radius: 1em;
    &:before {
      background: #ededed;
    }
  `}
`;

const Wrapper = styled.div`
section{
    overflow: hidden;
  }
`;
export { DashboardCourseAccordionContainer, Wrapper };
