import BaseService from "./base.service";
import api from "./api";

export default class TermsService extends BaseService {
	constructor() {
		super("contrato/termos-uso");
	}

	async sign(options: any) {
		try {
			const response = await api.post(`${this.resource}/assinar/`, options, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			// console.log("Assinado com Sucesso => ", response.data);
			//console.log("OPTIONS", options);
			return response.data;
		} catch (err) {
			console.error(`erro ao assinar ${this.resource} :`, err);
			// console.error(`erro ao assinar ${this.resource} :`, err.response);
			return {
				error: true,
				// status: err.response ? err.response.status : null,
				// data: err.response ? err.response.data : null,
			};
		}
	}

	async getAceites() {
		try {
			const response = await api.get(`${this.resource}/aceitos/`);
			return response.data;
		} catch (err) {
			console.error(`erro ao listar aceites ${this.resource} :`, err);
		}
	}
}
