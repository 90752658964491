import React from "react";

// interface IBadge {
// 	name: string;
// 	image: string;
// }

export const Badges = {
	assinante: {
		name: "Aluno Plus do CIA Autismo",
		image: "https://lib-academia.s3.amazonaws.com/images/selos/selo_assinante.png",
	},
	cursoABA: {
		name: "Curso em Terapia ABA para Pais e Aplicadores",
		image: "https://lib-academia.s3.amazonaws.com/images/selos/selo_aba.png",
	},

	masterClass: {
		name: "Masterclass em Inclusão Escolar no TEA",
		image: "https://lib-academia.s3.amazonaws.com/images/selos/selo_master.png",
	},

	membroFundador: {
		name: "Membro Fundador",
		image: "https://lib-academia.s3.amazonaws.com/images/selos/selo_fundador.png",
	},

	formacaoAvancada: {
		name: "Formação Avançada em Terapia ABA no Autismo",
		image: "https://lib-academia.s3.amazonaws.com/images/selos/selo_formacao.png",
	},

	vbmapp: {
		name: "Curso Avançado em Protocolo VB-MAPP",
		image: "https://lib-academia.s3.amazonaws.com/images/selos/selo_vbmapp.png",
	},
};
