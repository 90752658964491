import React from "react";

interface IIconProps {
	color: string;
}

const SVG = ({ color }: IIconProps) => (
	<svg id='Componente_5_1' data-name='Componente 5 – 1' width='24' height='24' viewBox='0 0 28 28'>
		<defs>
			<linearGradient id='linear-gradient-downloads' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
				<stop offset='0' stopColor='#006df0' />
				<stop offset='1' stopColor='#00e7f0' />
			</linearGradient>
			<clipPath id='clip-path-downloads'>
				<rect id='Retângulo_83' data-name='Retângulo 83' width='28' height='28' transform='translate(-0.248 -0.248)' fill='url(#linear-gradient-downloads)' />
			</clipPath>
		</defs>
		<g id='Grupo_94' data-name='Grupo 94' transform='translate(0.248 0.248)' clipPath='url(#clip-path-downloads)'>
			<path
				id='Caminho_46'
				data-name='Caminho 46'
				d='M23.437,4.464H11.851L10.544,1.847A3.381,3.381,0,0,0,7.55,0h-4.2A3.351,3.351,0,0,0,0,3.348V21.763a3.351,3.351,0,0,0,3.348,3.348h2.79a1.116,1.116,0,1,0,0-2.232H3.348a1.117,1.117,0,0,1-1.116-1.116V11.718H21.2a1.116,1.116,0,0,0,0-2.232H2.232V3.348A1.117,1.117,0,0,1,3.348,2.232h4.2a1.131,1.131,0,0,1,1,.615l1.613,3.231a1.116,1.116,0,0,0,1,.618H23.437a1.117,1.117,0,0,1,1.116,1.116v13.95a1.117,1.117,0,0,1-1.116,1.116H20.681a1.116,1.116,0,1,0,0,2.232h2.755a3.351,3.351,0,0,0,3.348-3.348V7.812a3.351,3.351,0,0,0-3.348-3.348'
				transform='translate(0.36 0.36)'
				fill='url(#linear-gradient-downloads)'
			/>
			<path
				id='Caminho_47'
				data-name='Caminho 47'
				d='M136.6,207.581a1.116,1.116,0,0,0-1.578,0l-1.443,1.443v-7.908a1.116,1.116,0,0,0-2.232,0v7.908l-1.443-1.443a1.116,1.116,0,1,0-1.578,1.578l3.348,3.348a1.115,1.115,0,0,0,1.578,0l3.348-3.348a1.116,1.116,0,0,0,0-1.578'
				transform='translate(-118.713 -185.69)'
				fill='url(#linear-gradient-downloads)'
			/>
		</g>
	</svg>

	// <svg
	//   width="47"
	//   height="47"
	//   viewBox="0 0 47 47"
	//   fill="none"
	//   xmlns="http://www.w3.org/2000/svg"
	// >
	//   <path
	//     fillRule="evenodd"
	//     clipRule="evenodd"
	//     d="M20.7273 15.7272V7H36V39.7273H12V15.7272H20.7273ZM24 15C24.5523 15 25 15.4477 25 16L25 31.5858L30.2929 26.2929C30.6834 25.9024 31.3166 25.9024 31.7071 26.2929C32.0976 26.6834 32.0976 27.3166 31.7071 27.7071L24.7071 34.7071L24 35.4142L23.2929 34.7071L16.2929 27.7071C15.9024 27.3166 15.9024 26.6834 16.2929 26.2929C16.6834 25.9024 17.3166 25.9024 17.7071 26.2929L23 31.5858V16C23 15.4477 23.4477 15 24 15Z"
	//     fill="#013A7B"
	//   />
	//   <path
	//     d="M20.282 7L12 15.282V16.172H12.89L21.172 7.88997V7H20.282Z"
	//     fill="#013A7B"
	//   />
	// </svg>
);

export { SVG as DownloadsSidebarIcon };
