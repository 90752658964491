import React, { useState } from "react";
import loadingGif from "../../../assets/images/loading.gif";
import {
	SquareCardContainer, //
	CardImage,
	CardBottom,
	CardTitle,
	CardMeta,
	CardDescription,
	CardTagsWrapper,
	Tag,
	MaterialModal,
	ModalTitle,
	ModalDescription,
	ModalButton,
} from "./SquareCardLinkStyled";

import { MdFileDownload } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { trackGA } from "../../../helpers/trackEvent";
import DownloadsService from "../../../services/downloads.service";

export interface Props {
	href: string;
	title: string;
	imageurl?: string;
	tags?: string[];
	meta?: string;
	description?: string;
	excerpt?: string;
	variant: "download" | "link";
	category: {
		id: number;
		nome: string;
		descricao?: string;
		slug: string;
	};
	isModal?: boolean;
	downloadID?: number;
}

const SquareCardWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const [showModal, setShowModal] = useState(false);

	const countDownload = async (id: number) => {
		const downloadsService = new DownloadsService();
		const response = await downloadsService.mark_as_seen(id);
		//console.log("Material Baixado Comp. =>", response);
	};
	const trackMaterial = async (id: any) => {
		trackGA("materiais", "download", props.title);
		await countDownload(id);
	};
	const openModalDetails = async (isModal: any, id: any, e: any) => {
		if (!isModal) return await trackMaterial(id);
		e.preventDefault();
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	return (
		<>
			<SquareCardContainer {...props} target={props.variant === "download" ? "_blank" : "_self"} onClick={(e: any) => openModalDetails(props.isModal, props.downloadID, e)}>
				<CardImage>
					<img src={props.imageurl ? props.imageurl : "https://placeimg.com/640/480/nature"} alt={props.title} />
					<img className='loadingGif' src={loadingGif} alt='loading' />
					{/* <LoadingIcon /> */}
				</CardImage>
				<CardBottom>
					<CardTitle>{props.title}</CardTitle>
					{props.meta && <CardMeta>{props.meta}</CardMeta>}

					{props.excerpt && <CardDescription dangerouslySetInnerHTML={{ __html: props.excerpt }}></CardDescription>}
					<br />
					{props.category && (
						<>
							<small>Categoria</small>
							<CardTagsWrapper>
								<Tag to={`/materiais/categoria/${props.category.slug}`}>{props.category.nome}</Tag>
							</CardTagsWrapper>
						</>
					)}
				</CardBottom>
			</SquareCardContainer>
			{showModal && (
				<MaterialModal>
					<div className='overlayModal' onClick={closeModal}></div>
					<div className='modalBody'>
						<ModalTitle>{props.title}</ModalTitle>
						{props.description && <ModalDescription dangerouslySetInnerHTML={{ __html: props.description }} />}
						<div className='buttonWrapper'>
							<ModalButton href={props.href} target='_blank' onClick={() => trackMaterial(props.downloadID)}>
								<MdFileDownload size={20} color='#FFFFFF' />
								Baixar material
							</ModalButton>
							<span className='close' onClick={closeModal}>
								<IoMdClose size={20} color='#FFFFFF' />
								Fechar
							</span>
						</div>
					</div>
				</MaterialModal>
			)}
		</>
	);
};

SquareCardWrapper.defaultProps = {};

export { SquareCardWrapper as SquareCardLink };
