import styled, { css } from 'styled-components';
import { Props } from './index';

const DividerContainer = styled.div<Props>`
  height: ${(props) => props.height};
  position: relative;
  width: 100%;
  background: ${(props) => props.color};
  margin: ${(props) => props.margin};
  
  ${(props) => props.gradient && css`
    background: radial-gradient(ellipse farthest-side at top center, rgba(0,0,0,.08), transparent);
  `}
  
    
`;
export { DividerContainer };
