import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { StudentDashboardHeaderContainer, StudentInfoWrapper, BadgesGroup } from "./StudentDashboardHeaderStyled";
import { CircularAvatarPhoto } from "../elements/CircularAvatarPhoto";
import { isMobile } from "../../isMobile";
// import { userBadgesList } from "../../mockData/badgesMock";
import { useHistory } from "react-router-dom";
import { useUserContext } from "../../context/user/AuthContext";
import { GamificationBadge } from "../elements/GamificationBadge";

import { IGenericSeal, IStudentApiResponse } from "../../services/student.profile.service";

import { UpdateUserProfile as UpdateModal } from "../features/Student/UpdateStudentProfile";
import { UpdateUserPassword as UpdatePasswordModal } from "../features/Student/UpdateStudentPassword";
import { Typography } from "..";
// import { ChangeUserAvatarButton } from "../features/ChangeUserAvatarButton";

interface IBadge {
	name: string;
	image: string;
}
export interface Props {
	studentName?: string;
	studentAddress?: string;
	avatarUrl?: string;
	showLogout?: boolean;
	showBadges?: boolean;
	badges?: IBadge[];
	genericBadges?: IGenericSeal[];
	isMonitor?: boolean;
	isProfessor?: boolean;
	isTutor?: boolean;
	isPrivateProfile?: boolean;
	student?: IStudentApiResponse;
	onUpdateAction?: () => void;
}

// const badgesToShow = userBadgesList.filter(
// 	(badge) => badge.scope === "global" // trazer apenas badges com escobo global
// );

const StudentDashboardHeaderWrapper: React.FC<Props> = ({ genericBadges, onUpdateAction, student, showBadges, badges, avatarUrl, ...props }: Props) => {
	const [sizeOfAvatar, setSizeOfAvatar] = useState("140px");
	const { handleLogout } = useUserContext();
	const history = useHistory();

	function headerBehaviour() {
		if (!isMobile.any()) {
			// não redimensionar caso mobile
			if (window.pageYOffset > 0) {
				setSizeOfAvatar("90px");
			} else {
				setSizeOfAvatar("140px");
			}
		}
		// window.pageYOffset > 0 ? setSizeOfAvatar('90px') : setSizeOfAvatar('140px')
	}
	window.addEventListener(
		"scroll",
		_.debounce((v) => {
			headerBehaviour();
		}, 50)
	);
	useEffect(() => {
		headerBehaviour();
	}, []);
	const logout = useCallback(() => {
		handleLogout();
		history.push("/");
		handleLogout();
	}, [handleLogout, history]);
	return (
		<StudentDashboardHeaderContainer {...props}>
			<CircularAvatarPhoto
				avatarImg={avatarUrl}
				borderColor='primary'
				size={sizeOfAvatar}
				avatarName={props.studentName ? props.studentName : ""}
				borderType='double-white'
				isMonitor={props.isMonitor}
				isProfessor={props.isProfessor}
				isTutor={props.isTutor}
				isUserProfile={props.isPrivateProfile}
			/>
			{props.studentName ? (
				<StudentInfoWrapper>
					<div className='info'>
						<span>{props.studentName}</span>
						<address>{props.studentAddress ? props.studentAddress : "Local não Informado"}</address>
					</div>
					{student && (
						<>
							<UpdateModal student={student} onUpdateAction={onUpdateAction} />
							<UpdatePasswordModal student={student} />
						</>
					)}

					{props.showLogout && (
						<span
							className='logoutPill'
							onClick={() => {
								logout();
							}}>
							Sair
						</span>
					)}
				</StudentInfoWrapper>
			) : (
				""
			)}
			{showBadges && (
				<>
					<BadgesGroup>
						{badges ? (
							<>
								<BadgesGroup>
									{/* <Typography variant='text' className='badgesTitle'>
										Selos:
									</Typography> */}
									{badges.map((badge: IBadge, index: number) => {
										return <GamificationBadge key={index} size={40} imgOnUrl={badge.image} name={badge.name} isAcquired />;
									})}
								</BadgesGroup>
							</>
						) : (
							""
						)}
					</BadgesGroup>
					{genericBadges && genericBadges.length > 0 ? (
						<>
							<BadgesGroup>
								<Typography variant='text' className='badgesTitle'>
									Conquistas:
								</Typography>
								{genericBadges.map((badge: IGenericSeal, index: number) => {
									return <GamificationBadge key={index} size={50} imgOnUrl={badge.selo} name={`${badge.nome} - ${badge.tipo}`} isAcquired />;
								})}
							</BadgesGroup>
						</>
					) : (
						""
					)}
				</>
			)}
		</StudentDashboardHeaderContainer>
	);
};

export { StudentDashboardHeaderWrapper as StudentDashboardHeader };
