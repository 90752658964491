import styled from 'styled-components';

const LessonsHeader = styled.div`
  display: grid;
  background: #F2F2F2;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 6px;
  grid-template-columns: 428px 126px 64px 210px 243px auto; /* Depois vou arrumar um jeito melhor, prometo =D */
  padding: 15px 0;
  font-weight: 600;
  div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export { LessonsHeader };
