import React, { useEffect, useState } from 'react';
import { PandaVideoContainer } from './PandaVideoStyled'

type TPandaPlayerEventType =
  "panda_timeupdate" |
  "panda_progress" |
  "panda_ready" |
  "panda_play" |
  "panda_pause" |
  "panda_seeking" |
  "panda_seeked" |
  "panda_ended" |
  "panda_enterfullscreen" |
  "panda_exitfullscreen" |
  "panda_captionsenabled" |
  "panda_captionsdisabled" |
  "panda_languagechange" |
  "panda_canplay" |
  "panda_error" |
  "panda_speed_update"

interface IPandaPlayerEvents {
  data: {
    message: TPandaPlayerEventType,
    currentTime: number | string,
    video: string
  }
}

type IPandaPlayerEventHandlerOBJ = {
  [k in TPandaPlayerEventType]?: (e: any) => void
}

export interface Props {
  url: string
  progressInterval?: number;
  onPlay?: (e: any) => void;
  onPause?: (e: any) => void;
  onProgress?: (e: any) => void;
  onEnded?: (e: any) => void;
}

const PandaVideoWrapper: React.FC<Props> = ({
  ...props
}: Props) => {
  const loadPlayer = async (url: string): Promise<void> => {
    //@ts-ignore
    window.pandascripttag = window.pandascripttag || [];
    //@ts-ignore
    window.pandascripttag.push(function () {
      //@ts-ignore
      const PandaVideoPlayer = new PandaPlayer(`panda-video`, {
        //@ts-ignore
        onReady: () => {
          console.log("Player");
          const events = (event: IPandaPlayerEvents) => {
            //@ts-ignore
            const videoDuration = PandaVideoPlayer.getDuration()

            const { data } = event
            const evts: IPandaPlayerEventHandlerOBJ = {
              "panda_pause": props.onPause,
              "panda_play": props.onPlay,
              "panda_ended": props.onEnded,
              "panda_timeupdate": props.onProgress,
            }
            if (data.message === "panda_timeupdate") { // dispara o evento apenas no intevalo definido, caso tenha intervalo definido
              if (props.progressInterval) {
                //@ts-ignore
                if (PandaVideoPlayer.getCurrentTime() > props.progressInterval && window.lastTime + props.progressInterval < PandaVideoPlayer.getCurrentTime()) {
                  evts[data.message]?.({ ...event.data, videoDuration })
                  //@ts-ignore
                  window.lastTime = PandaVideoPlayer.getCurrentTime()
                }

              } else {
                evts[data.message]?.({ ...event.data, videoDuration })
              }

            } else {
              evts[data.message]?.({ ...event.data, videoDuration })
            }
          }

          window.addEventListener("message", (event) => events(event), false);
        },
        onError: (e: any) => {
          console.error("PANDA ERROR", e)
        }
      })

      console.log(PandaVideoPlayer)
    })
  }

  useEffect(() => {
    //@ts-ignore
    window.lastTime = 0;
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://player.pandavideo.com.br/api.v2.js";
    document.head.appendChild(script)
    script.onload = () => {
      loadPlayer(props.url)
    }

  }, [])


  return (
    <PandaVideoContainer {...props}>
      <iframe
        id={`panda-video`}
        src={`${props.url}`}
        // src={`https://b-vz-24ed4237-a7a.tv.pandavideo.com.br/459e78d1-19e1-470d-a818-89ba9a446b58/playlist.m3u8`}
        // src={`https://b-vz-24ed4237-a7a.tv.pandavideo.com.br/efbbdfb5-cb11-4891-b1f6-8c0a641d86b6/playlist.m3u8`}
        allowFullScreen
      >
      </iframe>
    </PandaVideoContainer>
  );
};

export { PandaVideoWrapper as PandaVideo };