import React, { ReactNode, useState } from "react";
import { Box } from "@material-ui/core";

import { AlertContainer } from "./AlertStyled";

import loadingGif from "../../../assets/images/loading.gif";
import CourseService from "../../../services/course.service";
export interface Props {
	text: string;
	variant?: "primary" | "warning" | "default" | "outlined";
	backgroundColor?: string;
	fontWeight?: string;
	textColor?: string;
	fontSize?: string;
	textAlign?: string;
	borderColor?: string;
	startAdornment?: ReactNode;
	endAdornment?: ReactNode;
	emitsCertificate?: boolean;
	link?: string;
	noPointer?: boolean;
}

const AlertWrapper: React.FC<Props> = ({ text, startAdornment, endAdornment, link, emitsCertificate, ...props }: Props) => {
	const [loadingCertificate, setLoadingCertificate] = useState(false);

	const getCertificado = async (certificateUrl: string) => {
		setLoadingCertificate(true);
		const courseService = new CourseService();
		const pdf = await courseService.get_certificate(certificateUrl);

		const blob = new Blob([pdf], { type: "application/pdf" });
		const blobURL = URL.createObjectURL(blob);
		var a = document.createElement("a");

		document.body.appendChild(a);
		a.setAttribute("style", "display: none");
		a.setAttribute("target", "_self");
		a.href = blobURL;
		a.download = certificateUrl.split("/")[7];

		a.click();

		window.URL.revokeObjectURL(blobURL);

		//window.open(blobURL);
		setLoadingCertificate(false);
	};

	return (
		<AlertContainer
			{...props}
			onClick={() => {
				if (link) {
					if (emitsCertificate) {
						return getCertificado(link);
					}
					return window.open(link);
				}
			}}>
			{loadingCertificate && (
				<Box marginRight='10px'>
					<img src={loadingGif} width='40' />
				</Box>
			)}

			{startAdornment && <Box marginRight='10px'>{startAdornment}</Box>}
			<span dangerouslySetInnerHTML={{ __html: text }}></span>
			{endAdornment && <Box marginLeft='auto'>{endAdornment}</Box>}
		</AlertContainer>
	);
};

AlertWrapper.defaultProps = {
	variant: "default",
	fontSize: "0.875rem",
	fontWeight: "700",
	textAlign: "left",
};

export { AlertWrapper as Alert };
