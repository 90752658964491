
import React, { useCallback, useEffect, useState } from "react";

import { Typography } from '../Typography';
import { LastNewsContainer } from './LastNewsStyled'

import ComunityFeaturedService from "../../services/comunityFeatured.service";
import loadingGif from "../../assets/images/loading.gif";
import { Button } from "../elements";
import { useHistory } from "react-router-dom";

export interface Props {

}
const LastNewsWrapper: React.FC<Props> = ({
  ...props
}: Props) => {
  const [noticia, setNoticia] = useState<any>();
  const [loading, setLoading] = useState(false);
  const history = useHistory()


  const trucateString = useCallback((txt: string, charCount: number) => {
    if (txt && txt.length > charCount) {
      return `${txt.substring(0, charCount)}[...]`;
    }
    return txt;
  }, []);

  const getNoticia = useCallback(async () => {
    setLoading(true);
    const featuredService = new ComunityFeaturedService();
    const response = await featuredService.list();



    const normDebDest = {
      id: response.id,
      title: response.titulo,
      comment: {
        author: {
          id: 1,
          profileId: "1",
          isPlus: true,
          isFounder: false,
          address: `São Pedro da Aldeia/RJ`,
          profession: "Monitor",
          isMonitor: true,
          name: response.criado_por.nome, // mudar depoiss
          imageUrl: response.criado_por.imagem_url,
        },
        text: trucateString(response.descricao, 300),
        date: response.data_publicacao,
        id: response.id,
        isNew: false,
        isLiked: false,
        likeCount: 0,

        repliedComment: null,
      },
      reactionsCount: 10,
      studentsCount: response.comentarios__criado_por__count,
      commentsCount: response.comentarios__count,
      isFavorite: false,
      isNotificationOn: false,
    };

    setNoticia(normDebDest);

    setLoading(false);
  }, []);

  useEffect(() => {
    getNoticia();
  }, []);
  return (
    <LastNewsContainer {...props}>
      <Typography variant="title" primary>Notícia em Destaque</Typography>
      {loading ?
        <img src={loadingGif} />
        : (
          <>
            {noticia ? <>
              <Typography variant="uppertitle" size="1.3rem" margin="1rem 0 .5rem">{noticia.title}</Typography>
              <Typography variant="text" margin="1rem 0">
                <div dangerouslySetInnerHTML={{ __html: noticia.comment.text }}></div>
              </Typography>
              <div style={{ textAlign: 'center' }}>
                <Button variant="outlined" color="primary" onClick={() => history.push(`/noticias/${noticia.id}`)}>Ler Mais</Button>
              </div></> : ''}
          </>
        )}
    </LastNewsContainer>
  );
};

LastNewsWrapper.defaultProps = {

}

export { LastNewsWrapper as LastNews };