import React from "react";

interface IIconProps {
	color: string;
}

const SVG = ({ color }: IIconProps) => (
	<svg id='Componente_14_1' data-name='Componente 14 – 1' width='25.938' height='24.065' viewBox='0 0 29.938 28.065'>
		<defs>
			<linearGradient id='linear-gradient' x1='0.681' y1='-0.159' x2='0.049' y2='1' gradientUnits='objectBoundingBox'>
				<stop offset='0' stopColor='#006df0' />
				<stop offset='1' stopColor='#00e7f0' />
			</linearGradient>
			<clipPath id='clip-path'>
				<rect id='Retângulo_80' data-name='Retângulo 80' width='29.938' height='28.065' fill='url(#linear-gradient)' />
			</clipPath>
		</defs>
		<g id='Grupo_88' data-name='Grupo 88' clipPath='url(#clip-path)'>
			<path
				id='Caminho_40'
				data-name='Caminho 40'
				d='M62.57,193.723H42.69a2.693,2.693,0,0,1-2.69-2.69V176.882a.936.936,0,0,1,1.871,0v14.151a.819.819,0,0,0,.819.819H62.57a.819.819,0,0,0,.819-.819V176.882a.936.936,0,0,1,1.871,0v14.151a2.693,2.693,0,0,1-2.69,2.69'
				transform='translate(-37.661 -165.658)'
				fill='url(#linear-gradient)'
			/>
			<path
				id='Caminho_41'
				data-name='Caminho 41'
				d='M29,14.5a.932.932,0,0,1-.661-.274L16.706,2.588a2.459,2.459,0,0,0-3.473,0L1.6,14.224A.936.936,0,0,1,.274,12.9L11.91,1.265a4.332,4.332,0,0,1,6.119,0L29.664,12.9A.936.936,0,0,1,29,14.5'
				transform='translate(0 0)'
				fill='url(#linear-gradient)'
			/>
			<path
				id='Caminho_42'
				data-name='Caminho 42'
				d='M184.42,284.128h-7.484a.936.936,0,0,1-.936-.936v-8.3a2.927,2.927,0,0,1,2.924-2.924h3.508a2.927,2.927,0,0,1,2.924,2.924v8.3a.936.936,0,0,1-.936.936m-6.549-1.871h5.613V274.89a1.054,1.054,0,0,0-1.052-1.052h-3.508a1.054,1.054,0,0,0-1.053,1.052Z'
				transform='translate(-165.709 -256.064)'
				fill='url(#linear-gradient)'
			/>
		</g>
	</svg>
	// <svg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='40' height='27'>
	// 	<path
	// 		fill={color ? color : "#013A7B"}
	// 		d='M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z'></path>
	// </svg>
);

export { SVG as DashboardSidebarIcon };
