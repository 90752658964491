import styled from "styled-components";
import { darken } from "@material-ui/core/styles/colorManipulator";
import media from "../../../styles/media";
import { Props } from "./index";

const LessonMaterialMultipleInputContainer = styled.div<Props>`
	display: grid;
	grid-template-columns: 200px auto auto;
	grid-column-gap: 20px;
	border: 1px solid #e8e8e8;
	background: #fff;
	border-radius: 6px;
	padding: 15px 20px;
	align-items: center;
`;

const FileInput = styled.div`
	label {
		border-radius: 100px;
		font-weight: 600;
		height: 45px;
		padding: 0 30px;
		text-align: center;
		line-height: 45px;
		font-size: 0.75rem;
		color: #fff;
		display: block;
		cursor: pointer;
		border: 0;
		box-sizing: border-box;
		background-color: ${(props) => props.theme.color.primary};
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		text-transform: uppercase;
		${media.lessThan("medium")`
      height: 35px;
      line-height: 35px;
      padding: 0 25px;
    `}

		&:hover {
			background-color: ${(props) => {
				const color = props.theme.color.primary;
				return darken(color, 0.5);
			}};
		}
	}

	input {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
`;
export { LessonMaterialMultipleInputContainer, FileInput };
