import React, { ReactNode } from "react";
import { InnerContentContainerContainer } from "./InnerContentContainerStyled";

export interface Props {
	background?: string;
	children?: ReactNode;
}

const InnerContentContainerWrapper: React.FC<Props> = ({ children, ...props }: Props) => <InnerContentContainerContainer {...props}>{children}</InnerContentContainerContainer>;

InnerContentContainerWrapper.defaultProps = {
	background: "#FFF",
};

export { InnerContentContainerWrapper as InnerContentContainer };
