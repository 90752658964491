import React, { ReactNode } from 'react';
import { MaterialsListContainer } from './MaterialsListStyled'

export interface Props {
background?: string;
children?: ReactNode;
}

const MaterialsListWrapper: React.FC<Props> = ({
  children,
  ...props
}: Props) => {
  return (
    <MaterialsListContainer {...props}>
      {children}
    </MaterialsListContainer>
  );
};

MaterialsListWrapper.defaultProps = {
  background: '#FFF'
}

export { MaterialsListWrapper as MaterialsList };