import styled from "styled-components";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { ModuleCardContainer } from "../ModuleCard/ModuleCardStyled";

import { Props } from "./index";

const ModuleCardWrapperContainer = styled.div<Props>`
	margin-bottom: 12px;

	&.active {
		${ModuleCardContainer} {
			background-color: ${(props) => fade(props.theme.color.primary, 0.3)};
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
		}
		&.blocked {
			${ModuleCardContainer} {
				border-bottom-right-radius: 6px;
				border-bottom-left-radius: 6px;
			}
		}
	}
	section {
		overflow: hidden;
	}
`;
export { ModuleCardWrapperContainer };
