import React, { ReactNode } from "react";
import { GreyContainerContainer } from "./GreyContainerStyled";

export interface Props {
	padding?: string;
	children?: ReactNode;
}

const GreyContainerWrapper: React.FC<Props> = ({ children, padding }: Props) => <GreyContainerContainer className="greyContainer" padding={padding}>{children}</GreyContainerContainer>;

GreyContainerWrapper.defaultProps = {
	padding: "20px",
};

export { GreyContainerWrapper as GreyContainer };
