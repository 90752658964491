import React, { ReactNode } from "react";
import { CardBaseContainer } from "./CardBaseStyled";

export interface Props {
  padding?: string;
  margin?: string;
  children?: ReactNode;
  width?: string;
  height?: string;
  minHeight?: string;
  alignItems?: string;
  grey?: boolean;
}

const CardBaseWrapper: React.FC<Props> = ({ children, ...props }: Props) => (
  <CardBaseContainer {...props}>{children}</CardBaseContainer>
);

CardBaseWrapper.defaultProps = {
  padding: "20px",
  width: "100%",
  height: "auto",
  minHeight: "auto",
  margin: "0",
  alignItems: "center",
  grey: false,
};

export { CardBaseWrapper as CardWrapper };
