import styled from "styled-components";
import media from "../../../../styles/media";
import { transparentize } from "polished";

interface NextPrevButtonProps {
	action: "next" | "prev";
}

const NextPrevNavigationContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const NextPrevButton = styled.button<NextPrevButtonProps>`
	display: flex;
	align-items: center;
	padding: 20px;
	background: none;
	cursor: pointer;
	font-size: 13px;
	font-weight: 600;
	text-transform: uppercase;
	outline: none;
	width: ${(props) => (props.action === "prev" ? "50%" : "calc(50% + 1px)")};
	margin-left: ${(props) => (props.action === "prev" ? "0" : "-1px")};
	justify-content: ${(props) => (props.action === "prev" ? "flex-start" : "flex-end")};
	border: 1px solid ${(props) => props.theme.color.primary};
	color: ${(props) => props.theme.color.primary};
	transition: 0.3s;
	${media.lessThan("medium")`
		padding: 14px 10px;
		font-size: 12px;
	`}
	&:hover {
		background: ${(props) => transparentize(0.9, props.theme.color.primary)};
	}
	&:disabled {
		opacity: 0.4;
		cursor: default;
		&:hover {
			background: none;
		}
	}
	svg {
		order: ${(props) => (props.action === "prev" ? "initial" : 1)};
		margin: ${(props) => (props.action === "prev" ? "0 8px 0 0" : "0 0 0 8px")};
	}
`;

export { NextPrevNavigationContainer, NextPrevButton };
