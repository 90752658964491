import React, { ReactNode } from 'react';
import styled from 'styled-components';

export interface Props extends React.HTMLAttributes<HTMLElement> {
    marginLeft?: string;
    marginContainer?: string;
    children?: ReactNode;
    border?: boolean;
    paddingTopBottom?: string;
    fullWidth?: boolean;
}

const IconsGroup = styled.div<Props>`
    display: flex;
    justify-content: ${(props) => (props.fullWidth ? 'space-around' : 'center')};
    align-items: center;
    margin: ${(props) => props.marginContainer};
    > div:not(:first-child){
        margin-left: ${(props) => props.marginLeft}
    }
    ${(props) => (props.border ? 'border-top: solid 1px #f2f2f2; border-bottom: solid 1px #f2f2f2' : null)}
    padding: ${(props) => props.paddingTopBottom}
`;

const IconWrapper: React.FC<Props> = ({
  ...props
}: Props) => (
  <IconsGroup
    {...props}
  >
    {props.children}
  </IconsGroup>
);

IconWrapper.defaultProps = {
  marginLeft: '15px',
  marginContainer: '0px',
  border: false,
  paddingTopBottom: '0',
  fullWidth: false,
};

export { IconWrapper as IconsGroup };
