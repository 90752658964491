import React, { ReactNode } from 'react';
import { ReactionIconCounterContainer } from './ReactionIconCounterStyled';

export interface Props {
  count?: number,
  backgroundColor?: string;
  children?: ReactNode;
}

const ReactionIconCounterWrapper: React.FC<Props> = ({
  count,
  children,
  backgroundColor,
}: Props) => (
  <ReactionIconCounterContainer backgroundColor={backgroundColor}>
    {children}
    {count
        && <span className="counter">{count}</span>}
  </ReactionIconCounterContainer>
);

ReactionIconCounterWrapper.defaultProps = {
  backgroundColor: 'transparent',
};

export { ReactionIconCounterWrapper as ReactionIconCounter };
