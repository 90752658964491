import React from "react";
import { Box } from "@material-ui/core";
import { Typography } from "../../..";
import { WhoCanSeeTag } from "../../../elements";

export interface Props {
	title: string | string[];
	text: string | string[];
	align?: string;
	whoCanSeeChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	whoCanSeeValue?: "all" | "owner";
}

// interface IChangeFunction {
// 	(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
// }

const TitleTextWhoCanSeeWrapper: React.FC<Props> = ({ title, text, whoCanSeeChange, whoCanSeeValue, align }: Props) => (
	<Box display='flex' flexDirection='column' alignItems='center'>
		<Typography variant='text' color='#666' margin='0 0 5px 0'>
			{title}
		</Typography>
		<Typography variant='text' color='#000' size='1.125rem' align={align || "left"}>
			{text}
		</Typography>
		{whoCanSeeValue && <WhoCanSeeTag value={whoCanSeeValue} onChange={whoCanSeeChange} />}
	</Box>
);

export { TitleTextWhoCanSeeWrapper as TitleTextWhoCanSee };
