import React, { ReactNode, useState, useEffect } from "react";
import { Box, Select, InputLabel, TextField, FormControl } from "@material-ui/core";

import { Button } from "../../../../elements";
import { ModalBase } from "../../../Producer_bkp/ModalBase";
import DebateService, { IListCategory } from "../../../../../services/comunityDebate.service";
import stringToSlug from "../../../../../lib/stringToSlug";

export interface Props {
	background?: string;
	children?: ReactNode;
	onCreateAction?: (id: number) => any;
}

const CreateDebateModalWrapper: React.FC<Props> = ({ children, onCreateAction, ...props }: Props) => {
	const [categoryModalOpen, setCategoryModalOpen] = useState(false);
	const [category, setCategory] = useState("");
	const [categoryInformation, setCategoryInformation] = useState("");

	function handleCategoryModalClose() {
		setCategoryModalOpen(false);
	}

	function handleCategoryModalOpen() {
		setCategoryModalOpen(true);
	}

	function handleCreateCategory() {
		if (category.length === 0) {
			setCategoryInformation("É necessário um nome para a categoria.");
			return;
		}
		const debateService = new DebateService();
		const slug = stringToSlug(category);
		debateService.createCategory({ name: category, slug }).then((response) => {
			if (response.status >= 400) {
				setCategoryInformation("Já existe uma categoria com este nome");
				return;
			}
			setCategoryInformation("");
			if (onCreateAction) onCreateAction(response.data.id);
			handleCategoryModalClose();
		});
	}

	return (
		<Box marginLeft='20px'>
			<Button
				variant='add'
				onClick={(e) => {
					e.preventDefault();
					handleCategoryModalOpen();
				}}>
				Nova Categoria
			</Button>
			<ModalBase title='Nova Categoria' maxWidth='md' open={categoryModalOpen} handleClose={handleCategoryModalClose} saveAction={handleCreateCategory}>
				<Box display='flex' alignItems='center' padding='50px 100px'>
					<Box width='100%'>
						<form noValidate autoComplete='off'>
							<Box display='flex' flexDirection='row'>
								<TextField
									id='category'
									label='Nome'
									fullWidth
									value={category}
									onChange={(e) => setCategory(e.target.value)}
									error={categoryInformation.length > 0}
									helperText={categoryInformation}
								/>
							</Box>
						</form>
					</Box>
				</Box>
			</ModalBase>
		</Box>
	);
};

CreateDebateModalWrapper.defaultProps = {
	background: "#FFF",
};

export { CreateDebateModalWrapper as CreateDebateCategoryModal };
