import React, { ReactNode } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { IconContainer } from "./IconWrapperStyled";

const CustomTooltip = withStyles(() => ({
	arrow: {
		color: "#E0E0E0",
	},
	tooltip: {
		backgroundColor: "#E0E0E0",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: 12,
	},
}))(Tooltip);

export interface Props extends React.HTMLAttributes<HTMLElement> {
	rounded?: boolean;
	filled?: boolean;
	size?: string;
	icon?: ReactNode;
	cursor?: string;
	marginLeft?: string;
	marginRight?: string;
	/** Default primary color */
	backgroundColor?: string;
	primaryColor?: boolean;
	tooltipText?: string;
	primaryBorder?: boolean;
	border?: boolean;
}

const IconWrapper: React.FC<Props> = ({ ...props }: Props) => (
	<>
		{props.tooltipText ? (
			<CustomTooltip title={props.tooltipText} arrow placement='top'>
				<IconContainer {...props}>{props.icon}</IconContainer>
			</CustomTooltip>
		) : (
			<IconContainer {...props}>{props.icon}</IconContainer>
		)}
	</>
);

IconWrapper.defaultProps = {
	rounded: false,
	filled: true,
	size: "24px",
	cursor: "pointer",
	border: false,
	primaryBorder: false,
};

export { IconWrapper };
