import React, { ReactNode } from "react";

import { IconWrapper } from "./IconWrapper";

export interface Props extends React.HTMLAttributes<HTMLElement> {
	svgIcon: ReactNode;
}

const SidebarIconWrapper: React.FC<Props> = ({ svgIcon }: Props) => <IconWrapper rounded size='40px' filled={true} border primaryColor={false} icon={svgIcon} marginLeft='2px' />;

export { SidebarIconWrapper };
