import React, { useEffect, useState } from 'react';
import { FavoriteButtonContainer, FullButton, SmallButton } from './FavoriteButtonStyled'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { ImSpinner2 } from 'react-icons/im'
import FavoritesService from '../../services/favorites.service';
import Theme from '../../styles/theme';
import { IFavorite, TFavType } from '../../types/favorites';

export interface Props {
  buttonStyle: 'simple' | 'full' | 'roundClear' | 'roundDark',
  refID: number | string,
  favType: TFavType,
  isFavorite?: boolean
}

const FavoriteButtonWrapper: React.FC<Props> = ({
  buttonStyle,
  refID,
  favType,
  isFavorite,
  ...props
}: Props) => {
  const iconSize = 20
  const [loading, setLoading] = useState<boolean>(false)
  const [favorite, setFavorite] = useState<boolean>(isFavorite ? isFavorite : false)
  const toggleFavorite = async (id: string | number, fav: TFavType) => {
    setLoading(true)

    const favoritesService = new FavoritesService()

    const response = await favoritesService.toggleFavorite(id, fav)

    console.log("FAVORITADO", response)

    setFavorite(!favorite)

    setLoading(false)
  }

  const decideIfFavorite = async () => {
    setLoading(true)

    const favoritesService = new FavoritesService()

    const response: IFavorite[] = await favoritesService.list()

    const isFav = response.find((fav: IFavorite) => fav.id === refID)

    // console.log({
    //   r: response,
    //   isFav
    // })

    setFavorite(isFav ? true : false)

    setLoading(false)
  }

  useEffect(() => {
    decideIfFavorite()
  }, [])

  return (
    <FavoriteButtonContainer {...props}>
      {buttonStyle === "simple" && (
        <SmallButton>
          {loading ?
            <span className="spin"><ImSpinner2 size={iconSize} /></span> :
            <button onClick={() => toggleFavorite(refID, favType)} title="Marcar como favorito">
              {favorite ? <AiFillStar size={iconSize} color={Theme.color.primary} /> : <AiOutlineStar size={iconSize} color={Theme.color.primary} />}
            </button>
          }
        </SmallButton>
      )}
      {(buttonStyle === "roundClear" || buttonStyle === 'roundDark') && (
        <SmallButton rounded={buttonStyle}>
          {loading ?
            <span className="spin"><ImSpinner2 size={iconSize} /></span> :
            <button onClick={() => toggleFavorite(refID, favType)} title="Marcar como favorito">
              {favorite ? <AiFillStar size={iconSize} /> : <AiOutlineStar size={iconSize} />}
            </button>
          }
        </SmallButton>
      )}
      {buttonStyle === 'full' && (
        <FullButton onClick={() => toggleFavorite(refID, favType)}>
          <span>{favorite ? "Marcado" : "Marcar"} como favorito</span>
          {loading ?
            <span className="spin"><ImSpinner2 size={iconSize} /></span> :
            (<>
              {favorite ? <AiFillStar size={iconSize} color={Theme.color.primary} /> : <AiOutlineStar size={iconSize} color={Theme.color.primary} />}
            </>)
          }
        </FullButton>
      )}

    </FavoriteButtonContainer>
  );
};

FavoriteButtonWrapper.defaultProps = {

}

export { FavoriteButtonWrapper as FavoriteButton };