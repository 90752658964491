import React from "react";

interface IIconProps {
	color: string;
}

const SVG = ({ color }: IIconProps) => (
	<svg id='Componente_17_1' data-name='Componente 17 – 1' width='26.097' height='20.824' viewBox='0 0 30.097 24.824'>
		<defs>
			<linearGradient id='linear-gradient-cursos' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
				<stop offset='0' stopColor='#006df0' />
				<stop offset='1' stopColor='#00e7f0' />
			</linearGradient>
		</defs>
		<path
			id='Caminho_49'
			data-name='Caminho 49'
			d='M309.793,43.156a30.924,30.924,0,0,0-6.694-.981.943.943,0,0,0-.09,1.883,32.232,32.232,0,0,1,6.533.949.943.943,0,0,0,.25-1.852'
			transform='translate(-298.397 -36.873)'
			fill='url(#linear-gradient-cursos)'
		/>
		<path
			id='Caminho_50'
			data-name='Caminho 50'
			d='M309.793,79.169a30.925,30.925,0,0,0-6.694-.981.943.943,0,0,0-.09,1.883,32.232,32.232,0,0,1,6.533.949.943.943,0,0,0,.25-1.852'
			transform='translate(-298.397 -68.36)'
			fill='url(#linear-gradient-cursos)'
		/>
		<path
			id='Caminho_51'
			data-name='Caminho 51'
			d='M309.793,118.853a30.928,30.928,0,0,0-6.694-.981.943.943,0,0,0-.09,1.883,32.233,32.233,0,0,1,6.533.949.943.943,0,0,0,.25-1.852'
			transform='translate(-298.397 -103.056)'
			fill='url(#linear-gradient-cursos)'
		/>
		<path
			id='Caminho_52'
			data-name='Caminho 52'
			d='M422.878,42.176a30.93,30.93,0,0,0-6.694.981.943.943,0,0,0,.25,1.852,32.664,32.664,0,0,1,6.533-.949.943.943,0,1,0-.089-1.883'
			transform='translate(-397.526 -36.874)'
			fill='url(#linear-gradient-cursos)'
		/>
		<path
			id='Caminho_53'
			data-name='Caminho 53'
			d='M422.878,78.19a30.926,30.926,0,0,0-6.694.981.943.943,0,0,0,.25,1.852,32.673,32.673,0,0,1,6.533-.949.943.943,0,1,0-.089-1.883'
			transform='translate(-397.526 -68.361)'
			fill='url(#linear-gradient-cursos)'
		/>
		<path
			id='Caminho_54'
			data-name='Caminho 54'
			d='M419.549,120.226a26.262,26.262,0,0,0-3.365.678.943.943,0,0,0,.25,1.852c.374,0,.975-.33,3.374-.662a.943.943,0,1,0-.259-1.868'
			transform='translate(-397.525 -105.106)'
			fill='url(#linear-gradient-cursos)'
		/>
		<path
			id='Caminho_56'
			data-name='Caminho 56'
			d='M300.546,0c-2.5-.037-8.764.124-12.81,2.432a.257.257,0,0,1-.254,0C283.437.129,277.175-.033,274.669,0a2.152,2.152,0,0,0-2.11,2.144V20.409a2.143,2.143,0,0,0,2.176,2.144c2.166-.029,7.6.094,11.262,1.895a3.638,3.638,0,0,0,3.229,0c3.7-1.814,9.175-1.925,11.358-1.889a2.037,2.037,0,0,0,2.071-2.039V2.148A2.152,2.152,0,0,0,300.546,0M286.665,22.678c-3.438-1.632-8.166-2.062-11.956-2.01a.258.258,0,0,1-.264-.258V2.148a.259.259,0,0,1,.253-.258c2.35-.035,8.209.107,11.85,2.186.039.022.078.042.117.062Zm14.106-2.162a.152.152,0,0,1-.155.154c-6.716-.113-10.585,1.31-12.066,2.007V4.137q.061-.03.12-.063c3.642-2.078,9.5-2.219,11.847-2.184a.259.259,0,0,1,.253.258V20.516Z'
			transform='translate(-272.559 0)'
			fill='url(#linear-gradient-cursos)'
		/>
	</svg>

	// <svg
	//   width="25"
	//   height="30"
	//   viewBox="0 0 31 30"
	//   fill={color}
	//   xmlns="http://www.w3.org/2000/svg"
	// >
	//   <path d="M1.98913 22.6702H12.1416V25.998C7.75101 26.0726 6.27328 27.9403 6.21127 28.0216L5.88852 28.4472H24.7946L24.4719 28.0216C24.4102 27.9407 22.9325 26.073 18.5423 25.998V22.6702H28.6944C29.7341 22.6702 30.5805 21.8215 30.5805 20.7781V2.66455C30.5805 1.62148 29.7345 0.772461 28.6944 0.772461H1.98913C0.949358 0.772461 0.103027 1.62113 0.103027 2.66455V20.7781C0.103027 21.8212 0.949006 22.6702 1.98913 22.6702ZM15.6062 6.71099H27.2667V13.8987H15.6062V6.71099ZM9.45354 10.2011C11.0127 10.2011 12.2811 11.4739 12.2811 13.0383C12.2811 14.602 11.0127 15.8738 9.45354 15.8738C7.89477 15.8738 6.62703 14.6017 6.62703 13.0383C6.62703 11.4739 7.89477 10.2011 9.45354 10.2011ZM9.45354 16.404C12.6627 16.404 15.2824 18.9542 15.4219 22.14H12.1412H3.48553C3.62506 18.9546 6.24474 16.404 9.45354 16.404Z" />
	//   <path d="M24.4278 8.25H18.4443V8.78019H24.4278V8.25Z" />
	//   <path d="M24.4278 10.342H18.4443V10.8722H24.4278V10.342Z" />
	// </svg>
);

export { SVG as CourseSidebarIcon };
