import api from "./api";
import { headers as configHeaders } from "./config";

export default class BaseService {
	public resource = "";
	constructor(resource: string) {
		this.resource = resource;
	}

	formatDetailData(data: any) {
		return data;
	}

	formatListData(data: any) {
		return data;
	}

	async list(isCommentsList?: boolean) {
		let resourceArr = this.resource.split("=");

		let resourceURL = ``;

		if (resourceArr.length > 1) {
			if (isCommentsList) {
				resourceURL = `${this.resource}-v2`;
			} else {
				resourceURL = `${this.resource}`;
			}
		} else {
			if (isCommentsList) {
				resourceURL = `${this.resource}-v2/`;
			} else {
				resourceURL = `${this.resource}/`;
			}
		}

		//	console.log("RESOURCE URL => ", { url: resourceURL, isComments: isCommentsList });

		try {
			const response = await api({
				method: "GET",
				url: resourceURL,
			});
			//	console.log("[BS] RESPONSE LIST ==>", response.data);

			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao listar ${this.resource} base service:`, err);
			return {
				error: true,
			};
		}
	}

	async loadPage(page: any, timestamp: any) {
		let resourceURL = ``;
		if (timestamp) {
			resourceURL = `${this.resource}/?page=${page}&data=${timestamp}`;
		} else {
			resourceURL = `${this.resource}/?page=${page}`;
		}

		try {
			const response = await api.get(resourceURL);
			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao listar página ${page} - ${this.resource} base service:`, err);
			return {
				error: true,
			};
		}
	}

	async get_by_category_slug(slug: any) {
		try {
			const response = await api.get(`${this.resource}/categoria/${slug}`);
			//console.log("[BS] GET BY CATEGORY SLUG ==> ", response);

			return this.formatDetailData(response.data);
		} catch (err) {
			console.error(`[BS] Erro ao listar ${this.resource} base service:`, err);
			return {
				error: true,
			};
		}
	}

	async get_by_slug(slug: any) {
		try {
			const response = await api.get(`${this.resource}/${slug}/`);
			// console.log("[BS] GET BY SLUG ==> ", response);

			return this.formatDetailData(response.data.results ? response.data.results : response.data);
		} catch (err) {
			console.error(`[BS] Erro ao listar ${this.resource} base service:`, err);
			return {
				error: true,
			};
		}
	}

	async get_by_id(id: any) {
		try {
			const response = await api.get(`${this.resource}/${id}/`);
			console.log("[BS] GET BY ID ==> ", response);
			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao buscar ${this.resource} base service:`, err);
			return {
				error: true,
			};
		}
	}

	async create(data: any, headers = configHeaders) {
		try {
			const response = await api.post(`${this.resource}/`, data, headers);
			return { status: response.status, data: response.data };
		} catch (err) {
			console.error(`[BS] Erro ao criar ${this.resource} :`, err);
			console.error(`[BS] Erro ao criar ${this.resource} :`, err.response);
			return {
				error: true,
			};
		}
	}

	async save(id: any, data: any, headers = configHeaders) {
		// TO DO : remover e usar update
		try {
			const response = await api.put(`${this.resource}/${id}/`, data, headers);
			// console.log("[BS] RESPONSE ==> ", response);
			// console.log("[BS] RESPONSE.DATA ==> ", response);

			return { status: response.status, data: response.data };
		} catch (err) {
			console.error(`[BS] Erro ao criar ${this.resource} :`, err);
			return {
				error: true,
			};
		}
	}

	async update(id: any, data: any, isUser: any, headers = configHeaders) {
		console.log("DATA IMG => ", data);

		console.log("UPDATE IS USER => ", isUser);

		const uri = `${this.resource}/${id}/`;

		try {
			let response: any;

			if (isUser) {
				response = await api.put(`${this.resource}/`, data, headers);
			} else {
				response = await api.put(uri, data, headers);
			}

			console.log("[BS] RESPONSE ==> ", response);

			return { status: response.status, data: response.data };
		} catch (err) {
			console.log(`[BS] Erro ao atualizar ${this.resource} :`, err);
			console.log(`[BS] Erro ao atualizar ${this.resource} :`, err.response);

			return {
				error: true,
				status: err.response ? err.response.status : null,
				data: err.response ? err.response.data : null,
			};
		}
	}

	async delete(id: any) {
		try {
			const response = await api.delete(`${this.resource}/${id}/`);
			console.log("[BS] DELETED DATA ==> ", response);
			return this.formatDetailData(response.data);
		} catch (err) {
			console.error(`[BS] Erro ao deletar ${this.resource} :`, err);
			console.error(`[BS] Erro ao deletar ${this.resource} :`, err.response);
			return {
				error: true,
				status: err.response ? err.response.status : null,
				data: err.response ? err.response.data : null,
			};
		}
	}

	async deleteComment(commentId: any) {
		try {
			console.log(`${this.resource}/${commentId}/`);
			const response = await api.delete(`${this.resource}/${commentId}/`);
			console.log("[BS] DELETED DATA ==> ", response);
			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao deletar ${this.resource} :`, err);
			console.error(`[BS] Erro ao deletar ${this.resource} :`, err.response);
			return {
				error: true,
				status: err.response ? err.response.status : null,
				data: err.response ? err.response.data : null,
			};
		}
	}

	async like(id: any) {
		try {
			const response = await api.post(`${this.resource}/like/`, {
				"entidade_pk": id,
			});
			//console.log("<< CURTIDO >>", response);
			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao curtir ${this.resource} :`, err);
			console.error(`[BS] Erro ao curtir ${this.resource} :`, err.response);
			return {
				error: true,
				status: err.response ? err.response.status : null,
				data: err.response ? err.response.data : null,
			};
		}
	}
}
