// const authHeader = () => {
//   const user = JSON.parse(localStorage.getItem('user'));
//   return user ? `Token ${user.token}` : '';
// };

// const initHeaders = {
//   'Content-Type': 'application/json',
//   Authorization: authHeader(),
// };

// export const initConfig = {
//   headers: initHeaders,
//   mode: 'cors',
//   cache: 'default',
// };

export const headers = {
	headers: {
		"Content-Type": "application/json",
	},
};

// "tenant-slug": localStorage.getItem("tenant"),

export const multipartHeaders = {
	headers: {
		"Content-Type": "multipart/form-data",
	},
};
// export const BASE_URL = "https://back.joinlearn.com.br/api";
export const BASE_URL = "https://nossomundoazul.com.br/api";
// export const BASE_URL = 'http://127.0.0.1:8888/api';
