import React from "react";
import { HiLockClosed } from "react-icons/hi";
import { ImageWrapper, BestSellingSeal, DiscountSeal, RecomendedRibbon } from "../..";
import { CircularProgressIndicator } from "../../CircularProgressIndicator";
import { CardPictureHeaderContainer, SealsWrapper, CommentsTag } from "./CardPictureHeaderStyled";

export interface Props {
	imageUrl: string;
	discountPercent?: number;
	bestSelling?: boolean;
	progress?: string;
	recommended?: boolean;
	alt?: string;
	hasComments?: boolean;
	locked?: boolean
}

const CardPictureHeaderWrapper: React.FC<Props> = ({ locked, imageUrl, alt, discountPercent, hasComments, bestSelling, progress, recommended, ...props }: Props) => {
	return (
		<CardPictureHeaderContainer>
			<ImageWrapper locked={locked} width='100%' height='160px' src={imageUrl} alt={alt} />
			{locked && <>
				<div className="cadeado">
					<div className="round">
						<HiLockClosed size={46} color={'#FFF'} /></div>
				</div>
				<SealsWrapper>
					<DiscountSeal size={65} percentage={30} background='#DD0000' primaryColor='#FFCC00' secondaryColor='#FFFFFF' />
				</SealsWrapper>
			</>}
			{recommended ? <RecomendedRibbon /> : ""}
			{!locked ? <>
				{hasComments ? <CommentsTag>Novos Comentários</CommentsTag> : ""}
				<SealsWrapper>
					{bestSelling && <BestSellingSeal size={45} background='#FFF' strokeColor='#56CCF2' heartColor='#DD0000' ribbonColor='#2FA2F4' />}
					{discountPercent && <DiscountSeal size={45} percentage={discountPercent} background='#DD0000' primaryColor='#FFCC00' secondaryColor='#FFFFFF' />}
					{progress !== undefined && <CircularProgressIndicator size={45} percentage={parseInt(progress)} />}
				</SealsWrapper></> : <></>
			}
		</CardPictureHeaderContainer>
	);
};

CardPictureHeaderWrapper.defaultProps = {
	alt: "",
};

export { CardPictureHeaderWrapper as CardPictureHeader };
