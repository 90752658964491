import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swiper, { Pagination, Autoplay } from "swiper";
import { Swiper as Slider, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { isMobile } from "../../../../isMobile";
//import { Typography } from "../../../Typography";
import { MultimediaHomeCarouselContainer, WideImg, LoadingWrapper } from "./MultimediaHomeCarouselStyled";
import { IoIosArrowForward } from "react-icons/io";
import { ContentLoadingError } from "../../../../components/ContentLoadingError";

import MultimidiasVideosService from "../../../../services/multimidiaVideos.service";

import { LoadingPlaceholder } from "../../../elements/LoadingPlaceholder";

export interface Props {}

Swiper.use([Pagination, Autoplay]);

interface IMultimidiaCategory {
	descricao: string;
	id: number;
	imagem: string;
	nome: string;
	slug: string;
	url: string;
}

const MultimediaHomeCarouselWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const [multimidias, setMultimidias] = useState<IMultimidiaCategory[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const getMultimidias = useCallback(async () => {
		try {
			const multimidiasVideosService = new MultimidiasVideosService();
			const response = await multimidiasVideosService.list();
			setMultimidias(response);
			setLoading(false);
			//throw new Error("teste");
			//console.log("MULTIMIDIA SLIDER =>", response);
		} catch (error) {
			setError(true);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getMultimidias();
	}, []);

	return (
		<MultimediaHomeCarouselContainer {...props}>
			{loading ? (
				<>
					<LoadingWrapper>
						{isMobile.any() ? (
							<>
								<LoadingPlaceholder variant='image' height='110px' width='335px' />
							</>
						) : (
							<>
								<LoadingPlaceholder variant='image' height='110px' width='335px' />
								<LoadingPlaceholder variant='image' height='110px' width='335px' />
								<LoadingPlaceholder variant='image' height='110px' width='335px' />
								<LoadingPlaceholder variant='image' height='110px' width='335px' />
							</>
						)}
					</LoadingWrapper>
				</>
			) : (
				<>
					{error ? (
						<>
							<ContentLoadingError
								refresh={() => {
									getMultimidias();
								}}
								loadedResourceErrorMsg='as séries'
							/>
						</>
					) : (
						<>
							<Slider pagination autoplay slidesPerView={isMobile.any() ? 1 : 4} spaceBetween={20}>
								{multimidias.length ? (
									<>
										{multimidias.map((serie: IMultimidiaCategory) => {
											return (
												<SwiperSlide key={serie.id}>
													<Link className='itemMultimedia' to={`/serie/${serie.slug}`} title={serie.nome}>
														<WideImg>
															{serie.imagem ? <img src={serie.imagem} alt={serie.nome} /> : <img src='https://ajuda.nossomundoazul.com.br/other-assets/multimidia-fallback.jpg' alt={serie.nome} />}
														</WideImg>
														<p>{serie.nome.length > 35 ? `${serie.nome.substring(0, 30)}...` : serie.nome}</p>
														<IoIosArrowForward size={14} color='#bdbdbd' />
													</Link>
												</SwiperSlide>
											);
										})}
									</>
								) : (
									<>
										<ContentLoadingError
											refresh={() => {
												getMultimidias();
											}}
											loadedResourceErrorMsg='as séries'
										/>
									</>
								)}
							</Slider>
						</>
					)}
				</>
			)}
		</MultimediaHomeCarouselContainer>
	);
};

MultimediaHomeCarouselWrapper.defaultProps = {};

export { MultimediaHomeCarouselWrapper as MultimediaHomeCarousel };
