import React from 'react';
import { motion } from "framer-motion"
import { LoadingContainer,  ModalLoadingContainer } from './LoadingStyled';
import {IconWrapper  } from "../"
import { AiOutlineLoading } from "react-icons/ai";

export interface Props {
  variant?: "modal" | "simple" | undefined
  size?: string
}

const transition = {
  repeat: Infinity,
  duration: 0.7
}
const LoadingWrapper: React.FC<Props> = ({
  variant,
  size,
  ...props
}: Props) => (
  
  <>
    {!variant
      ? (
        <LoadingContainer>
          <div className="lds-ripple">
            <div />
            <div />
          </div>
        </LoadingContainer>
      )
      : 
        <>
        {variant == 'simple' && 
          
            <IconWrapper
              size={size ? size: "30px"}
              primaryColor
              filled={false}
              cursor="none"
              marginRight="5px"
            icon={<motion.div
              animate={{
                rotate: [0, 360],
              }}
              transition={transition}><AiOutlineLoading size={size? size:"30px"} /></motion.div>}
            />
          
        }
        {variant == 'modal' &&
          <ModalLoadingContainer>
            <div className="loadingio-spinner-double-ring-l3mpkapk3qg">
              <div className="ldio-7wm71l9lbps">
                <div />
                <div />
                <div><div /></div>
                <div><div /></div>
              </div>
            </div>
          </ModalLoadingContainer>
          }
        </>
      }
  </>
);

LoadingWrapper.defaultProps = {

};

export { LoadingWrapper as Loading };
