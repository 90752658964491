import styled from "styled-components";
import { Props } from "./index";
import media from "../../styles/media";

interface CellProps {
	width?: {
		d: string;
		m: string;
	};
	margin?: {
		d: string;
		m: string;
	};
}

const StudentProfileContainer = styled.div<Props>`
	display: flex;
	.linkCertificado {
		text-decoration: none;
	}
`;

const BoletimWrapper = styled.div`
	//display: none;
	@media print {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: #fff;
		z-index: 99999999999999999999999;
		padding: 5%;
	}

	.printLogo {
		display: none;
		@media print {
			display: block;
			margin: 0 0 30px;
			width: 40%;
		}
	}
`;

const BoletimCourseName = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	color: #333;
	margin-bottom: 8px;
	&:not(:first-child) {
		margin-top: 32px;
	}
`;

const BoletimCourseCard = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	color: #333;

	margin-bottom: 10px;
	border-radius: 6px;
	border: 1px solid #f2f2f2;
	padding: 15px 20px;
	background: #fff;
	@media print {
		background: #f6f8f9;
	}
`;

const BoletimCourseCardCell = styled.div<CellProps>`
	width: ${(props) => (props.width?.d ? props.width.d : "auto")};
	margin: ${(props) => (props.margin?.d ? props.margin.d : "0 0 0 0")};
	color: #555;
	b {
		display: block;
		margin-bottom: 4px;
		color: #333;
	}
	span {
		display: flex;
		align-items: center;
	}
	${(props) => media.lessThan("medium")`
    width: ${props.width?.m ? props.width.m : "auto"};
    margin: ${props.margin?.m ? props.margin.m : "0 0 0 0"};
    font-size: 0.9em;
    //max-width: 100%;
  `}
`;

const FlexPrintTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	div {
		${media.lessThan("medium")`
      width: 100%;
    `}
	}
	button {
		background: none;
		cursor: pointer;
		transition: 0.3s;
		&:hover {
			opacity: 0.6;
		}
		${media.lessThan("medium")`
      display: none;
    `}
	}
`;

const TabGroup = styled.div``;
const TabsWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 3px solid #f2f2f2;
	margin-bottom: 2rem;
`;
const Tab = styled.button`
	padding: 1rem 2rem;
	margin-bottom: -3px;
	background: none;
	font-weight: 600;
	text-transform: uppercase;
	cursor: pointer;
	color: ${(props) => props.theme.color.primary};
	transition: 0.3s ease;
	border-bottom: 3px solid transparent;
	&.active,
	&:hover {
		border-bottom: 3px solid ${(props) => props.theme.color.primary};
	}
	&.active {
		cursor: default;
	}
`;
const TabContent = styled.div`
	&.active {
		display: block;
	}
	&.inactive {
		display: none;
	}
`;

export {
	StudentProfileContainer, //
	BoletimWrapper,
	BoletimCourseName,
	BoletimCourseCard,
	BoletimCourseCardCell,
	FlexPrintTitle,
	TabGroup,
	TabsWrapper,
	Tab,
	TabContent,
};
