import React from "react";

interface IIconProps {
	color: string;
}

const SVG = ({ color }: IIconProps) => (
	<svg id='Componente_2_1' data-name='Componente 2 – 1' width='16.78' height='25.174' viewBox='0 0 23.78 32.174'>
		<defs>
			<linearGradient id='linear-gradient-articles' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
				<stop offset='0' stopColor='#ff2b8c' />
				<stop offset='1' stopColor='#f52f4e' />
			</linearGradient>
			<clipPath id='clip-path-articles'>
				<rect id='Retângulo_84' data-name='Retângulo 84' width='23.78' height='32.174' fill='url(#linear-gradient-articles)' />
			</clipPath>
		</defs>
		<g id='Grupo_96' data-name='Grupo 96' clipPath='url(#clip-path-articles)'>
			<path
				id='Caminho_48'
				data-name='Caminho 48'
				d='M18.166,0H1.973A1.976,1.976,0,0,0,0,1.973V30.2a1.975,1.975,0,0,0,1.973,1.973H21.808A1.975,1.975,0,0,0,23.78,30.2V6.1Zm.465,2.952,2.461,2.675H18.631ZM22.124,30.2a.318.318,0,0,1-.317.316H1.973a.317.317,0,0,1-.316-.316V1.973a.316.316,0,0,1,.316-.315h15v4.8a.829.829,0,0,0,.829.827h4.321ZM4.58,8.812h14.07a.621.621,0,0,1,0,1.241H4.58a.621.621,0,0,1,0-1.241M19.27,13.6a.621.621,0,0,1-.621.621H4.58a.621.621,0,0,1,0-1.241h14.07a.621.621,0,0,1,.621.621m0,4.035a.621.621,0,0,1-.621.621H4.58a.621.621,0,0,1,0-1.242h14.07a.621.621,0,0,1,.621.621m0,4.1a.621.621,0,0,1-.621.621H4.58a.621.621,0,0,1,0-1.241h14.07a.621.621,0,0,1,.621.621'
				fill='url(#linear-gradient-articles)'
			/>
		</g>
	</svg>

	// <svg
	//   width="47"
	//   height="47"
	//   viewBox="0 0 47 47"
	//   fill="none"
	//   xmlns="http://www.w3.org/2000/svg"
	// >
	//   <path
	//     d="M18.9333 8V14.9333H12V34H31.0667V8H18.9333ZM26.9502 29.7998H16.1167C15.5645 29.7998 15.1167 29.3521 15.1167 28.7998C15.1167 28.2476 15.5645 27.7998 16.1167 27.7998H26.9502C27.5024 27.7998 27.9502 28.2476 27.9502 28.7998C27.9502 29.3521 27.5024 29.7998 26.9502 29.7998ZM26.9502 24.6001H16.1167C15.5645 24.6001 15.1167 24.1523 15.1167 23.6001C15.1167 23.0479 15.5645 22.6001 16.1167 22.6001H26.9502C27.5024 22.6001 27.9502 23.0479 27.9502 23.6001C27.9502 24.1523 27.5024 24.6001 26.9502 24.6001ZM26.9502 19.3999H16.1167C15.5645 19.3999 15.1167 18.9521 15.1167 18.3999C15.1167 17.8477 15.5645 17.3999 16.1167 17.3999H26.9502C27.5024 17.3999 27.9502 17.8477 27.9502 18.3999C27.9502 18.9521 27.5024 19.3999 26.9502 19.3999Z"
	//     fill="#013A7B"
	//   />
	//   <path d="M32.0669 12V35H16.9333V38H36V12H32.0669Z" fill="#013A7B" />
	//   <path
	//     d="M18.5796 8L12 14.5796V15.2866H12.707L19.2866 8.70703V8H18.5796Z"
	//     fill="#013A7B"
	//   />
	// </svg>
);

export { SVG as ArticlesSidebarIcon };
