import React, {
  ChangeEvent, useState, useEffect, useRef,
} from 'react';
import { Box } from '@material-ui/core';
import { Scope, useField } from '@unform/core';

import { Input, Typography } from '../..';
import { RemoveIcon } from '../../elements/Icons';
import { Button } from '../../elements';
import { LessonMaterialMultipleInputContainer, FileInput } from './LessonMaterialMultipleInputStyled';

export interface Props {

}

const LessonMaterialMultipleInputWrapper: React.FC<Props> = ({
  ...props
}: Props) => {
  const {
    fieldName, defaultValue, registerField, error,
  } = useField('materiais');
  const inputRef = useRef({ value: [] });
  const [lines, setLines] = useState(defaultValue || []);
  const newItem = { titulo: '', arquivo: '' };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue() {
        return lines;
      },
    });
  }, [fieldName, lines, registerField]);

  const updateLines = (newLines: any) => {
    inputRef.current.value = newLines;
    setLines(newLines);
  };

  const addLine = () => {
    updateLines([...lines, newItem]);
  };

  const removeLine = (index: number) => {
    const newLines = lines.filter((item: any, i: number) => i !== index);
    updateLines(newLines);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target?.files ? e.target?.files[0] : e.target.value;

    const newLines = lines.map((line: any, i: number) => (index !== i ? line : { ...line, [e.target.name]: value }));

    updateLines(newLines);
  };

  return (
    <div>
      <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
        <Typography margin="30px 0 30px 0" variant="title" size="1.125rem">
          Materiais
        </Typography>
        <Button variant="add" type="button" onClick={addLine}>Incluir Material</Button>
      </Box>
      <Box display="grid" gridTemplateRows="auto" gridRowGap="5px">
        {lines.map((line: any, index: number) => (
          <Scope path={`materiais[${index}]`}>
            <LessonMaterialMultipleInputContainer key={index}>
              <FileInput>
                <label htmlFor={`arquivo-${index}`}>Anexar Arquivo </label>
                <input
                  className="image-input"
                  type="file"
                  id={`arquivo-${index}`}
                  name="arquivo"
                  onChange={(e: any) => handleChange(e, index)}
                />
              </FileInput>

              <Input
                name="titulo"
                label="Nome"
                value={line.titulo}
                onChange={(e: any) => handleChange(e, index)}
              />
              <Box display="flex" justifyContent="flex-end">
                <RemoveIcon onClick={() => removeLine(index)} />
              </Box>
            </LessonMaterialMultipleInputContainer>
          </Scope>
        ))}
      </Box>
      {error && <p>{error}</p>}
    </div>
  );
};

// LessonMaterialMultipleInputWrapper.defaultProps = {
//   background: '#FFF'
// }

export { LessonMaterialMultipleInputWrapper as LessonMaterialMultipleInput };
