import styled, { css } from "styled-components";
import { Props } from "./index";
import media from "../../styles/media";

const Title = styled.h1<Props>`
	font-size: ${(props) => (props.size ? props.size : "22px")};
	margin: ${(props) => props.margin};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 700)};
	text-transform: ${(props) => (props.uppercase ? "uppercase" : "")};
	display: ${(props) => props.display};
	${props => props.display === "flex" && css`
		align-items: center;
		gap: 10px;
	`};
	text-align: ${(props) => props.align};
	color: ${(props) => {
		if (props.primary) {
			return props.theme.color.primary;
		}
		return props.color ? props.color : "#333";
	}};
	line-height: ${(props) => (props.lineHeight ? props.lineHeight : 1.3)};
	${media.lessThan("small")`
        font-size: 16px;
    `}
`;

const Subtitle = styled.div<Props>`
	font-size: ${(props) => (props.size ? props.size : "0.75rem")};
	margin: ${(props) => props.margin};
	color: ${(props) => (props.color ? props.color : "#9a9a9a")};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
	text-transform: ${(props) => (props.uppercase ? "uppercase" : "")};
	line-height: 1.7;
	display: ${(props) => props.display};
	text-align: ${(props) => props.align};
`;

const Uppertitle = styled.div<Props>`
  font-size: ${(props) => (props.size ? props.size : "0.875rem")};
  margin: ${(props) => props.margin};
  color: ${(props) => (props.color ? props.color : props.theme.color.primary)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "")};
  line-height: 1.5;
  display: ${(props) => props.display};
  text-align: ${(props) => props.align};
`;

const Text = styled.div<Props>`
	font-size: ${(props) => (props.size ? props.size : "0.875rem")};
	margin: ${(props) => props.margin};

	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
	text-transform: ${(props) => (props.uppercase ? "uppercase" : "")};
	line-height: ${(props) => (props.lineHeight ? props.lineHeight : 1.5)};
	display: ${(props) => props.display};
	text-align: ${(props) => props.align};
	color: ${(props) => {
		if (props.primary) {
			return props.theme.color.primary;
		}
		return props.color ? props.color : "#4F4F4F";
	}};
`;

const HelpText = styled.p<Props>`
	font-size: ${(props) => (props.size ? props.size : "0.875rem")};
	margin: ${(props) => props.margin};
	font-style: italic;
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
	text-transform: ${(props) => (props.uppercase ? "uppercase" : "")};
	line-height: ${(props) => (props.lineHeight ? props.lineHeight : 1.5)};
	display: ${(props) => props.display};
	text-align: ${(props) => props.align};
	color: ${(props) => {
		if (props.primary) {
			return props.theme.color.primary;
		}
		return props.color ? props.color : "#828282";
	}};
`;

export { Title, Subtitle, Uppertitle, Text, HelpText };
