import React, { useState, useEffect } from "react";
import { Box, Select, InputLabel, TextField, FormControl } from "@material-ui/core";

import { isNull } from "lodash";
import { Button } from "../../../elements";
import { ModalBase } from "../../Producer_bkp/ModalBase";
import DebateService, { IListCategory } from "../../../../services/comunityDebate.service";
import { CreateDebateCategoryModal } from "./CreateDebateCategoryModal";

export interface Props {
	onCreateDebateAction?: () => void;
}

const CreateDebateModalWrapper: React.FC<Props> = ({ onCreateDebateAction, ...props }: Props) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [categories, setCategories] = useState([] as IListCategory[]);
	const [selectedCategory, setSelectedCategory] = useState(null as number | null);
	const [categoryInformation, setCategoryInformation] = useState("");
	const [title, setTitle] = useState("");
	const [titleInformation, setTitleInformation] = useState("");
	const [description, setDescription] = useState("");

	useEffect(() => {
		const debateService = new DebateService();
		async function getCategories() {
			const response = await debateService.listCategories();
			setCategories(response.data);
			if (isNull(selectedCategory)) setSelectedCategory(response.data[0].id);
		}
		getCategories();
	}, [modalOpen, selectedCategory]);

	function handleModalClose() {
		setModalOpen(false);
	}

	function handleModalOpen() {
		setModalOpen(true);
	}

	function handleCreateDebate() {
		if (title.length === 0) {
			setTitleInformation("O título precisa ser preenchido");
			return;
		}
		if (!selectedCategory) {
			setCategoryInformation("É necessário escolher uma categoria");
			return;
		}
		const debateService = new DebateService();
		debateService.createDebate(selectedCategory, title, description).then((response) => {
			if (response.error) {
				const fieldNamesError = Object.keys(response.data);
				fieldNamesError.forEach((fieldNameError) => {
					switch (fieldNameError) {
						case "titulo":
							setTitleInformation(response.data[fieldNameError]);
							break;
						default:
							break;
					}
				});
			} else {
				if (onCreateDebateAction) {
					setTitle("");
					setDescription("");
					onCreateDebateAction();
				}

				setModalOpen(false);
			}
		});
	}

	return (
		<Box marginLeft='auto'>
			<Button variant='add' onClick={handleModalOpen}>
				Novo Debate
			</Button>
			<ModalBase title='Novo Debate' open={modalOpen} handleClose={handleModalClose} saveAction={handleCreateDebate}>
				<Box display='flex' alignItems='center' padding='50px 100px'>
					<Box width='100%'>
						<form noValidate autoComplete='off'>
							<FormControl>
								<Box display='flex' flexDirection='row'>
									<InputLabel htmlFor='category'>Categoria</InputLabel>
									<Select
										native
										id='category'
										onChange={(e) => {
											setSelectedCategory(Number(e.target.value));
											setCategoryInformation("");
										}}
										required>
										{categories?.map((category) => (
											<option key={category.id} value={category.id} selected={category.id === selectedCategory}>
												{category.name}
											</option>
										))}
									</Select>
									<CreateDebateCategoryModal onCreateAction={(id: number) => setSelectedCategory(id)} />
								</Box>
							</FormControl>
							<TextField
								id='content'
								fullWidth
								label='Título'
								value={title}
								onChange={(e) => {
									setTitle(e.target.value);
								}}
								required
								helperText={titleInformation}
								error={titleInformation.length > 0}
								onFocus={() => setTitleInformation("")}
							/>
							<TextField
								id='content'
								fullWidth
								label='Descrição'
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
							/>
						</form>
					</Box>
				</Box>
			</ModalBase>
		</Box>
	);
};

// CreateDebateModalWrapper.defaultProps = {
// };

export { CreateDebateModalWrapper as CreateDebateModal };
