import styled from "styled-components";
import media from "../../../../styles/media";

const ClassRatingsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	align-items: center;
	gap: 2rem;
	padding: 0.7rem 20px;
	border: 1px solid ${(props) => props.theme.color.primary};
	margin-top: -1px;
	${media.lessThan("medium")`
	justify-content: center;
	gap: .5rem;
		flex-direction: column;
		`}
	h4 {
		color: ${(props) => props.theme.color.primary};
		margin-right: auto;
		${media.lessThan("medium")`
			text-align: center;
			width: 100%;
			display: none;
		`}
	}
`;
export { ClassRatingsContainer };
