import styled from "styled-components";

export const VideoChaptersContainer = styled.div`
	display: flex;
	width: 100%;
	overflow: hidden;
	margin: 0 0 32px;
	.swiper-container {
		width: 100%;
		overflow: hidden;
	}
	.swiper-container {
		padding: 0 45px 0 25px;
	}

	.swiper-slide {
		width: auto !important;
	}

	.swiper-button-prev,
	.swiper-button-next {
		top: 0 !important;
		margin-top: 0 !important;
		width: 25px;
		height: 100%;
		font-weight: 900;
		&:after {
			font-size: 16px !important;
		}
	}

	.swiper-button-prev {
		left: 0;
		background: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0));
	}

	.swiper-button-next {
		right: 0;
		background: linear-gradient(to left, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0));
	}
`;

export const TimelineCard = styled.button`
	padding: 8px;
	border: 1px solid #eee;
	border-radius: 5px;
	background-color: white;
	text-align: left;
	cursor: pointer;
	transition: 0.3s;
	font-size: 1rem;
	&:hover {
		opacity: 0.7;
	}
	small,
	span {
		display: block;
	}
	small {
		color: black;
		opacity: 0.6;
		font-size: 0.8rem;
		margin-bottom: 6px;
	}
	span {
		color: ${(props) => props.theme.color.primary};
		font-weight: 600;
	}
`;
