import React, { useEffect, useState } from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { PageContainer, GreyContainer, InnerContentContainer, ContentLoadingError, FavoriteButton } from "../../components";
import FavoritesService from '../../services/favorites.service';
import Theme from '../../styles/theme';
import { IFavorite, TFavType } from '../../types/favorites';

import {
  FavoritosContainer,
  FavCategories,
  TagFavType,
  FavList,
  FavListItem,
  FavItemWrapper
} from './FavoritosStyled'

export interface Props {
}

const Favoritos: React.FC<Props> = ({
  children,
  ...props
}) => {

  const [favorites, setFavorites] = useState<IFavorite[]>([])
  const [filteredFavorites, setFilteredFavorites] = useState<IFavorite[]>([])
  const [favoritesCategories, setFavoritesCategories] = useState<TFavType[]>([])
  const [selectedCategories, setSelectedCategories] = useState<TFavType[]>([])
  const [unfaved, setUnfaved] = useState<number[]>([])

  const createFavLink = (fav: IFavorite) => {
    switch (fav.tipo) {
      case "ARTIGO":
        return `/artigo/${fav.slug}`
      case "DOWNLOAD":
        return fav.link
      case "VIDEO":
        return `/serie/${fav.slug}/${fav.id}`
      case "TOPICO":
        return `/noticias/${fav.id}`
      case "AULA":
        return `/cursos/${fav.ref2_slug}/${fav.id}`
      default:
        return '/'
    }
  }

  const extractCategories = (favorites: IFavorite[]): TFavType[] => {
    const categories: TFavType[] = []

    for (let i = 0; i < favorites.length; i++) {
      if (!categories.includes(favorites[i].tipo)) {
        categories.push(favorites[i].tipo)
      }
    }

    return categories.sort()
  }

  const handleSelectCategory = (sel: TFavType | "TODOS") => {
    if (sel === "TODOS") return setSelectedCategories([])
    if (selectedCategories.includes(sel)) {
      setSelectedCategories(selectedCategories.filter((cat: TFavType) => cat !== sel))
    } else {
      setSelectedCategories([...selectedCategories, sel])
    }
  }

  const getFavorites = async () => {
    const favoritesService = new FavoritesService();
    const response: IFavorite[] = await favoritesService.list();
    const permitidos = response.filter((fav: IFavorite) => fav.has_permission === true)
    setFavorites(permitidos)
    setFilteredFavorites(permitidos)
    setFavoritesCategories(extractCategories(permitidos))
  }

  const toggleFavorite = async (id: number, fav: TFavType) => {
    try {
      const favoritesService = new FavoritesService()

      await favoritesService.toggleFavorite(id, fav)

      if (unfaved.includes(id)) {
        setUnfaved(unfaved.filter((uid: number) => uid !== id))
      } else {
        setUnfaved([...unfaved, id])
      }
    } catch (error) {
      console.error(error)
    }
  }

  //Aplica os filtros
  useEffect(() => {
    if (selectedCategories.length > 0) {
      const filtered = favorites.filter((fav: IFavorite) => selectedCategories.includes(fav.tipo))
      setFilteredFavorites(filtered)
    } else {
      setFilteredFavorites(favorites)
    }
  }, [selectedCategories])

  useEffect(() => {
    getFavorites()
  }, [])

  return (
    <PageContainer
      pageTitle="Favoritos"
      showSearch={false}
      showBreadcrumb>
      <GreyContainer>
        <InnerContentContainer>
          <FavoritosContainer {...props}>
            <FavCategories>
              <TagFavType onClick={() => handleSelectCategory("TODOS")} className={selectedCategories.length < 1 ? "active" : ""}>TODOS</TagFavType>
              {favoritesCategories.map((cat: TFavType, index: number) => <TagFavType onClick={() => handleSelectCategory(cat)} className={selectedCategories.includes(cat) ? "active" : ""} key={index}>
                {cat === "DOWNLOAD" ? "MATERIAL" : cat === "TOPICO" ? "NOTÍCIA" : cat === "VIDEO" ? "EPISÓDIO" : cat}
              </TagFavType>)}
            </FavCategories>
            <FavList>
              {filteredFavorites.map((fav: IFavorite) => {
                return (
                  <React.Fragment key={fav.id}>
                    <FavItemWrapper>
                      {fav.tipo === "DOWNLOAD" ?
                        <FavListItem href={createFavLink(fav)} as="a">
                          <small>{fav.tipo}</small>
                          <big>{fav.titulo}</big>
                        </FavListItem>
                        :
                        <FavListItem to={createFavLink(fav)}>
                          <small>{fav.tipo === "TOPICO" ? "NOTÍCIA" : fav.tipo === "VIDEO" ? "EPISÓDIO" : fav.tipo}</small>
                          <big>{fav.titulo}</big>
                          {(fav.tipo === "AULA") &&
                            <div className="meta">
                              <span><b>Curso:</b> {fav.ref2_titulo}</span>
                              <span><b>Módulo:</b> {fav.ref1_titulo}</span>
                            </div>
                          }
                        </FavListItem>
                      }
                      <button onClick={() => toggleFavorite(fav.id, fav.tipo)}>
                        {unfaved.includes(fav.id) ? <AiOutlineStar size={20} color={Theme.color.primary} /> : <AiFillStar size={20} color={Theme.color.primary} />}
                      </button>
                    </FavItemWrapper>
                  </React.Fragment>
                )
              })}
            </FavList>
          </FavoritosContainer>
        </InnerContentContainer>
      </GreyContainer>
    </PageContainer>
  );
};

export { Favoritos };
