import styled from "styled-components";
import { fade } from "@material-ui/core/styles/colorManipulator";

const ReactionPickerContainer = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	position: absolute;
	left: -141px;
	top: 16px;
	z-index: 100;

	.reactions-counter {
		display: flex;
		flex-wrap: wrap;
	}
	.emoji-mart {
		width: 200px !important;
		padding-top: 10px;
		z-index: 999;
		border-color: ${(props) => props.theme.color.primary};
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		align-items: center;
		.emoji-mart-emoji {
			padding: 5px;
		}
	}
	.emoji-mart-category-list {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}
	.emoji-mart-category {
		li {
			margin-bottom: 3px;
		}
		.emoji-mart-emoji {
			border: 1px solid transparent;
			transition: all 0.5s ease;
			margin: 0 2px 2px 2px;
			&:active,
			&:focus,
			&:visited {
				outline: 0;
			}
			span {
				cursor: pointer;
			}
			&.selected {
				border: 1px solid ${(props) => props.theme.color.primary};
				background: ${(props) => fade(props.theme.color.primary, 0.2)};
				border-radius: 6px;
			}
			&:hover {
				&:before {
					background-color: ${(props) => fade(props.theme.color.primary, 0.4)};
					border-radius: 6px;
					transition: all 0.5s ease;
				}
			}
		}
	}
	.emoji-mart-anchors {
		display: none;
	}
	.emoji-mart-search {
		display: none;
	}
	.emoji-mart-bar {
		display: none;
	}
	.emoji-mart-scroll {
		overflow: auto;
		height: auto;
	}
	.emoji-mart-category-label {
		display: none;
	}

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: -10px;
		right: 29px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 13px 13px 13px;
		border-color: transparent transparent #fff transparent;
	}
	&::after {
		z-index: -1;
		border-width: 0 12px 12px 12px;
		top: -12px;
		right: 30px;
		border-color: transparent transparent ${(props) => props.theme.color.primary} transparent;
	}
`;

const ReactionSelectorContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	cursor: pointer;
`;
const ReactionPickerHeader = styled.div`
	padding: 15px 0 10px 0;
	border: 1px solid ${(props) => props.theme.color.primary};
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	border-bottom: 0px;
	background: #fff;
`;

const ReactionSelectorWrapper = styled.div`
	position: relative;
`;

export { ReactionPickerContainer, ReactionSelectorContainer, ReactionPickerHeader, ReactionSelectorWrapper };
