import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Typography } from '../../Typography';

export interface Props extends React.HTMLAttributes<HTMLElement> {
    icon: ReactNode;
    text: string;
}

const IconTextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    div{
      cursor: default
    }
`;

const IconTextWrapper: React.FC<Props> = ({
  icon,
  text,
  ...props
}: Props) => (
  <IconTextContainer {...props}>
    <div>
      {icon}
    </div>
    <div>

      <Typography variant="text" color="#333" size="8px" margin="6px 0 0 0">{text}</Typography>
    </div>
  </IconTextContainer>
);


export { IconTextWrapper };
