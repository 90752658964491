import React from "react";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Typography } from "../../../Typography";
import { CardWrapper, CardFooter, CardPictureHeader } from "../..";
import { CourseCardIconsToolbar } from "./CourseCardIconsToolbar";
import {
	// IconsGroup,
	// NumericTextWrapper,
	EditIcon,
	ViewIcon,
} from "../../Icons";

export interface Props {
	course: {
		name: string;
		slug?: string;
		imageUrl: string;
		teachers: string[];
		description: string;
		certificate: boolean;
		status: string;
		releasedLessons: number;
		discountPercent?: number;
		bestSelling?: boolean;
		progress?: string;
		recommended?: boolean;
		hasComments?: boolean;
		producerInformation?: {
			students: number;
			modules: number;
			suitableCourses: number;
		};
		created?: Date;

		hasAccess?: boolean;
		landingPageLink?: string;
		isOpenForSale?: boolean;
	};
	editUrl?: string;
	viewUrl?: string;
	hasComments?: boolean;
	plusExclusive?: {
		isExclusive: boolean;
		message: string;
	};
}

const CourseCardWrapper: React.FC<Props> = ({ plusExclusive, course, hasComments, editUrl, viewUrl, ...props }: Props) => {
	return (
		<CardWrapper height='100%' padding='0'>
			<CardPictureHeader locked={course.isOpenForSale && !course.hasAccess} hasComments={hasComments} {...course} />
			<Box width='100%' padding='0 20px'>
				<Typography variant='title' size='1rem' margin='10px 0 0 0'>
					{course.name}
				</Typography>
				<Typography variant='subtitle' size='0.75rem' color='#979797'>
					{course.teachers.map((teacher, i) => {
						const tLen = course.teachers.length;
						if (tLen === i + 1) {
							return teacher;
						}
						return `${teacher}, `;
					})}
				</Typography>
				{course.description.length > 150 ? (
					<Typography variant='text' color='#333' margin='20px 0'>
						<div
							dangerouslySetInnerHTML={{
								__html: `${course.description.substring(0, 140)} [ ... ]`,
							}}
						/>
					</Typography>
				) : (
					<Typography variant='text' color='#333' margin='20px 0'>
						<div dangerouslySetInnerHTML={{ __html: course.description }} />
					</Typography>
				)}
				{/* TODO: quando cursos exclusivos forem relevantes a gente ativa isso e formata */}
				{/* {plusExclusive && plusExclusive.isExclusive && "Este curso é exclusivo para assinantes"} */}
			</Box>
			<CardFooter>
				<CourseCardIconsToolbar certificate={course.certificate} status={course.status} releasedLessons={course.releasedLessons} />
			</CardFooter>
			{course.created && (
				<Box padding='0 10px 10px' width='100%' display='flex' justifyContent='space-between'>
					<Typography variant='subtitle' size='0.75rem' color='#979797'>
						Criado em:{" "}
						{course.created.toLocaleDateString("pt-BR", {
							day: "2-digit",
							month: "long",
							year: "numeric",
						})}
					</Typography>
					<Box display='grid' gridTemplateColumns='1fr 1fr' gridGap='5px'>
						{editUrl && (
							<Link to={editUrl}>
								<EditIcon size='20px' />
							</Link>
						)}
						{viewUrl && (
							<Link to={viewUrl}>
								<ViewIcon size='20px' />
							</Link>
						)}
					</Box>
				</Box>
			)}
		</CardWrapper>
	);
};

CourseCardWrapper.defaultProps = {
	course: {
		name: "",
		imageUrl: "",
		teachers: [""],
		description: "",
		certificate: false,
		status: "",
		releasedLessons: 0,
	},
	editUrl: "#",
	viewUrl: "#",
};

export { CourseCardWrapper as CourseCard };
