import React, { ReactNode } from "react";
import clsx from "clsx";
import { IoIosAdd } from "react-icons/io";
import { ButtonContainer, LoadingButton } from "./ButtonStyled";
import { IconWrapper } from "../Icons";

export interface Props {
	/**
	 * Variação contained | outlined | link
	 */
	variant?: "outlined" | "contained" | "link" | "save" | "add" | "loading";
	/** Cor primary | default  */
	color?: "primary" | "default" | undefined;
	onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
	display?: string;
	margin?: string;
	disabled?: boolean;
	children?: ReactNode;
	type?: "button" | "submit" | "reset" | undefined;
	title?: string;
}

const ButtonWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const { color, variant, disabled, margin, display, children, type, title } = props;
	return (
		<ButtonContainer
			{...props}
			margin={margin}
			display={display}
			variant={variant}
			color={color}
			disabled={disabled || variant === "loading"}
			type={type}
			className={clsx([variant, color])}
			title={title}>
			{variant === "save" && (
				<IconWrapper size='36px' rounded primaryColor={color === "primary"} filled={false} primaryBorder={color === "primary"} border={color !== "primary"} icon={<IoIosAdd size='30px' />} />
			)}
			{variant === "loading" && (
				<LoadingButton>
					<div className='loadingio-spinner-dual-ring-xvfvwingcv'>
						<div className='ldio-tqeh7dyshyf'>
							<div />
							<div>
								<div />
							</div>
						</div>
					</div>
				</LoadingButton>
			)}
			{children}
			{variant === "add" && <IconWrapper size='36px' rounded primaryColor={color === "primary"} filled icon={<IoIosAdd color='#FFF' size='30px' />} />}
		</ButtonContainer>
	);
};

ButtonWrapper.defaultProps = {
	variant: "contained",
	color: "default",
	margin: "0px",
	display: "inline-block",
};

export { ButtonWrapper as Button };
