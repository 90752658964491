import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Swiper, { Pagination, Autoplay } from "swiper";
import { Swiper as Slider, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { isMobile } from "../../../../isMobile";
import { CourseCard, CourseCardLoading } from "../../../elements/";
import { ContentLoadingError } from "../../../../components/ContentLoadingError";
import CourseService from "../../../../services/course.service";

import { HomeCoursesCarouselContainer } from "./HomeCoursesCarouselStyled";
import { Link } from "react-router-dom";
import { Course } from "../../../../types/course";

export interface Props { }

Swiper.use([Pagination, Autoplay]);

const HomeCoursesCarouselWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const [courses, setCourses] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const loadCursos = useCallback(async () => {
		try {
			if (!loading) {
				setLoading(true);
			}

			const courseService = new CourseService();

			const response = await courseService.list();

			const normal = response.filter((categoria: any) => {
				return categoria.nome !== "Cursos Premium";
			});

			const listaCursos = normal.map((curso: any) => {
				return curso.cursos;
			});

			setCourses(listaCursos.flat().filter((item: any) => item.slug !== "").slice(0, 10));
			setLoading(false);
		} catch (error) {
			setError(true);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		loadCursos();
	}, []);

	return (
		<HomeCoursesCarouselContainer {...props}>
			{loading ? (
				<>
					<Box
						width='100%'
						display='grid'
						gridTemplateColumns={{
							xs: "100%",
							sm: "100%",
							md: "24% 24% 24% 24%",
						}}
						gridRowGap='15px'
						gridColumnGap='1%'>
						<CourseCardLoading /> <CourseCardLoading />
						<CourseCardLoading /> <CourseCardLoading />
					</Box>
				</>
			) : (
				<>
					{error ? (
						<>
							<ContentLoadingError
								refresh={() => {
									loadCursos();
								}}
							/>
						</>
					) : (
						<>
							<Slider
								pagination={{
									clickable: true,
								}}
								autoplay
								slidesPerView={isMobile.any() ? 1 : courses.length > 4 ? 4 : courses.length}
								spaceBetween={30}
								autoHeight
								loop>
								{courses ? (
									<>
										{courses.map((curso: any) => {
											return curso.tem_acesso && (
												<SwiperSlide key={curso.id}>
													<Link className='courseLink' to={`/cursos/${curso.slug}`}>
														<CourseCard
															course={{
																name: curso.nome,
																slug: curso.slug,
																imageUrl: curso.imagem,
																teachers: [curso.professor],
																description: "",
																certificate: curso.certificado,
																status: curso.percentual_conclusao > 0 ? curso.curso_concluido : "notStarted",
																releasedLessons: curso.count_aulas_liberadas,
																progress: curso.percentual_conclusao,
															}}
														/>
													</Link>
												</SwiperSlide>
											)
										})}
									</>
								) : (
									<>
										<ContentLoadingError
											refresh={() => {
												loadCursos();
											}}
										/>
									</>
								)}
							</Slider>
						</>
					)}
				</>
			)}
		</HomeCoursesCarouselContainer>
	);
};

HomeCoursesCarouselWrapper.defaultProps = {};

export { HomeCoursesCarouselWrapper as HomeCoursesCarousel };
