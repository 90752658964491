import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";

import { ModuleCard, ModuleClassCard } from "../../..";
import { Module, ModuleClass } from "../../../../../types/course";

import { useCourseDetail } from "../../../../../context/courseDetail/courseDetailContext";
import { ModuleCardWrapperContainer } from "./ModuleCardWrapperStyled";

export interface Props extends React.HTMLAttributes<HTMLElement> {
	isMultimidia?: boolean;
	module: Module;
	showArrow?: boolean;
	hasComments?: boolean;
}

const ModuleCardWrapper: React.FC<Props> = ({ module, showArrow, ...props }: Props) => {
	const { activeModule, updateActiveModule, activeModuleClass, updateActiveModuleClass } = useCourseDetail()!;
	const activeModuleId = activeModule ? activeModule.id : 0;
	const [showClasses, setShowClasses] = useState(false);
	const activeModuleClassId = activeModuleClass ? activeModuleClass.id : 0;

	useEffect(() => {
		setShowClasses(!!(activeModule && module.id === activeModuleId));
		isEveryClassOpen();
	}, [activeModule, activeModuleId, module.id]);

	const handleChangeActiveModuleClass = (module: Module, moduleClass: ModuleClass) => {
		updateActiveModule(module);
		updateActiveModuleClass(moduleClass);
		window.scrollTo(0, 0);
	};

	const handleChangeActiveModule = (module: Module) => {
		setShowClasses(!showClasses);

		if (!module.released) {
			updateActiveModule(module);
			updateActiveModuleClass(null);
		}
		// else {
		//   updateActiveModuleClass(module.moduleClasses[0]);
		// }
	};
	const isEveryClassOpen = () => {
		//console.log({ "mod": module, "isECR": module.moduleClasses?.every((moduleClass: any) => moduleClass.isReleased !== false) });
		return module.moduleClasses?.every((moduleClass: any) => moduleClass.isReleased !== false);
	};
	return (
		<ModuleCardWrapperContainer
			{...props}
			module={module}
			className={clsx({
				active: showClasses || module.id === activeModuleId,
			})}>
			<ModuleCard showArrow={showArrow} isOpen={showClasses} module={module} action={handleChangeActiveModule} />
			{/* {module.released && (
        
      )} */}

			<AnimatePresence initial={false}>
				{showClasses && (
					<motion.section
						key='content'
						initial='collapsed'
						animate='open'
						exit='collapsed'
						variants={{
							open: { opacity: 1, height: "auto" },
							collapsed: { opacity: 0, height: 0 },
						}}
						transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}>
						{module.moduleClasses?.map((moduleClass: ModuleClass, index: number) => (
							<ModuleClassCard
								isActive={moduleClass.id === activeModuleClassId}
								onClick={() => {
									if (!moduleClass.isReleased) return;
									if (moduleClass.blockedByQuiz) return;
									handleChangeActiveModuleClass(module, moduleClass);
								}}
								moduleClass={moduleClass}
								index={index + 1}
								key={index}
							/>
						))}
						{module.quizObject?.id ? (
							<ModuleClassCard
								isQuiz
								isActive={module.quizID === activeModuleClassId}
								onClick={() => {
									if (!module.isQuizReleased) return;
									if (!isEveryClassOpen()) return;
									handleChangeActiveModuleClass(module, {
										id: module.quizID ? module.quizID : 0,
										name: module.quizObject?.nome ? module.quizObject?.nome : "",
										releaseDate: module.quizReleaseDate ? module.quizReleaseDate : "",
										data_liberacao_ou_publicacao: module.quizReleaseDate ? module.quizReleaseDate : "",
										materialsCount: 0,
										videoUrl: "",
										description: module.quizObject?.descricao ? module.quizObject?.descricao : "",
										materials: [],
										courseName: "",
										moduleName: module.name,
										completed: module.quizObject?.chances_esgotadas ? module.quizObject?.chances_esgotadas : false,
										debates: [],
										isReleased: module.isQuizReleased ? module.isQuizReleased : false,
										isQuiz: true,
										timestamp: 0,
										isOptional: false,
										blockedByQuiz: false,
										acceptsUpload: false,
										blockedByUpload: false,
										maxUploadableFiles: 0,
										timeline: [],
										showComments: false,
										rating: 0,
										numberOfRatings: 0,
									});
								}}
								moduleClass={{
									id: module.quizID ? module.quizID : 0,
									name: module.quizObject.nome,
									releaseDate: module.quizReleaseDate ? module.quizReleaseDate : "",
									data_liberacao_ou_publicacao: module.quizReleaseDate ? module.quizReleaseDate : "",
									materialsCount: 0,
									videoUrl: "",
									description: module.quizObject.descricao,
									materials: [],
									courseName: "",
									moduleName: module.name,
									completed: module.quizObject.chances_esgotadas,
									debates: [],
									isReleased: module.isQuizReleased ? module.isQuizReleased : false,
									timestamp: 0,
									isOptional: false,
									blockedByQuiz: false,
									acceptsUpload: false,
									blockedByUpload: false,
									maxUploadableFiles: 0,
									timeline: [],
									showComments: false,
									rating: 0,
									numberOfRatings: 0,
								}}
								index={module.quizObject.id}
							/>
						) : (
							""
						)}
					</motion.section>
				)}
			</AnimatePresence>
		</ModuleCardWrapperContainer>
	);
};
//ModuleCardWrapper.whyDidYouRender = true;
export { ModuleCardWrapper };
