import BaseService from "./base.service";
import api from "./api";
import { headers as configHeaders } from "./config";

export default class CourseClassDebatesService extends BaseService {
	constructor(id, timestamp) {
		//timestamp serve pra marcar os comentários como lidos no get, serve mais pra monitores
		if (!id) throw new Error("Missing Class ID when trying to retrieve debates");
		super(`cursos/aula/${id}/comentarios`);
		this.timestamp = timestamp;
	}

	async list() {
		let resourceURL = `${this.resource}-v2`;

		try {
			const response = await api({
				method: "GET",
				url: this.timestamp ? `${resourceURL}/?data=${this.timestamp}` : resourceURL,
				// url: resourceURL,
			});

			// console.log("LIST COURSE COMMENTS ==>", response.data);

			return response.data;
		} catch (err) {
			console.error(`Erro ao listar Comments ${this.resource} base service:`, err);
			console.error(`Erro ao listar Comments ${this.resource} base service:`, err.response);
			return {
				error: true,
				status: err.response ? err.response.status : null,
				data: err.response ? err.response.data : null,
			};
		}
	}

	async create(data, headers = configHeaders) {
		try {
			const response = await api.post(`${this.resource}/novo/`, data, headers);
			return { status: response.status, data: response.data };
		} catch (err) {
			console.log(`erro ao criar ${this.resource} :`, err);
			// console.log(`erro ao criar ${this.resource} :`, err.response);
			return {
				error: true,
				status: err.response ? err.response.status : null,
				data: err.response ? err.response.data : null,
			};
		}
	}
}
