import styled, { css } from "styled-components";

interface Props {
	rounded?: boolean;
}

const ImageInputContainer = styled.div<Props>`
	background: #fff;
	border-radius: 6px;
	width: 100%;
	height: 128px;
	cursor: pointer;
	.no-preview {
		border-width: 1px;
		border-style: dashed;
		${(props) => `
            border-color: ${props.theme.color.primary}; 
        `};
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: 40px;
		${(props) =>
			props.rounded &&
			css`
				border-radius: 6px;
			`}
	}
	.preview-wrapper {
		width: 100%;
		height: 100%;
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
		${(props) =>
			props.rounded &&
			css`
				border-radius: 50%;
				overflow: hidden;
			`}
	}
	.image-input {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
	.image-wrapper {
		width: 26px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.text {
		padding-top: 5px;
		font-size: 10px;
		color: #333;
	}
`;

export { ImageInputContainer };
