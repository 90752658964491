import React, { ReactNode } from 'react';

import {
  createStyles, Theme, withStyles, WithStyles,
} from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { IoMdCreate } from 'react-icons/io';
import { Button, IconWrapper } from '..';
import { Typography } from '../../Typography';

export interface Props {
  title: string;
  buttonText: string;
  buttonVariant?: 'outlined' | 'contained' | 'link';
  children?: ReactNode;
}
const styles = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  muiDialogTitle: {
    backgroundColor: '#F2F2F2',
    alignItems: 'center',
    display: 'flex',
    color: theme.palette.primary.main,
  },
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const {
    children, classes, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.muiDialogTitle} {...other}>
      <IconWrapper size="28px" backgroundColor="#FFF" rounded primaryColor primaryBorder icon={<IoMdCreate />} />
      <Typography margin="0 0 0 15px" display="inline-block" variant="title" primary>{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    background: theme.palette.primary.main,
    margin: 0,
    justifyContent: 'space-between',
    padding: '10px 20px',
  },
}))(MuiDialogActions);

const ModalWrapper: React.FC<Props> = ({
  title,
  buttonText,
  buttonVariant,
  children,
  ...props
}: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button color="primary" variant={buttonVariant} onClick={handleClickOpen}>
        {buttonText}
      </Button>
      <Dialog fullWidth maxWidth="lg" open={open}>
        <DialogTitle id="modal-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
          {children}
        </DialogContent>
        <DialogActions>
          <Button variant="link" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="outlined">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalWrapper.defaultProps = {
  buttonVariant: 'contained',
};

export { ModalWrapper as Modal };
