import styled from 'styled-components';
import media from 'styled-media-query';


interface Props {
  backgroundImg: string
}

const SystemActivityCardContainer = styled.div<Props>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 100%;
  padding: 10px;
  background-image: url(${(props) => props.backgroundImg});
  ${media.lessThan('medium')`
    min-height: 190px;
  `}

`;
export { SystemActivityCardContainer };
