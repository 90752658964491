import React, { useState } from "react";
import { IssueCertificateLinkContainer } from "./IssueCertificateLinkStyled";
import { IssueCertificateIcon } from "../Icons";
import CourseService from "../../../services/course.service";
import loadingGif from "../../../assets/images/loading-bg-blue.gif";

export interface Props {
	certificateUrl: string;
	isSeal?: boolean;
}

const IssueCertificateLinkWrapper: React.FC<Props> = ({ certificateUrl, isSeal, ...props }: Props) => {
	const [loading, setLoading] = useState(false);

	const getCertificado = async (certificateUrl: string) => {
		setLoading(true);

		const courseService = new CourseService();
		const pdf = await courseService.get_certificate(certificateUrl);

		const blob = new Blob([pdf], { type: "application/pdf" });
		const blobURL = URL.createObjectURL(blob);

		var a = document.createElement("a");
		document.body.appendChild(a);
		a.setAttribute("style", "display: none");
		a.setAttribute("target", "_self");
		a.href = blobURL;
		a.download = certificateUrl.split("/")[7];

		a.click();

		window.URL.revokeObjectURL(blobURL);

		//window.open(blobURL);

		setLoading(false);
	};

	return (
		<IssueCertificateLinkContainer onClick={() => getCertificado(certificateUrl)} disabled={loading} title='Emita seu certificado' {...props}>
			{loading ? (
				<>
					<img className='loader' src={loadingGif} alt='' />
					Emitindo
				</>
			) : (
				<>
					<IssueCertificateIcon color='#FFF' />
					Emita seu {isSeal ? "selo" : "certificado"}
				</>
			)}
		</IssueCertificateLinkContainer>
	);
};

IssueCertificateLinkWrapper.defaultProps = {};

export { IssueCertificateLinkWrapper as IssueCertificateLink };
