import styled from 'styled-components';


const ReactionItemsWrapper = styled.div`
    display: flex;
    > div{
        padding: 7px 0px;
        &:not(:first-child){
            margin-left: 20px;
        }
    }
`;


export { ReactionItemsWrapper };
