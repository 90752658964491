import React from "react";

export const trackGA = (category: string, action: string, label: string, value?: string): void => {
	//console.log("TRACK");

	//@ts-ignore
	window.dataLayer.push({
		event: "event",
		eventProps: {
			category: category,
			action: action,
			label: label,
			value: value ? value : "0",
		},
	});
};
