import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../Typography';

export interface Props extends React.HTMLAttributes<HTMLElement> {
    text: string;
    value: number;
}

const IconTextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    div{
      cursor: default
    }
`;

const NumericTextWrapper: React.FC<Props> = ({
  text,
  value,
  ...props
}: Props) => (
  <IconTextContainer {...props}>
    <Typography variant="text" margin="6px 0 0 0" fontWeight="bold" primary>{value}</Typography>
    <div>
      <Typography variant="text" color="rgba(0, 0, 0, 0.5)" size="12px" margin="6px 0 0 0">{text}</Typography>
    </div>
  </IconTextContainer>
);

export { NumericTextWrapper };
