import styled from "styled-components";
import { darken } from "polished";
// import media from "../../styles/media";

const HubPosGraduacaoContainer = styled.div`
	position: relative;
	.clearFilter {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 30px;
		span {
			text-decoration: none;
			white-space: nowrap;
			border-radius: 100px;
			font-weight: 400;
			height: 35px;
			padding: 0 20px;
			line-height: 30px;
			font-size: 0.75rem;
			color: #222b37;
			cursor: pointer;
			box-sizing: border-box;
			background-color: #f2f2f2;
			border: 1px solid #e0e0e0;
			transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

			margin: 5px 5px 0 5px;

			&:focus {
				border: 1px solid #e0e0e0;
			}
			&:hover {
				background-color: ${darken(0.2, "#E0E0E0")};
			}
		}
	}

	a,
	.courseLink {
		text-decoration: none;
		position: relative;
		transition: 0.3s ease;
		top: 0;
		h1 {
			transition: 0.3s ease;
		}
		&:hover {
			top: -10px;
			h1 {
				color: ${(props) => props.theme.color.primary};
			}
		}
	}
	.resultDiv {
		margin: 0 0 50px;
	}
	.coursesCounter {
		color: #f2c94c;
		padding-left: 8px;
	}
`;
export { HubPosGraduacaoContainer };
