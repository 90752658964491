import styled from "styled-components";
import { Props } from "./index";
import { darken } from "polished";

const Tag = styled.span`
	text-decoration: none;
	white-space: nowrap;
	border-radius: 100px;
	font-weight: 400;
	height: 35px;
	padding: 0 20px;
	line-height: 30px;
	font-size: 0.75rem;
	color: #222b37;
	cursor: pointer;
	box-sizing: border-box;
	background-color: #f2f2f2;
	border: 1px solid #e0e0e0;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	margin: 5px 5px 0 0;

	&:focus {
		border: 1px solid #e0e0e0;
	}
	&:hover {
		background-color: ${darken(0.2, "#E0E0E0")};
	}
	&.active {
		background-color: ${darken(0.2, "#E0E0E0")};
	}
`;

export { Tag };
