import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { IoMdRefresh } from "react-icons/io";
import { ContentLoadingError, Typography } from "../../components";
import { Course, ModuleClass } from "../../types/course";
import { CreateDebate } from "../../components/features/Debate";
import CourseClassDebatesService from "../../services/courseClassDebatesService.service";
import { Button } from "../../components/elements";
import { DebateItem, DebateItemLoading } from "../../components/features/Debate";
import { useDebateContext } from "../../context/debate/debateContext";
import { useDebate } from "../../hooks/useDebate";
import { useUserContext } from "../../context/user/AuthContext";
import { RefreshCommentsButton } from "./CourseDetailStyled";

interface Props {
	course: Course;
	activeModuleClass: ModuleClass;
	timestamp?: number;
	onAction?: () => void;
}

const CourseDetailDebates: React.FC<Props> = ({ course, timestamp, activeModuleClass, onAction, ...props }) => {
	const { userData } = useUserContext();
	const classDebatesService = new CourseClassDebatesService(activeModuleClass?.id, timestamp);
	const { debates, updateDebates } = useDebateContext()!;
	const { loading, list, loadMoreComments, hasNextPage } = useDebate({
		service: classDebatesService,
		successMessage: "Comentário Salvo!",
		currentDebates: debates,
		updateDebates: updateDebates,
		timestamp: activeModuleClass.timestamp,
	});

	const handleCreateDebate = () => {
		list();
	};

	useEffect(() => {
		if (activeModuleClass) {
			list();
		}
	}, [activeModuleClass]);

	return (
		<>
			{course.allowComments && (
				<>
					<Box margin='20px 0' display='flex' flexWrap='wrap' justifyContent='space-between'>
						<Typography variant='title'>Comentários</Typography>
						{!loading && (
							<RefreshCommentsButton onClick={handleCreateDebate}>
								<IoMdRefresh size={24} />
								&nbsp; Atualizar Comentários
							</RefreshCommentsButton>
						)}
						<CreateDebate lessonId={activeModuleClass.id} successCallback={handleCreateDebate}
							onAction={onAction ? () => onAction() : () => { }}
						/>
					</Box>
					{loading && (
						<>
							<DebateItemLoading />
							<DebateItemLoading />
							<DebateItemLoading />
						</>
					)}
					{!loading && (
						<>
							{debates ? (
								<>
									{debates?.map((debate: any) => (
										<React.Fragment key={debate.id}>
											{onAction ? (
												<DebateItem
													isAuthorUser={debate.comment.author.id === userData.user_id}
													key={debate.id}
													debateItem={debate}
													type='lesson'
													entityId={activeModuleClass.id}
													replySuccessCallBack={handleCreateDebate}
													onAction={() => onAction()}
												/>
											) : (
												<DebateItem
													isAuthorUser={debate.comment.author.id === userData.user_id}
													key={debate.id}
													debateItem={debate}
													type='lesson'
													entityId={activeModuleClass.id}
													replySuccessCallBack={handleCreateDebate}
													onAction={() => { }}
												/>
											)}
										</React.Fragment>
									))}

									{hasNextPage && (
										<>
											<Box margin='20px 0 10px 0' display='flex' justifyContent='center'>
												{loading ? (
													<Button variant='loading' color='primary'>
														Carregando
													</Button>
												) : (
													<Button
														variant='contained'
														color='primary'
														onClick={() => {
															loadMoreComments();
														}}>
														Carregar mais comentários
													</Button>
												)}
											</Box>
										</>
									)}
								</>
							) : (
								<ContentLoadingError
									refresh={() => {
										list();
									}}
									loadedResourceErrorMsg='os comentários'
								/>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export default CourseDetailDebates;
