import React from 'react';
import { BestSellingSealContainer } from './BestSellingSealStyled'

export interface Props {
  size?: number;
  background?: string;
  strokeColor?: string;
  heartColor?: string;
  ribbonColor?: string;
}

const BestSellingSealWrapper: React.FC<Props> = ({
  ...props
}: Props) => {
  return (
    <BestSellingSealContainer
      {...props}
    >
      <svg width={props.size ? props.size : 45} height={props.size ? props.size : 45} viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="ribbon right" d="M40.0294 31.5513C39.1556 31.7917 37.7441 32.2748 36.8481 32.4096C36.8481 33.9832 37.475 36.4646 35.5607 37.9886C33.8196 39.4048 32.9111 38.9561 30.84 38.4177L33.0502 41.0069L39.2058 47.1855C39.3113 47.292 39.4437 47.3678 39.5888 47.4046C39.7338 47.4414 39.8862 47.4379 40.0294 47.3945C40.1727 47.3511 40.3015 47.2695 40.402 47.1582C40.5026 47.047 40.5711 46.9104 40.6003 46.7631L41.6648 41.4542L46.9127 40.3858C47.0595 40.3565 47.1956 40.2877 47.3064 40.1867C47.4172 40.0858 47.4985 39.9565 47.5418 39.8127C47.585 39.6689 47.5885 39.516 47.5518 39.3704C47.5151 39.2248 47.4397 39.0919 47.3336 38.986L40.0294 31.5513Z"/>
        <path className="ribbon left" d="M6.32357 41.4542L7.38801 46.7631C7.4172 46.9104 7.48574 47.047 7.5863 47.1582C7.68687 47.2695 7.81568 47.3511 7.95893 47.3945C8.10219 47.4379 8.25451 47.4414 8.39958 47.4046C8.54465 47.3678 8.67702 47.292 8.78252 47.1855L14.9381 41.0069L18.3946 37.1303C16.3317 37.6603 15.8441 38.1091 14.103 36.7011C12.362 35.2931 10.612 34.9259 10.6698 32.8387C9.75963 32.7035 8.12355 31.7967 7.23657 31.5513L0.613529 38.986C0.507406 39.0919 0.431968 39.2248 0.395289 39.3704C0.358611 39.516 0.362072 39.6689 0.405304 39.8127C0.448535 39.9565 0.529909 40.0858 0.640716 40.1867C0.751522 40.2877 0.88759 40.3565 1.03436 40.3858L6.32357 41.4542Z"/>
        {/* <path className="stroke" d="M5.85785 26.3226C6.09715 27.391 3.3824 29.9419 4.29007 31.7889C5.19773 33.6358 8.86965 33.1306 9.52977 33.9589C10.1899 34.7871 8.85315 38.2656 10.4704 39.566C12.0877 40.8663 15.1738 38.7874 16.131 39.2512C17.0882 39.715 17.3852 43.3924 19.4316 43.8893C21.478 44.3863 23.252 41.1313 24.366 41.1313C25.4799 41.1313 27.3943 44.3283 29.3169 43.8893C31.2395 43.4504 31.6686 39.6985 32.6175 39.2512C33.5664 38.804 36.6607 40.858 38.278 39.566C39.8953 38.2739 38.5668 34.7871 39.2187 33.9589C39.8706 33.1306 43.5672 33.6441 44.4584 31.7889C45.3496 29.9337 42.6513 27.3744 42.8906 26.3226C43.1299 25.2707 46.645 24.1775 46.645 22.082C46.645 19.9866 43.0969 18.8354 42.8741 17.8415C42.6513 16.8476 45.3496 14.2221 44.4419 12.3752C43.5342 10.5282 39.8623 11.0335 39.2187 10.2052C38.5751 9.377 39.8953 5.89844 38.278 4.59812C36.6607 3.2978 33.5747 5.37665 32.6175 4.91285C31.6603 4.44904 31.3633 0.771698 29.3169 0.27476C27.2705 -0.222177 25.4799 3.03276 24.366 3.03276C23.252 3.03276 21.3872 -0.172483 19.4151 0.27476C17.443 0.722004 17.0634 4.4656 16.1145 4.91285C15.1655 5.36009 12.0712 3.30608 10.4539 4.59812C8.83665 5.89016 10.1651 9.377 9.51327 10.2052C8.8614 11.0335 5.18123 10.4868 4.29007 12.3752C3.3989 14.2636 6.09715 16.7897 5.85785 17.8415C5.61856 18.8934 2.08691 19.9866 2.08691 22.082C2.08691 24.1775 5.63506 25.3287 5.85785 26.3226Z"/> */}
        <path className="background" d="M7.93034 25.9404C8.13776 26.8878 5.78457 29.1497 6.57135 30.7874C7.35813 32.4251 10.541 31.9771 11.1132 32.7115C11.6854 33.4459 10.5267 36.5303 11.9286 37.6833C13.3305 38.8363 16.0056 36.993 16.8353 37.4042C17.665 37.8155 17.9225 41.0762 19.6963 41.5168C21.4701 41.9574 23.0079 39.0713 23.9735 39.0713C24.9391 39.0713 26.5985 41.906 28.265 41.5168C29.9316 41.1276 30.3035 37.8008 31.1261 37.4042C31.9486 37.0077 34.6308 38.8289 36.0327 37.6833C37.4346 36.5376 36.2831 33.4459 36.8481 32.7115C37.4132 31.9771 40.6175 32.4324 41.39 30.7874C42.1625 29.1423 39.8236 26.8731 40.031 25.9404C40.2384 25.0077 43.2854 24.0383 43.2854 22.1803C43.2854 20.3223 40.2098 19.3015 40.0167 18.4203C39.8236 17.539 42.1625 15.211 41.3757 13.5733C40.5889 11.9356 37.406 12.3836 36.8481 11.6492C36.2902 10.9148 37.4346 7.83037 36.0327 6.67738C34.6308 5.52438 31.9558 7.3677 31.1261 6.95644C30.2964 6.54519 30.0389 3.2845 28.265 2.84386C26.4912 2.40323 24.9391 5.28938 23.9735 5.28938C23.0079 5.28938 21.3914 2.44729 19.682 2.84386C17.9725 3.24043 17.6435 6.55987 16.821 6.95644C15.9984 7.35301 13.3162 5.53173 11.9143 6.67738C10.5124 7.82302 11.664 10.9148 11.0989 11.6492C10.5339 12.3836 7.34383 11.8989 6.57135 13.5733C5.79888 15.2477 8.13776 17.4876 7.93034 18.4203C7.72291 19.3529 4.66162 20.3223 4.66162 22.1803C4.66162 24.0383 7.73722 25.0591 7.93034 25.9404Z"/>
        <path className="heart" d="M35.5605 19.0719V20.0972C35.5443 20.166 35.5257 20.2371 35.5142 20.3082C35.3683 21.4298 34.9997 22.5118 34.4296 23.4918C33.9487 24.272 33.3964 25.0068 32.7796 25.6868C31.9885 26.5846 31.1368 27.4283 30.2304 28.2122C29.3985 28.9278 28.5526 29.6297 27.6812 30.2765C26.5225 31.1389 25.3175 31.9486 24.1356 32.7858C24.0966 32.82 24.0463 32.8388 23.9942 32.8388C23.9421 32.8388 23.8919 32.82 23.8529 32.7858C22.8123 32.061 21.744 31.3683 20.7313 30.6045C19.6653 29.7994 18.6456 28.9485 17.6167 28.0814C16.3221 26.9915 15.156 25.7604 14.1405 24.4116C13.3698 23.3923 12.8349 22.2177 12.5739 20.971C12.4928 20.6775 12.4395 20.4091 12.3862 20.1293V19.0719C12.4001 19.0122 12.4117 18.9549 12.4256 18.8953C12.5276 18.4365 12.5925 17.9778 12.7385 17.5191C13.2274 16.0012 14.2835 14.7254 15.6909 13.9524C17.0009 13.1953 18.5421 12.9292 20.0337 13.2024C21.4886 13.4402 22.8153 14.1697 23.788 15.2667C23.8356 15.3313 23.8768 15.4005 23.9108 15.4731C24.1634 15.2231 24.3511 15.0304 24.5481 14.8492C25.7521 13.7357 27.3369 13.1138 28.9847 13.1083C30.6325 13.1028 32.2215 13.714 33.4331 14.8194C34.3749 15.6661 35.0405 16.7712 35.345 17.9939C35.4469 18.3448 35.4933 18.7072 35.5605 19.0719Z"/>
      </svg>
    </BestSellingSealContainer>
  );
};

export { BestSellingSealWrapper  as BestSellingSeal };
