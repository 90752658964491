import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { Typography, PageContainer, GreyContainer, InnerContentContainer } from "../../components";
//import { Button } from "../../components/elements";
import { Comment } from "../../components/features/Debate";
// import {
// 	//comunityDebates,
// 	otherDebatesData,
// } from "../../mockData/debateDataMock";

import { DebateComment as DebateCommentType, DebateItem as DebateItemType } from "../../types/debate";

// import { LikeButton } from "../ArticleDetail/ArticleDetailStyled";
// import ComunityService from "../../services/comunity.service";
import DetailCommentService from "../../services/detailComments.service";

//import { IoMdThumbsUp } from "react-icons/io";

import { isMobile } from "../../isMobile";

import formatComments from "../../helpers/formatDebateItem";

import { ComunityDetailContainer, CommentWrapper } from "../ComunityDetail/ComunityDetailStyled";
//import { CommentDetailContainer } from "./CommentDetailStyled";

export interface Props {
	background?: string;
}

const CommentDetail: React.FC<Props> = ({ children, background = "#FFF", ...props }) => {
	//const commentsToAdd = 3;
	const { debateId, commentId } = useParams<{ debateId: string; commentId: string }>();

	const [debate, setDebate] = useState<DebateItemType | undefined>(undefined);
	const [debateComments, setDebateComments] = useState<DebateCommentType[] | undefined>(undefined);
	const [commentsToShow, setCommentsToShow] = useState(15);
	//const [isLiked, setIsLiked] = useState(true);
	//const [likesCount, setLikesCount] = useState(0);

	// const handleViewMoreComments = () => {
	// 	setCommentsToShow(commentsToShow + commentsToAdd);
	// };

	const getDebateDetails = useCallback(async () => {
		const detailCommentService = new DetailCommentService(debateId, commentId);

		const response = await detailCommentService.list();

		//console.log("RESPONSE DETAIL ==> ", response);
		const normalizedDebate = {
			...response[0],
			title: response[0].titulo,
			comment: {
				author: {
					id: response[0].criado_por.id,
					name: response[0].criado_por.nome,
					isMonitor: response[0].is_mentor,
					isProfessor: response[0].is_professor,
					isTutor: response[0].is_tutor,
					imageUrl: response[0].criado_por.imagem_url,
				},
				text: response[0].descricao,
				date: response[0].data_publicacao,
				isNew: response[0].is_new,
			},
			studentsCount: 10,
			commentsCount: 10,
			isFavorite: false,
			isNotificationOn: false,
			likesCount: response[0].curtidas__count,
		};
		setDebate(normalizedDebate);

		const comments = response
			.map((comment: any) => {
				if (comment.id !== response[0].id) {
					return formatComments([comment]);
				} else {
					return;
				}
			})
			.filter((comment: any) => comment !== undefined)
			.flat();

		setDebateComments(comments);
	}, [debateId, commentId]);

	// const likeTopic = useCallback(
	// 	async (id) => {
	// 		const communityService = new ComunityService();
	// 		//const response =
	// 		await communityService.like(id);
	// 		//console.log("CURTIDA TÓPICO ==> ", response);

	// 		if (isLiked) {
	// 			setLikesCount(likesCount - 1);
	// 		} else {
	// 			setLikesCount(likesCount + 1);
	// 		}

	// 		setIsLiked(!isLiked);
	// 	},
	// 	[isLiked, likesCount, comunityId]
	// );
	useEffect(() => {
		window.scrollTo(0, 0);
		getDebateDetails();
		setCommentsToShow(15);
		//getComments();
		// eslint-disable-next-line
	}, [debateId, commentId]);

	// const pageTitle = () => (
	// 	<>
	// 		{debate && (
	// 			<Box display='flex' alignItems='center' justifyContent='space-between' padding='0 20px 0 20px'>
	// 				<Typography variant='title'>{debate.title}</Typography>
	// 			</Box>
	// 		)}
	// 	</>
	// );
	return (
		<PageContainer>
			{debate && (
				<>
					<GreyContainer>
						<InnerContentContainer>
							<ComunityDetailContainer>
								<Comment
									entityId={debate.id}
									showReactions={false}
									replySuccessCallBack={getDebateDetails}
									onCreateCommentAction={getDebateDetails}
									comment={debate.comment}
									margin='0 0 90px 0 '
									avatarSize='90px'
									type='comunity'
									link
								/>
								{/* <LikeWrapper>
									<LikeButton
										isLiked={isLiked}
										onClick={() => {
											likeTopic(debate.id);
										}}>
										{isLiked ? (
											<>
												<span>Você curtiu este tópico</span>
												<IoMdThumbsUp size={20} />
												<span>{likesCount > 0 && likesCount}</span>
											</>
										) : (
											<>
												<span>Curtir Tópico</span>
												<IoMdThumbsUp size={20} />
												<span>{likesCount > 0 && likesCount}</span>
											</>
										)}
									</LikeButton>
								</LikeWrapper> */}
								{debateComments &&
									debateComments.map((comment: any, index) => {
										if (commentsToShow > index) {
											return (
												<>
													<CommentWrapper key={index}>
														<Comment
															entityId={debate.id}
															parentId={comment.id}
															margin={isMobile.any() ? "0 0 80px 20px" : "0 0 80px 100px"}
															comment={comment.comment}
															type='comunity'
															replySuccessCallBack={getDebateDetails}
														/>
													</CommentWrapper>
													{comment.comments.length > 0 ? (
														<>
															{comment.comments.map((innerComment: any, index: number) => (
																<CommentWrapper key={index}>
																	<Comment
																		entityId={debate.id}
																		parentId={comment.id}
																		margin={isMobile.any() ? "0 0 80px 20px" : "0 0 80px 100px"}
																		comment={innerComment}
																		type='comunity'
																		replySuccessCallBack={getDebateDetails}
																	/>
																</CommentWrapper>
															))}
														</>
													) : (
														""
													)}
												</>
											);
										}
										return undefined;
									})}
							</ComunityDetailContainer>
						</InnerContentContainer>
					</GreyContainer>
				</>
			)}
		</PageContainer>
	);
};

export { CommentDetail };
