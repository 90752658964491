import React from "react";
import { Box } from "@material-ui/core"
import { CardWrapper, LoadingPlaceholder } from "../../../elements";
import { DebateHeader, DebateContent } from "./DebateItemStyled";


const DebateItemWrapper: React.FC = () => (
    <CardWrapper alignItems='normal' padding='0' margin='0 0 20px 0'>
        <DebateHeader><LoadingPlaceholder variant='text' linesNumber={1}  /></DebateHeader>
        <DebateContent>
        <Box margin="0" display="flex">
        <Box>
            <LoadingPlaceholder width="70px" height="70px" variant='roundedImage' />
        </Box>
        <Box width="100%" marginLeft="10px">
            <LoadingPlaceholder variant='text' linesNumber={3} />
        </Box>
        </Box>
        </DebateContent>
    </CardWrapper>
);

export { DebateItemWrapper as DebateItemLoading };
