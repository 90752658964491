export const badgesList = [
    {
        id: 1,
        name: 'Avançando 20%',
        slug: 'avancando20',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/avancando20.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/avancando20off.png',
        scope: 'course',
    },
    {
        id: 2,
        name: 'Avançando 50%',
        slug: 'avancando50',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/avancando50.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/avancando50off.png',
        scope: 'course',
    },
    {
        id: 3,
        name: 'Avançando 90%',
        slug: 'avancando90',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/avancando90.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/avancando90off.png',
        scope: 'course',
    },
    {
        id: 4,
        name: 'Comentários 1',
        slug: 'comentario1',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/comentario1.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/comentario1off.png',
        scope: 'global',
    },
    {
        id: 5,
        name: 'Comentários 2',
        slug: 'comentario2',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/comentario2.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/comentario2off.png',
        scope: 'global',
    },
    {
        id: 6,
        name: 'Comentários 3',
        slug: 'comentario3',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/comentario3.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/comentario3off.png',
        scope: 'global',
    },
    {
        id: 7,
        name: 'Concluiu Modelo 1',
        slug: 'concluiumod1',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/concluiumod1.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/concluiumod1off.png',
        scope: 'course',
    },
    {
        id: 8,
        name: 'Concluiu Modelo 2',
        slug: 'concluiumod2',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/concluiumod2.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/concluiumod2off.png',
        scope: 'course',
    },
    {
        id: 9,
        name: 'Like 1',
        slug: 'like1',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/like1.png',
        offImgUrl: '',
        scope: 'global',
    },
    {
        id: 10,
        name: 'Mitou',
        slug: 'mitou',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/mitou.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/mitouoff.png',
        scope: 'global',
    },
    {
        id: 11,
        name: 'Replies 1',
        slug: 'replies1',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/replies1.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/replies1off.png',
        scope: 'global',
    },
    {
        id: 12,
        name: 'Replies 2',
        slug: 'replies2',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/replies2.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/replies2off.png',
        scope: 'global',
    },
    {
        id: 13,
        name: 'Replies 3',
        slug: 'replies3',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/replies3.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/replies3off.png',
        scope: 'global',
    },
    {
        id: 14,
        name: 'Sugeriu Debate',
        slug: 'sugeriudebate',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/sugeriudebate.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/sugeriudebateoff.png',
        scope: 'global',
    },
    {
        id: 15,
        name: 'Terminou Módulo',
        slug: 'terminoumodulo',
        onImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/terminoumodulo.png',
        offImgUrl: 'https://gabrielbaptista.com.br/joinlearn-prototipo/badges/terminoumodulooff.png',
        scope: 'course',
    },
]

export const userBadgesList = [
    {acquired: true, ...badgesList[2]},
    {acquired: false, ...badgesList[5]},
    {acquired: false, ...badgesList[10]},
    {acquired: true, ...badgesList[7]},
    {acquired: true, ...badgesList[12]},
]

export const userBadgerListByCourse = [
    {acquired: true, ...badgesList[0]},
    {acquired: true, ...badgesList[1]},
    {acquired: false, ...badgesList[5]},
    {acquired: false, ...badgesList[10]},
    {acquired: false, ...badgesList[7]},
    {acquired: true, ...badgesList[12]},
    {acquired: true, ...badgesList[4]},
    {acquired: true, ...badgesList[14]}
]