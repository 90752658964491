import React from 'react'
import { CourseCard } from '../components/elements'
import { isExclusive } from './isExclusive'

export const CourseCardReturn = (curso: any) => {
    return (<CourseCard
        hasComments={curso.has_comment}
        course={{
            name: curso.nome,
            slug: curso.slug,
            imageUrl: curso.imagem,
            teachers: [curso.professor],
            description: curso.descricao,
            certificate: curso.certificado,
            status: curso.percentual_conclusao > 0 ? curso.curso_concluido : "notStarted",
            releasedLessons: curso.count_aulas_liberadas,
            progress: curso.percentual_conclusao,
            hasAccess: curso.tem_acesso,
            landingPageLink: curso.link_vendas,
            isOpenForSale: curso.inscricoes_abertas,
        }}
        plusExclusive={isExclusive(curso.grupos, ["Alunos", "NMAAlunos"], curso.nome)}
    />)
}