import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typography, PageContainer, InnerContentContainer, GreyContainer, ContentLoadingError, VideoChapters, FavoriteButton, PandaVideo } from "../../components";
import ReactPlayer from "react-player";
//import { motion, AnimatePresence } from "framer-motion";

import _ from "lodash";
import { ModuleCard, ModuleClassCard } from "../../components/elements";

import { Module, ModuleClass } from "../../types/course";

//import { MultimediaDetailsContainer } from './MultimediaDetailsStyled'
import Box from "@material-ui/core/Box";
import { CourseInfo } from "../../components/features/CourseDetail";

import { ModuleCardWrapperContainer } from "../../components/elements/Cards/CourseDetailCards/ModuleCardWrapper/ModuleCardWrapperStyled";

import { IoIosClose } from "react-icons/io";

import {
	CourseDetailContainer,
	ClassContentWrapper,
	VideoWrapper,
	ModulesListWrapper,
	ShowModulesListButton,
	CloseModulesListButton,
	CloseModulesListButtonWrapper,
	//LeiaMaisButtonWrapper,
} from "../CourseDetail/CourseDetailStyled";

import { isMobile } from "../../isMobile";

import MultimidiaDetailsService from "../../services/multimidiaDetails.service";
import MultimidiasVideosService from "../../services/multimidiaVideos.service";
import { useParams } from "react-router-dom";

export interface Props { }

const MultimediaDetails: React.FC<Props> = ({ children, ...props }) => {
	const { slug, id } = useParams<{ slug: string; id: string }>();

	const player = useRef<ReactPlayer>(null)

	const [serieModulo, setSerieModulo] = useState({} as Module);
	const [serieClasses, setSerieClasses] = useState<ModuleClass[]>([]);
	const [activeClass, setActiveClass] = useState({} as ModuleClass);
	const [pageTitle, setPageTitle] = useState("");
	const [hasScrolled, setHasScrolled] = useState(false);
	const [pl, setPl] = useState<boolean>(true)

	const [showModulesMobile, setShowModulesMobile] = useState(false);

	//const [truncateDescription, setTruncateDescription] = useState(true);
	// const [showTruncateButton, setshowTruncateButton] = useState(true);

	// const toggleTruncateDescription = useCallback(() => {
	// 	setTruncateDescription(!truncateDescription);
	// }, [truncateDescription]);

	const getSerieBySlug = useCallback(async () => {
		const multimidiaDetailsService = new MultimidiaDetailsService();

		const response = await multimidiaDetailsService.get_by_slug(slug);

		//console.log("EPISODES FROM MULTIMIDIA => ", response);

		const videos = response.map((episode: any) => {
			return {
				id: episode.id,
				name: episode.titulo,
				releaseDate: episode.data_publicacao,
				data_liberacao_ou_publicacao: episode.data_publicacao,
				materialsCount: 0,
				videoUrl: episode.video,
				description: episode.texto,
				materials: [],
				courseName: episode.categoria.nome,
				moduleName: episode.categoria.nome,
				timeline: episode.timeline,
				completed: false,
				isReleased: true, // Mexer depois pra permitir agendament
			};
		}, []);

		const modulo = {
			id: 1,
			name: "Conteúdo da Série",
			nome: "Conteúdo da Série",
			releaseDate: "none",
			moduleClasses: videos,
			aulas: videos,
			classesCount: videos.length,
			completedClasses: 0,
			progress: 0,
			released: true,
			students: [],
			studentsCount: 0,
		};

		setSerieClasses(videos.reverse());
		setSerieModulo({ ...modulo, hasComments: false, cor: "", hasSeparador: false, textoSeparador: "" }); //hardcoded pq multimidia não tem comentário
		if (id) {
			const active = videos.find((video: any) => video.id === parseInt(id));
			if (active) {
				await changeVideo(active);
			} else {
				await changeVideo(videos[0]);
			}
		} else {
			await changeVideo(videos[0]);
		}
		setPageTitle(response[0].categoria.nome);
	}, [id, slug]);

	const toggleModuleList = useCallback((isOpen) => {
		setShowModulesMobile(isOpen);
	}, []);

	const changeVideo = async (moduleClass: ModuleClass) => {
		setActiveClass(moduleClass);
		const multimidiaVideosService = new MultimidiasVideosService();

		const response = await multimidiaVideosService.mark_as_seen(moduleClass.id);
		//console.log("Vídeo Assistido Comp. => ", response);
	};
	const handleModuleListPosition = useCallback(
		_.debounce(() => {
			let colunaModulos = document.querySelector("#colunaModulos");
			if (!colunaModulos) return;
			if (hasScrolled && window.pageYOffset > 100) return;
			if (window.pageYOffset === 0) {
				colunaModulos.classList.remove("scroll");
				setHasScrolled(false);
			} else {
				colunaModulos.classList.add("scroll");
				setHasScrolled(true);
			}
		}, 20),
		[]
	);
	useEffect(() => {
		window.addEventListener("scroll", handleModuleListPosition);
		window.scrollTo(0, 0);
		getSerieBySlug();
		// eslint-disable-next-line
	}, []);

	const seekPlayer = (to: number) => {
		setPl(false)
		player.current?.seekTo(to)
		setPl(true)
	}

	return (
		<PageContainer hideBanner pageTitle={pageTitle} showBreadcrumb plusExclusive>
			{serieModulo ? (
				<>
					{isMobile.any() && <ShowModulesListButton onClick={() => toggleModuleList(true)}> Ver mais Episódios </ShowModulesListButton>}
					<GreyContainer>
						<InnerContentContainer>
							<CourseDetailContainer {...props}>
								<Box gridColumn='1' gridRow='1/3' paddingLeft={{ xs: "0px", md: "21px" }} width='100%' id='colunaModulos' className='colunaModulos multimediaModule'>
									{isMobile.any() ? (
										<>
											{showModulesMobile && (
												<>
													<ModulesListWrapper>
														<CloseModulesListButtonWrapper>
															<CloseModulesListButton
																onClick={() => {
																	toggleModuleList(false);
																}}>
																<IoIosClose size={30} color='#FFF' />
															</CloseModulesListButton>
														</CloseModulesListButtonWrapper>

														<ModuleCardWrapperContainer module={serieModulo} className='active'>
															<ModuleCard showArrow={false} isOpen module={serieModulo} />
															<div
																onClick={() => {
																	toggleModuleList(false);
																}}>
																{serieClasses?.map((moduleClass: ModuleClass, index: number) => (
																	<ModuleClassCard key={index} isActive={moduleClass.id === activeClass.id} onClick={() => changeVideo(moduleClass)} moduleClass={moduleClass} index={index + 1} />
																))}
															</div>
														</ModuleCardWrapperContainer>
													</ModulesListWrapper>
												</>
											)}
										</>
									) : (
										<ModulesListWrapper>
											<ModuleCardWrapperContainer module={serieModulo} className='active'>
												<ModuleCard showArrow={false} isOpen module={serieModulo} />

												{serieClasses?.map((moduleClass: ModuleClass, index: number) => (
													<ModuleClassCard isMultiedia isActive={moduleClass.id === activeClass.id} onClick={() => changeVideo(moduleClass)} moduleClass={moduleClass} index={index + 1} key={index} />
												))}
											</ModuleCardWrapperContainer>
										</ModulesListWrapper>
									)}
								</Box>
								<Box gridColumn='2' gridRow='1/3' paddingLeft={{ xs: "0px", md: "21px" }} width='100%'>
									<Box position='relative' display='flex' justifyContent='space-between' marginTop={{ xs: "15px;", md: "0" }} alignItems={{ xs: "flex-start", sm: "flex-start", md: "flex-end" }}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='uppertitle'>
												{serieModulo.name}
											</Typography>
											{activeClass.releaseDate && <Typography variant='subtitle'>Liberada em {activeClass.releaseDate}</Typography>}
											<Typography variant='title' display="flex">
												<FavoriteButton refID={activeClass.id} buttonStyle="simple" favType="VIDEO" />
												{activeClass.name}
											</Typography>
										</Box>
									</Box>

									<ClassContentWrapper>
										{activeClass.videoUrl &&
											<VideoWrapper>
												{activeClass.videoUrl.includes('pandavideo') ?
													<PandaVideo url={activeClass.videoUrl} />
													: <>
														<ReactPlayer
															controls //
															width='100%'
															height='100%'
															url={activeClass.videoUrl}
															pip
															ref={player}
															playing={pl}
														/></>}

												{/* <ReactPlayer playing={pl} ref={player} controls width='100%' height='100%' url={activeClass.videoUrl} /> */}
											</VideoWrapper>}
										<Box
											display='flex'
											flexDirection={{
												xs: "column",
												sm: "column",
												md: "column",
											}}
											padding='31px 20px 31px 20px'
										>
											<div>
												{activeClass.timeline && activeClass.timeline.length > 0 &&
													<VideoChapters timeline={activeClass.timeline} seekFunction={(to) => player.current?.seekTo(to)} />
												}
												<CourseInfo
													favBtn={<FavoriteButton refID={activeClass.id} buttonStyle="simple" favType="VIDEO" />}
													name={activeClass.name} description={activeClass.description} truncateDescription={false} />
											</div>
										</Box>
									</ClassContentWrapper>
								</Box>
							</CourseDetailContainer>
						</InnerContentContainer>
					</GreyContainer>
				</>
			) : (
				<>
					<GreyContainer>
						<InnerContentContainer>
							<ContentLoadingError refresh={getSerieBySlug} loadedResourceErrorMsg='os episódios' />
						</InnerContentContainer>
					</GreyContainer>
				</>
			)}
		</PageContainer>
	);
};

export { MultimediaDetails };
