import React, { useEffect } from "react";

import { NextPrevNavigationContainer, NextPrevButton } from "./NextPrevNavigationStyled";

import { useCourseDetail } from "../../../../context/courseDetail/courseDetailContext";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

export interface Props { }

const NextPrevNavigationWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const {
		//
		updateActiveModuleClass,
		updateActiveModule,
		nextLesson,
		nextModule,
		previousLesson,
		previousModule,
	} = useCourseDetail()!;

	const setNextOrPreviousLesson = (direction: "next" | "prev") => {
		// console.log("Navigation", { nextLesson, nextModule, previousLesson, previousModule });
		if (direction === "next") {
			if (nextLesson || nextModule) {
				if (nextLesson && nextLesson.isReleased) {
					updateActiveModuleClass(nextLesson);
				} else if (nextModule && nextModule.moduleClasses[0].isReleased) {
					updateActiveModule(nextModule);
					updateActiveModuleClass(nextModule.moduleClasses[0]);
				} else if (nextLesson && !nextModule?.moduleClasses[0].isReleased) return;
			}
		}
		if (direction === "prev") {
			//console.log("prev from button", previousLesson);
			if (previousLesson) {
				updateActiveModuleClass(previousLesson);
			} else if (previousModule) {
				updateActiveModule(previousModule);
				updateActiveModuleClass(previousModule.moduleClasses[previousModule.moduleClasses.length - 1]);
			}
		}
	};

	// useEffect(() => {
	// 	console.log("prevLesson", nextLesson);
	// 	console.log("prevModule", nextModule);
	// },[]);

	return (
		<NextPrevNavigationContainer {...props}>
			<NextPrevButton action='prev' disabled={!previousLesson && !previousModule} onClick={() => setNextOrPreviousLesson("prev")}>
				<FiChevronLeft size={20} />
				Aula Anterior
			</NextPrevButton>
			<NextPrevButton action='next' disabled={
				(nextLesson && !nextLesson?.isReleased) ||
				(!nextLesson && !nextModule) ||
				(!nextLesson && !nextModule?.moduleClasses[0]?.isReleased)
			} onClick={() => setNextOrPreviousLesson("next")}>
				<FiChevronRight size={20} />
				Próxima Aula
			</NextPrevButton>
		</NextPrevNavigationContainer>
	);
};

NextPrevNavigationWrapper.defaultProps = {};

export { NextPrevNavigationWrapper as NextPrevNavigation };
