import React, { useState, useRef, ReactNode, useCallback } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { Box } from "@material-ui/core";
import { FormHandles } from "@unform/core";
import { ModalBase } from "../../Producer_bkp/ModalBase";
import { RichTextInput, Input } from "../../..";

import { Button } from "../../../elements";

import { useCreateFromModal } from "../../../../hooks/useCreateFromModal";
import CourseClassDebatesService from "../../../../services/courseClassDebatesService.service";
import ArticlesDebatesService from "../../../../services/articlesDebatesService.service";
import CommentService from "../../../../services/comunityComments.service";
import { IoMdTrash } from "react-icons/io";

export interface Props {
	type: "lesson" | "comunity" | "article";
	commentId: number | string;
	entityId?: number;
	debateData?: any;
	successCallback?: () => void;
}

const DeleteDebateWrapper: React.FC<Props> = ({ type, entityId, commentId, debateData, successCallback, ...props }: Props) => {
	const [open, setOpen] = useState(false);
	const isUpdate = !!debateData;
	const formRef = useRef<FormHandles>(null);
	const schema = Yup.object().shape({
		descricao: Yup.string().required("Este campo é obrigatório."),
	});
	const handleSuccess = () => {
		setOpen(false);
		if (successCallback) {
			successCallback();
		}
	};

	const serviceType = useCallback(() => {
		switch (type) {
			case "lesson":
				return new CourseClassDebatesService(entityId);
				break;
			case "article":
				return new ArticlesDebatesService(entityId);
				break;
			case "comunity":
				return new CommentService(entityId);
		}
	}, [type]);

	const { loading } = useCreateFromModal({
		formRef,
		schema,
		isUpdate,
		item: debateData,
		service: serviceType(),
		successMessage: `Comentário Apagado"}`,
		handleSuccess,
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const deleteComment = async () => {
		await serviceType().deleteComment(commentId);
		handleSuccess();
	};
	return (
		<>
			<Box display='flex' justifyContent='flex-end' alignItems='center'>
				<Button variant='link' onClick={handleClickOpen} title='Apagar'>
					<IoMdTrash size={21} color='#013A7B' />
					&nbsp;&nbsp;
				</Button>
			</Box>
			<ModalBase loading={loading} open={open} title='Apagar Comentário?' handleClose={handleClose} saveAction={deleteComment}>
				<p>
					Tem certeza que deseja apagar este comentário? Esta ação é irreversível depois de confirmada.
					<br />
					Caso esteja certo disso clique em "Salvar" abaixo
				</p>
			</ModalBase>
		</>
	);
};

export { DeleteDebateWrapper as DeleteDebate };
