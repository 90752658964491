import React from "react";

interface IIconProps {
	color: string;
}

const SVG = ({ color }: IIconProps) => (
	<svg id='Componente_6_1' data-name='Componente 6 – 1' width='30' height='30' viewBox='0 0 34 34'>
		<defs>
			<linearGradient id='linear-gradient-series' x1='0.907' x2='0' y2='1' gradientUnits='objectBoundingBox'>
				<stop offset='0' stopColor='#ff2b8c' />
				<stop offset='1' stopColor='#f52f4e' />
			</linearGradient>
			<clipPath id='clip-path-series'>
				<rect id='Retângulo_81' data-name='Retângulo 81' width='34' height='34' fill='url(#linear-gradient-series)' />
			</clipPath>
		</defs>
		<g id='Grupo_90' data-name='Grupo 90' clipPath='url(#clip-path-series)'>
			<path
				id='Caminho_43'
				data-name='Caminho 43'
				d='M17,34A17,17,0,1,0,0,17,17.019,17.019,0,0,0,17,34M17,2.833A14.167,14.167,0,1,1,2.833,17,14.183,14.183,0,0,1,17,2.833'
				fill='url(#linear-gradient-series)'
			/>
			<path
				id='Caminho_44'
				data-name='Caminho 44'
				d='M137.606,125.877a2.863,2.863,0,0,0,1.242.285,2.746,2.746,0,0,0,1.72-.6l5.7-4.275a2.833,2.833,0,0,0,0-4.425l-.035-.027-5.631-4.22a2.833,2.833,0,0,0-4.6,2.213v8.494a2.812,2.812,0,0,0,1.606,2.554m1.263-11.022,5.63,4.22-5.665,4.247Z'
				transform='translate(-123.958 -102.076)'
				fill='url(#linear-gradient-series)'
			/>
		</g>
	</svg>

	// <svg
	//   width="47"
	//   height="47"
	//   viewBox="0 0 47 47"
	//   fill="none"
	//   xmlns="http://www.w3.org/2000/svg"
	// >
	//   <path
	//     fillRule="evenodd"
	//     clipRule="evenodd"
	//     d="M8 14.4483C6.89543 14.4483 6 15.3437 6 16.4483V34C6 35.1046 6.89543 36 8 36H35.8966C37.0011 36 37.8966 35.1046 37.8966 34V16.4483C37.8966 15.3437 37.0011 14.4483 35.8966 14.4483H8ZM21.9482 31.6897C25.519 31.6897 28.4137 28.7949 28.4137 25.2241C28.4137 21.6533 25.519 18.7586 21.9482 18.7586C18.3774 18.7586 15.4827 21.6533 15.4827 25.2241C15.4827 28.7949 18.3774 31.6897 21.9482 31.6897Z"
	//     fill="#013A7B"
	//   />
	//   <path
	//     fillRule="evenodd"
	//     clipRule="evenodd"
	//     d="M11.4482 11C10.3437 11 9.44824 11.8954 9.44824 13V13.5862H36.7585C37.8631 13.5862 38.7585 14.4816 38.7585 15.5862V32.5517H39.3448C40.4494 32.5517 41.3448 31.6563 41.3448 30.5517V13C41.3448 11.8954 40.4494 11 39.3448 11H11.4482Z"
	//     fill="#013A7B"
	//   />
	//   <path
	//     d="M24.9656 25.2241L19.7932 29.1035L19.7932 21.3448L24.9656 25.2241Z"
	//     fill="#013A7B"
	//   />
	// </svg>
);

export { SVG as MultimidiaSidebarIcon };
