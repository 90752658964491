import React from "react";
import { CardTitle, CardSubtitle, CardFooter, CardHeader, CardHeaderSmallTitle } from "../CardStyleBase";
import { ModuleClassCardContainer, CardIcon, CompletedClassTag } from "./ModuleClassCardStyled";
import { MaterialIcon } from "../../../Icons";
import { ModuleClass } from "../../../../../types/course";
import { IoIosLock, IoMdCheckmark } from "react-icons/io";
import { CommentsTag } from "../ModuleCard/ModuleCardStyled";

export interface Props extends React.HTMLAttributes<HTMLElement> {
	index: number;
	moduleClass: ModuleClass;
	isActive?: boolean | false;
	isMultiedia?: boolean;
	isQuiz?: boolean;
}

const ModuleClassCardWrapper: React.FC<Props> = ({ index, isQuiz, isMultiedia, moduleClass, isActive, ...props }: Props) => {
	return (
		<ModuleClassCardContainer {...props} index={index} moduleClass={moduleClass} isActive={isActive}>
			<CardHeader>
				<CardTitle>
					<CardHeaderSmallTitle>{isMultiedia ? `Episódio ${index}` : isQuiz ? "Questionário" : moduleClass.isOptional ? "Aula Opcional" : "Aula"}</CardHeaderSmallTitle>
					<CardTitle>
						<h2 className='title'>{moduleClass.name}</h2>
					</CardTitle>
					<CardSubtitle>
						{!isQuiz && (
							<>
								{moduleClass.isReleased ? "Liberada em " : "Liberação em "}
								{moduleClass.releaseDate}
								<br />
							</>
						)}
						{moduleClass.blockedByQuiz && "Conclua os questionários anteriores para acessar essa aula"}
					</CardSubtitle>
				</CardTitle>
			</CardHeader>
			<CardFooter>
				<CardSubtitle>
					{moduleClass.materialsCount > 0 ? (
						<div className='materials'>
							<MaterialIcon size='18px' backgroundColor='#FFF' color='#013A7B' />
							<span>{moduleClass.materialsCount} materiais para download</span>
						</div>
					) : (
						""
					)}

					{moduleClass.nonReadComments ? <>{moduleClass.nonReadComments > 0 ? <CommentsTag>{moduleClass.nonReadComments} Não Lidos</CommentsTag> : ""}</> : ""}
				</CardSubtitle>
			</CardFooter>
			{moduleClass.completed && (
				<CompletedClassTag>
					<span className='txt'>{isQuiz ? "Respondido" : "Aula Concluída"}</span>
					<span className='icon'>
						<IoMdCheckmark size={15} />
					</span>
				</CompletedClassTag>
			)}
			{(!moduleClass.isReleased || moduleClass.blockedByQuiz) && (
				<CardIcon>
					<IoIosLock size={20} />
				</CardIcon>
			)}
		</ModuleClassCardContainer>
	);
};

// ModuleClassCardWrapper.defaultProps = {
//   background: '#FFF'
// }

export { ModuleClassCardWrapper as ModuleClassCard };
