const referenceGroups: { [key: string]: string } = {
	"AbaNaEdInfantil": "ABA na Educação Infantil",
	"ABAnaPratica": "ABA na Prática",
	"AdaptMatEsc": "Adaptando Materiais Escolares",
	"AdolescentesEAdultos": "Habilidades Sociais para Adolescentes e Adultos com TEA",
	"Alfabetizacao": "Curso de Alfabetização no Autismo",
	"AvaliacaoNeuropsicologica": "Avaliação Neuropsicológica no TEA",
	"CapacitacaoInclusao": "Capacitação Avançada em Inclusão Escolar de Alunos com TEA",
	"ComAlt2021": "Curso Avançado de Comunicação Alternativa",
	"ComunicacaoAlternativa": "Curso Avançado de Comunicação Alternativa",
	"ComunicacaoAlternativa_2020_2": "Curso Avançado de Comunicação Alternativa",
	"CursoABA": "Curso em Terapia ABA para Pais e Aplicadores",
	"CursoABA2021": "Curso em Terapia ABA para Pais e Aplicadores",
	"CursoABA2022": "Curso em Terapia ABA para Pais e Aplicadores",
	"DesenvolvimentoMotor": "Práticas para o Desenvolvimento Motor de Pessoas com Autismo",
	"EdFisica": "Educação Física Adaptada para Pessoas com Autismo",
	"EstrategiasMusicais": "Estratégias Musicais para Inclusão Escolar",
	"Formacao": "Formação Avançada em Terapia Comportamental",
	"Formacao2018": "Formação Avançada em Terapia Comportamental",
	"Formacao2020": "Formação Avançada em Terapia ABA",
	"Formacao2021": "Formação Avançada em ABA",
	"FuncoesExecutivas": "Curso de Estimulação das Funções Executivas na Prática",
	"HabilidadesBasicas": "Treino de Habilidades Básicas para Pessoas com Autismo",
	"Imersao2019": "Imersão em Terapia Comportamental no Autismo 2019",
	"Inclusao2019": "Semana da Inclusão Escolar no TEA 2019",
	"Masterclass": "Masterclass em Inclusão Escolar no TEA",
	"Masterclass2020": "Masterclass em Inclusão Escolar no TEA",
	"Matematica": "Curso de Ensino de Matemática para Alunos com Autismo",
	"MiniCursoAplicadoresABA": "Minicurso para Aplicadores",
	"NMA30Dias": "Assinatura",
	"NMA7Dias": "Assinatura",
	"NMAAlunos": "Assinatura",
	"portage-t1": "Capacitação em Inventário Portage Operacionalizado",
	"PosGraduacaoT1": "Pós Graduação em Autismo",
	"PosGraduacaoT2": "Pós Graduação em Autismo",
	"RecursosMusicais": "Recursos Musicais para Prática Terapêutica de pessoas com Autismo",
	"TEAnasIgrejas": "Curso de Inclusão Missionária de Pessoas com TEA em Igrejas e Comunidades de Fé",
	"vbmapp": "Curso Avançado em Protocolo VB-MAPP",
	"vbmapp2020": "Curso Avançado em Protocolo VB-MAPP",
	"vbmapp2021": "Curso Avançado em Protocolo VB-MAPP",
	"VBMAPP-T1-2022": "Curso Avançado em Protocolo VB-MAPP",
	"vbmapp-t2-2021": "Curso Avançado em Protocolo VB-MAPP",
};

export const groupToCourseName = (groups: string[]): string => {
	const courses: string[] = [];

	groups.forEach((group) => {
		// console.log(group);
		if (referenceGroups[group]) {
			courses.push(referenceGroups[group]);
		} else {
			courses.push(group);
		}
	});

	return courses.join(", ");
};
