import styled from "styled-components";
import { Box } from "@material-ui/core";

export const TeacherCardContainer = styled.div`
	display: flex;
`;

export const TeacherCardHeader = styled(Box)`
	display: flex;
	padding: 5px;
	width: 100%;
	background-color: ${(props) => props.theme.color.primaryLight};
	border-bottom: solid 6px ${(props) => props.theme.color.primary};
	align-items: center;
	h1 {
		padding-left: 12px;
		font-weight: bold;
		font-size: 1.1rem;
	}
`;

export const TeacherCardContent = styled(Box)`
	display: flex;
	padding: 20px;
	width: 100%;
	align-items: center;
`;

export const TeacherCardFooter = styled(Box)`
	display: flex;
	padding: 5px 10px;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	border-top: solid 1px #ddd;
`;
