import styled, { css, keyframes } from 'styled-components';

interface Props{
  width?: string
  height?: string
}

const LoadingPlaceholderContainer = styled.div<Props>`
  display: flex;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
const pulse = keyframes`
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
`;


const variantTemplate = css`
  animation: ${pulse} 1s infinite ease-in-out;
`;

const Placeholder = {
  text: styled.div`
    .text-input__loading--line{
      height: 10px;
      margin: 10px;
      ${variantTemplate}
      &:nth-child(1) {
        width: 150px;
      }
      &:nth-child(5) {
        width: 150px;
      }
      &:nth-child(9) {
        width: 150px;
      }
      &:nth-child(2) {
        width: 250px;
      }
      &:nth-child(6) {
        width: 250px;
      }
      &:nth-child(10) {
        width: 250px;
      }
      &:nth-child(3) {
        width: 50px;
      }
      &:nth-child(7) {
        width: 50px;
      }
      &:nth-child(4) {
        width: 100px;
      }
      &:nth-child(8) {
        width: 100px;
      }
    }
  `,
  onelinetext: styled.div`
    .text-input__loading--line{
      height: 10px;
      margin: 10px;
      width: 200px;
      ${variantTemplate}
    }
  `,
  image: styled.div`
    ${variantTemplate}
    width: 100%;
    height: 100%;
  `,
  roundedImage: styled.div`
    ${variantTemplate}
    width: 100%;
    height: 100%;
    border-radius: 50%;
  `,

};


export { LoadingPlaceholderContainer, Placeholder };
