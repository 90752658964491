import styled from "styled-components";
import { Props } from "./index";
import { darken } from "polished";

const ArticlesContainer = styled.div<Props>`
	.clearFilter {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 30px;
		span {
			text-decoration: none;
			white-space: nowrap;
			border-radius: 100px;
			font-weight: 400;
			height: 30px;
			padding: 0 20px;
			line-height: 30px;
			font-size: 0.75rem;
			color: #222b37;
			cursor: pointer;
			box-sizing: border-box;
			background-color: #f2f2f2;
			border: 1px solid #e0e0e0;
			transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

			margin: 5px 5px 0 5px;

			&:focus {
				border: 1px solid #e0e0e0;
			}
			&:hover {
				background-color: ${darken(0.2, "#E0E0E0")};
			}
		}
	}
`;
const ViewMoreWrapper = styled.div<Props>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 120px;
`;

const CategoriesContainer = styled.div<Props>`
	display: flex;
	width: 100%;
	padding: 10px 10px 10px 20px;
	background: #fff;
	border-bottom: 1px solid #f2f2f2;
	overflow: auto;
	scrollbar-width: thin;
	scrollbar-color: #eee #fff;

	&::-webkit-scrollbar {
		//display: none;
		height: 10px;
		padding: 1px;
	}
	&::-webkit-scrollbar-thumb {
		background: #eee;
		opacity: 0.4;
		border-radius: 5px;
		border: 3px solid #fff;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	span {
		text-decoration: none;
		white-space: nowrap;
		border-radius: 100px;
		font-weight: 400;
		height: 35px;
		padding: 0 20px;
		line-height: 30px;
		font-size: 0.75rem;
		color: #222b37;
		cursor: pointer;
		box-sizing: border-box;
		background-color: #f2f2f2;
		border: 1px solid #e0e0e0;
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

		margin: 5px 5px 0 5px;

		&:first-child {
			margin-left: 0;
		}

		&:focus {
			border: 1px solid #e0e0e0;
		}
		&:hover {
			background-color: ${darken(0.2, "#E0E0E0")};
		}
		&.active {
			background-color: ${darken(0.2, "#E0E0E0")};
		}
	}
`;

const FilterTitle = styled.div<Props>`
	background-color: #fff;
	font-size: 0.7rem;
	opacity: 0.7;
	text-transform: uppercase;
	padding: 10px 20px 0;
	font-weight: 600;
	border-top: 1px solid #f2f2f2;
`;

export { ArticlesContainer, ViewMoreWrapper, CategoriesContainer, FilterTitle };
