import styled from "styled-components";

const PlusExclusiveBarContainer = styled.div`
	text-align: center;
	padding: 8px;
	color: #f2c94c;
	background: ${(props) => props.theme.color.primary};
	font-weight: 600;
	border-top: 2px solid #f2c94c;
`;
export { PlusExclusiveBarContainer };
