import { TFavType } from "../types/favorites";
import api from "./api";
import BaseService from "./base.service";
import { headers } from "./config";

export default class ClassRatingService extends BaseService {
	constructor() {
		super("cursos/aula/star/");
	}

	async rate(rating: number, classID: string | number, comment: string) {
		try {
			const data = {
				"nota": rating,
				"comentario": comment,
				"object_id": classID,
			};
			console.log(data);
			const response = await api.post(`${this.resource}`, data);
			return response.data;
		} catch (error) {
			console.error("Erro ao avaliar", error);
		}
	}
	async status(classID: string | number) {
		try {
			const response = await api.get(`${this.resource}${classID}`);
			return response.data;
		} catch (error) {
			console.error("Erro ao listar status do rating", error);
		}
	}
}
