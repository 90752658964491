import React from "react";
import { IoMdLock, IoIosArrowUp } from "react-icons/io";

// import {
// 	// /StudentsAvatarGroup, CircularProgressIndicator,
// 	// IconWrapper,
// } from "../../..";
import { Module } from "../../../../../types/course";
import {
	CardTitle,
	CardSubtitle,
	CardFooter,
	CardHeader,
	//CardHeaderHelpText
} from "../CardStyleBase";

import { ModuleCardContainer, CommentsTag } from "./ModuleCardStyled";

export interface Props extends React.HTMLAttributes<HTMLElement> {
	module: Module;
	action?: any;
	isOpen?: boolean;
	showArrow?: boolean;
}

// const BlockedIcon: React.FC = () => <IconWrapper backgroundColor='#FFF' size='32px' rounded icon={<IoMdLock size='60%' color='#000' />} />;

const ModuleCardWrapper: React.FC<Props> = ({ module, showArrow, action, isOpen, ...props }: Props) => (
	<ModuleCardContainer
		onClick={() => {
			action(module);
		}}
		{...props}
		module={module}
		// action={action ? action : "no-action"}
		isOpen={isOpen}
		showArrow={showArrow}>
		{module && (
			<>
				<CardHeader>
					<CardTitle>
						<h2 className='title'>{module.name}</h2>
						{module.releaseDate ? <CardSubtitle>Liberada em {module.releaseDate}</CardSubtitle> : <CardSubtitle>A ser liberado</CardSubtitle>}
						{module.hasComments && <CommentsTag>Novos Comentários</CommentsTag>}
					</CardTitle>
					{/* <CardHeaderHelpText>
            <div className="headerHelper">
              Aula {module.completedClasses} de {module.classesCount}
            </div>
            <div className="iconWrapper">
              <CircularProgressIndicator
                size={35}
                percentage={module.progress}
              />
            </div>
          </CardHeaderHelpText> */}
					{/* {module.released ? (
            <CardHeaderHelpText>
              <div className="headerHelper">
                Aula {module.completedClasses} de {module.classesCount}
              </div>
              <div className="iconWrapper">
                <CircularProgressIndicator
                  size={35}
                  percentage={module.progress}
                />
              </div>
            </CardHeaderHelpText>
          ) : (
            <div className="iconWrapper">
              <BlockedIcon />
            </div>
          )} */}
				</CardHeader>
				<CardFooter>
					{isOpen && (
						<>
							{showArrow && (
								<div className='arrowWrapper'>
									<IoIosArrowUp size='32px' />
								</div>
							)}
						</>
					)}
					{/* {module.students.length > 0
              && <StudentsAvatarGroup students={module.students} studentsCount={module.studentsCount} />} */}
					{/* {module.released && isOpen && (
            <div className="arrowWrapper">
              <IoIosArrowUp size="32px" />
            </div>
          )} */}
				</CardFooter>
			</>
		)}
	</ModuleCardContainer>
);

ModuleCardWrapper.defaultProps = {
	isOpen: false,
};

export { ModuleCardWrapper as ModuleCard };
