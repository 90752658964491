import React from 'react';
import { DiscountSealContainer } from './DiscountSealStyled'

export interface Props {
  background?: string;
  primaryColor?: string;
  secondaryColor?: string;
  size?: number;
  percentage: number;
}

// fill="#DD0000"

const DiscountSealWrapper: React.FC<Props> = ({
  ...props
}: Props) => {
  return (
    <DiscountSealContainer {...props}>
      <svg width={props.size ? props.size : 35} height={props.size ? props.size : 35} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.912 3.91081L27.6613 0.223083L30.7981 6.29227L37.6182 5.97238L37.3027 12.7969L43.3675 15.9337L39.6842 21.683L43.3675 27.4323L37.3027 30.569L37.6182 37.3891L30.7981 37.0737L27.6613 43.1384L21.912 39.4551L16.1627 43.1384L13.0259 37.0737L6.20144 37.3891L6.52134 30.569L0.452148 27.4323L4.13987 21.683L0.452148 15.9337L6.52134 12.7969L6.20144 5.97238L13.0259 6.29227L16.1627 0.223083L21.912 3.91081Z"/>
      </svg>
      <span>
        <b>{props.percentage}</b><em>%</em>
        <small>off</small>
      </span>
    </DiscountSealContainer>
  );
};

export { DiscountSealWrapper  as DiscountSeal };
