import React, { ReactNode } from 'react';
import styled from 'styled-components';

export interface Props {
    padding?: string;
    margin?: string;
    backgroundColor?: string;
    justifyContent?: string;
    children?: ReactNode;
}

const CardHeaderContainer = styled.div<Props>`
    padding: ${(props) => props.padding};
    margin: ${(props) => props.margin};
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: ${(props) => props.justifyContent};
`;

const CardHeader: React.FC<Props> = ({
  children,
  ...props
}: Props) => (
  <CardHeaderContainer {...props}>
    {children}
  </CardHeaderContainer>
);

CardHeader.defaultProps = {
  padding: '10px',
  margin: '0',
  backgroundColor: '#F5F5F5',
  justifyContent: 'flex-start',
};

export { CardHeader };
