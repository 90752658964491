import React from "react";
import loadingGif from "../../../assets/images/loading.gif";
import placeholder from "../../../assets/images/article-placeholder.jpg";
import { SquareCardContainer, CardImage, CardBottom, CardTitle, CardMeta, CardDescription, CardTagsWrapper, Tag } from "./SquareCardStyled";
import { FavoriteButton } from "../../FavoriteButton";

export interface Props {
	to: string;
	title: string;
	imageurl?: string;
	tags?: string[];
	meta?: string;
	description?: string;
	categories?: any[];
}

const SquareCardWrapper: React.FC<Props> = ({ ...props }: Props) => {
	return (
		<SquareCardContainer {...props}>
			<CardImage>
				<img src={props.imageurl ? props.imageurl : "https://placeimg.com/640/480/nature"} />
				<img className='loadingGif' src={placeholder} alt='loading' />
			</CardImage>
			<CardBottom>
				<CardTitle>{props.title}</CardTitle>
				{props.meta && <CardMeta>{props.meta}</CardMeta>}

				{props.description && <CardDescription dangerouslySetInnerHTML={{ __html: props.description }}></CardDescription>}
				{props.tags?.length ? (
					<>
						<small>Tags</small>
						<CardTagsWrapper>
							{props.tags.map((tag, index) => (
								<Tag to={tag} key={index}>
									{tag}
								</Tag>
							))}
						</CardTagsWrapper>
					</>
				) : (
					""
				)}
				{props.categories?.length ? (
					<>
						<small>Categorias</small>
						<CardTagsWrapper>
							{props.categories.map((category, index) => (
								<Tag to={`/artigos/categoria/${category.slug}`} key={index}>
									{category.descricao}
								</Tag>
							))}
						</CardTagsWrapper>
					</>
				) : (
					""
				)}
			</CardBottom>
		</SquareCardContainer>
	);
};

SquareCardWrapper.defaultProps = {};

export { SquareCardWrapper as SquareCard };
