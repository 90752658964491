import React, { ReactNode } from 'react';
import {
  FloatingCardWrapper, FloatingCardContainer, FloatingCardFooter, FloatingCardHeader,
} from './FloatingCardStyled';

export interface Props {
  children?: ReactNode
  right?: string
  left?: string
  top?: string
  bottom?: string
}

const FloatingCard: React.FC<Props> = ({
  children,
  top,
  left,
  bottom,
  right,
  ...props
}: Props) => (
  <FloatingCardWrapper top={top} left={left} bottom={bottom} right={right}>
    <FloatingCardContainer {...props}>
      {children}
    </FloatingCardContainer>
  </FloatingCardWrapper>
);

FloatingCardWrapper.defaultProps = {
  right: '0px',
  left: '0px',
  top: '0px',
  bottom: '0px',
};

export { FloatingCard };
export { FloatingCardFooter };
export { FloatingCardHeader };
