import React, { useState, useRef, ReactNode, useCallback } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { Box } from "@material-ui/core";
import { FormHandles } from "@unform/core";
import { ModalBase } from "../../Producer_bkp/ModalBase";
import { RichTextInput, Input } from "../../..";

import { Button } from "../../../elements";
import { useCreateFromModal } from "../../../../hooks/useCreateFromModal";
import CourseClassDebatesService from "../../../../services/courseClassDebatesService.service";
import ArticlesDebatesService from "../../../../services/articlesDebatesService.service";
import CommentService from "../../../../services/comunityComments.service";

import { HiOutlineReply } from "react-icons/hi";

import { ChonkyButton } from "./ReplyDebateStyled";
export interface Props {
	parentId?: number | string;
	commentId?: number | string;
	type: "lesson" | "comunity" | "article";
	iconElement?: ReactNode;
	entityId?: number;
	debateData?: any;
	comment?: any;
	successCallback?: (data?: any) => void;
	isChonky?: boolean;
	actionCallback?: () => void;
}

const ReplyDebateWrapper: React.FC<Props> = ({ parentId, type, iconElement, commentId, entityId, debateData, isChonky, successCallback, actionCallback, ...props }: Props) => {
	const [open, setOpen] = useState(false);
	const isUpdate = !!debateData;
	const formRef = useRef<FormHandles>(null);
	const schema = Yup.object().shape({
		descricao: Yup.string().required("Este campo é obrigatório."),
	});
	const handleSuccess = (data?: any) => {
		setOpen(false);
		if (successCallback) {
			successCallback(data);
		}
	};

	const serviceType = useCallback(() => {
		switch (type) {
			case "lesson":
				return new CourseClassDebatesService(entityId);
				break;
			case "article":
				return new ArticlesDebatesService(entityId);
				break;
			case "comunity":
				return new CommentService(entityId);
		}
	}, [type]);

	const { handleSubmit, loading } = useCreateFromModal({
		formRef,
		schema,
		isUpdate,
		item: debateData,
		service: serviceType(),
		successMessage: `Comentário ${isUpdate ? "Alterado" : "Criado!"}`,
		handleSuccess,
	});

	const handleClickOpen = () => {
		// if (props.comment) {
		// 	console.log("DEBATE DATA => ", props.comment);
		// }
		// console.log("action?");
		if (actionCallback) {
			actionCallback();
		}
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const submitForm = () => {
		formRef?.current?.submitForm();
	};
	return (
		<>
			{isChonky ? (
				<ChonkyButton onClick={handleClickOpen} title='Responder'>
					<HiOutlineReply size={20} /> Responder
				</ChonkyButton>
			) : (
				<Box display='flex' justifyContent='flex-end' alignItems='center'>
					{iconElement ? (
						<Button variant='link' onClick={handleClickOpen} title='Editar'>
							{iconElement}
						</Button>
					) : (
						<Button variant='link' onClick={handleClickOpen} title='Responder'>
							<HiOutlineReply size={20} color='#013A7B' />
						</Button>
					)}
				</Box>
			)}
			<ModalBase loading={loading} open={open} title={isUpdate ? "Editar Comentário" : "Novo Comentário"} handleClose={handleClose} saveAction={submitForm}>
				<Form
					ref={formRef}
					onSubmit={handleSubmit}
					initialData={{
						id: commentId,
						descricao: debateData?.text,
						comentario: commentId,
						// comentario: parentId,
					}}>
					<Box display='grid' gridTemplateColumns='1fr' gridTemplateRows='2fr' gridRowGap='20px' gridColumnGap='20px'>
						<RichTextInput name='descricao' label='Descrição' />
						<Box display='none'>
							<Input name='comentario' />
							<Input name='id' />
						</Box>
					</Box>
				</Form>
			</ModalBase>
		</>
	);
};

export { ReplyDebateWrapper as ReplyDebate };
