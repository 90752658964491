import { lighten } from "polished";
import styled from "styled-components";
import media from "../../../../styles/media";

const MainCoursesHomeSliderContainer = styled.div`
	display: flex;
	width: 100%;
	overflow: hidden;
`;
const FeaturedCourse = styled.div`
	position: relative;
	height: 250px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: 5px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	a {
		display: inline-block;
		position: absolute;
		left: 50%;
		bottom: 30px;
		transform: translateX(-50%);
		background: ${(props) => props.theme.color.primary};
		color: #fff;
		text-decoration: none;
		padding: 5px 40px;
		border-radius: 50px;
		transition: 0.3s;
		&:hover {
			background: ${(props) => lighten(0.1, props.theme.color.primary)};
		}
		${media.lessThan("medium")`
			white-space: nowrap;	
		`}
	}
`;
export { MainCoursesHomeSliderContainer, FeaturedCourse };
