import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import { Typography, PageContainer, GreyContainer, InnerContentContainer } from "../../components";
import { ContentLoadingError } from "../../components/ContentLoadingError";
import {
	CourseCardLoading,
	CourseCard,
	//Alert
} from "../../components/elements";
import { HubPosGraduacaoContainer } from "./HubPosGraduacaoStyled";

import CourseService from "../../services/course.service";

const HubPosGraduacao: React.FC = ({ ...props }) => {
	const [courses, setCourses] = useState([]);
	const [loadingCourses, setLoadingCourses] = useState(true);
	const [error, setError] = useState(false);
	// const { isPlus } = useUserContext();
	const loadCursos = useCallback(async () => {
		try {
			const courseService = new CourseService();
			const response = await courseService.list();

			const pos = response.filter((categoria: any) => {
				return categoria.nome === "Pós Graduação";
			});

			setCourses(pos);
			setLoadingCourses(false);
		} catch (error) {
			setError(true);
			setLoadingCourses(false);
		}
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);

		loadCursos();
	}, []);

	return (
		<PageContainer showBreadcrumb={false} pageTitle='Pós-Graduações' showSearch={false}>
			<GreyContainer>
				<InnerContentContainer>
					<HubPosGraduacaoContainer>
						{loadingCourses && (
							<>
								<Box
									width='100%'
									display='grid'
									gridTemplateColumns={{
										xs: "100%",
										sm: "100%",
										md: "24% 24% 24% 24%",
									}}
									gridRowGap='15px'
									gridColumnGap='1%'>
									<CourseCardLoading /> <CourseCardLoading />
									<CourseCardLoading /> <CourseCardLoading />
								</Box>
							</>
						)}

						{error ? (
							<>
								<ContentLoadingError
									refresh={() => {
										loadCursos();
									}}
									loadedResourceErrorMsg='a lista de cursos disponíveis'
								/>
							</>
						) : (
							<>
								{courses?.length > 0 &&
									courses.map((result: any) => (
										<React.Fragment key={result.slug}>
											{result.cursos.length > 0 && (
												<>
													<div key={result.slug} className='resultDiv'>
														<Typography margin='0 0 30px 0' variant='title' color='#014491'>
															{result.nome}
															<span className='coursesCounter'>{result.cursos.length}</span>
														</Typography>
														<Box
															width='100%'
															display='grid'
															gridTemplateColumns={{
																xs: "100%",
																sm: "100%",
																md: "24% 24% 24% 24%",
																// xl: '19% 19% 19% 19% 19%',
															}}
															gridRowGap='15px'
															gridColumnGap='1%'>
															{result.cursos.map((curso: any) => (
																<Link key={curso.id} className='courseLink' to={`/cursos/${curso.slug}`}>
																	<CourseCard
																		hasComments={curso.has_comment}
																		course={{
																			name: curso.nome,
																			slug: curso.slug,
																			imageUrl: curso.imagem,
																			teachers: [curso.professor],
																			description: curso.descricao,
																			certificate: curso.certificado,
																			status: curso.percentual_conclusao > 0 ? curso.curso_concluido : "notStarted",
																			releasedLessons: curso.count_aulas_liberadas,
																			progress: curso.percentual_conclusao,
																		}}
																		// plusExclusive={isExclusive(curso.grupos, ["Alunos", "NMAAlunos"], curso.nome)}
																	/>
																</Link>
															))}
														</Box>
													</div>
												</>
											)}
										</React.Fragment>
									))}
							</>
						)}
					</HubPosGraduacaoContainer>
				</InnerContentContainer>
			</GreyContainer>
		</PageContainer>
	);
};

export { HubPosGraduacao };
