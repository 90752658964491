import React from "react";
import { PlusExclusiveBarContainer } from "./PlusExclusiveBarStyled";

export interface Props {}

const PlusExclusiveBarWrapper: React.FC<Props> = ({ ...props }: Props) => {
	return <PlusExclusiveBarContainer {...props}>Esta seção é exclusiva para Alunos Plus, como você!</PlusExclusiveBarContainer>;
};

PlusExclusiveBarWrapper.defaultProps = {};

export { PlusExclusiveBarWrapper as PlusExclusiveBar };
