import styled from "styled-components";

const CheckboxInputContainer = styled.div`
	border: 1px solid #f2f2f2;
	border-radius: 6px;
	background: #fff;
	padding: 20px;
	position: relative;
	cursor: pointer;
	height: 100%;

	.checkbox-help-text {
		font-size: 12px;
		line-height: 16px;
		color: #828282;
		margin-top: 20px;
	}
	.checkbox-label {
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
		color: #333333;
	}
`;

const CheckboxInputWrapper = styled.div`
	position: relative;
	.checkbox-input {
		position: absolute;
		right: 20px;
		top: 20px;
		z-index: 2;
	}
	input[type="checkbox"]:checked + label {
		${CheckboxInputContainer} {
			border: 1px solid ${(props) => props.theme.color.primary};
		}
	}
`;

const MultipleRelatedCheckboxInputWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	label {
		cursor: pointer;
		width: 100%;
		margin-left: 10px;
	}
`;

export { CheckboxInputContainer, CheckboxInputWrapper, MultipleRelatedCheckboxInputWrapper };
