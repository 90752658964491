import styled from "styled-components";
import { Props } from "./index";
import media from "../../../styles/media";

const CookieModalContainer = styled.div<Props>`
	display: flex;
	background-color: ${(props) => (props.size === "L" ? "red" : "white")};
	.overlay {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.3);
		z-index: 99998;
		width: 100%;
		height: 100%;
	}
	.modalBody {
		display: none;
		background-color: #fff;
		background-image: ${(props) => (props.imageBackgroundURL ? `url(${props.imageBackgroundURL})` : "")};
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		padding: 25px;
		border-radius: 20px;
		text-align: center;
		width: 90%;
		max-width: ${(props) => (props.size === "L" ? "700px" : "500px")};
		max-height: 90vh;
		overflow: auto;

		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999999;

		h1 {
			margin-bottom: 25px;
			font-size: 1.25em;
			color: ${(props) => props.theme.color.primary};
		}

		p {
			margin-bottom: 16px !important;
		}

		a.cta {
			display: block;
			background: #4bb543;
			color: #fff;
			font-weight: bold;
			padding: 10px;
			border-radius: 10px;
			text-decoration: none;
			text-transform: uppercase;
			transition: 0.3s;
			font-size: 1.125rem;
			&:hover {
				opacity: 0.7;
			}
		}

		.btnClose {
			position: absolute;
			top: 15px;
			right: 15px;
			cursor: pointer;
			transition: 0.3s;
			&:hover {
				filter: invert(0.5);
			}
		}
	}

	.overlay.show,
	.modalBody.show {
		display: block;
	}

	.modalPos {
		h1 {
			width: 90%;
			max-width: 300px;
			margin: 30px auto 12px;
			img {
				width: 100%;
			}
		}
		.academiaFaeesp {
			width: 60%;
			max-width: 200px;
			margin: 0 auto 45px;
			img {
				width: 100%;
			}
		}
		h2 {
			color: ${(props) => props.theme.color.primary};
			font-weight: 400;
			font-size: 1.25rem;
			width: 94%;
			margin: 0 auto 25px;
			text-transform: uppercase;
			b {
				font-weight: 700;
			}
			${media.lessThan("medium")`
				width: 100%;
				font-size: 1.125rem;
			`}
		}
		p {
			width: 90%;
			margin: 0 auto;
			color: ${(props) => props.theme.color.primary};
			${media.lessThan("medium")`
				width: 100%;
				font-size: .9rem;
			`}
		}
	}
`;
export { CookieModalContainer };
