import React from "react";

interface IIconProps {
  color: string;
}

const SVG = ({ color }: IIconProps) => (
  <svg
    id="Layer_1"
    width="34px"
    fill={color}
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 82 69.96"
  >
    <path
      className="cls-1"
      d="M31.39,48.61H46a1.5,1.5,0,0,0,0-3H31.39a1.5,1.5,0,0,0,0,3"
      transform="translate(-9.39 -15.11)"
    />
    <path
      className="cls-1"
      d="M31.39,38.61h38a1.5,1.5,0,0,0,0-3h-38a1.5,1.5,0,0,0,0,3"
      transform="translate(-9.39 -15.11)"
    />
    <path
      className="cls-1"
      d="M9.39,15.11v58H51l2.31-6.47a15.79,15.79,0,0,1-1.9-3.53h-32v-38h62v38h-1a15.51,15.51,0,0,1-1.86,3.48l2.33,6.52H91.39v-58Z"
      transform="translate(-9.39 -15.11)"
    />
    <path
      className="cls-1"
      d="M75.13,66a12.5,12.5,0,1,0-18.47,0L51.06,81.7l6.73-4,2.69,7.34,5.34-15H66l5.35,15L74,77.73l6.72,4Zm-9.26-2.87a5.5,5.5,0,1,1,5.5-5.5A5.5,5.5,0,0,1,65.87,63.11Z"
      transform="translate(-9.39 -15.11)"
    />
  </svg>
);

export { SVG as IssueCertificateIcon };
