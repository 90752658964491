import styled from "styled-components";
import { Box } from "@material-ui/core";

export const LessonCardContainer = styled.div`
	display: flex;
	position: relative;
	height: 100%;
	margin-bottom: 40px;
`;

export const LessonCardHeader = styled(Box)`
	display: flex;
	padding: 20px 16px;
	width: 100%;
	background-color: ${(props) => props.theme.color.primaryLight};
	border-bottom: solid 6px ${(props) => props.theme.color.primary};
	align-items: center;
	height: 90px;
	h1 {
		padding-left: 12px;
		font-weight: bold;
		font-size: 1.1rem;
	}
`;

export const LessonCardContent = styled(Box)`
	display: flex;
	padding: 20px;
	width: 100%;
	align-items: center;
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
	li {
		margin-left: 20px;
	}
`;

export const LessonCardContentHeader = styled(Box)`
	display: flex;
	padding: 10px 20px;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	border-bottom: solid 1px #ddd;
`;

export const LessonCardFooter = styled(Box)`
	display: flex;
	position: absolute;
	padding: 10px 10px 10px 20px;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	border-top: solid 1px #ddd;
	bottom: 0;
`;
