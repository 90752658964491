import React, { ReactNode, useEffect, useState } from "react";

import { Header } from "../..";
import { Sidebar } from "../Sidebar";
import { SearchBar } from "../../features/SearchBar/SearchBar";
import { PageContainer, MainContent, FeedBackButton } from "./PageStyled";
import { PageTitle } from "../../elements";
import { FaBullhorn } from "react-icons/fa";
import { isMobile } from "../../../isMobile";
import { PlusExclusiveBar } from "../../elements/PlusExclusiveBar";
import { useUserContext } from "../../../context/user/AuthContext";

import logoNMA from "../../../assets/images/nma-bd-jl.jpg";
import logoNMAPlus from "../../../assets/images/badge-cia-novo-roxo.png";
import { TContentType } from "../../../types/helpers";

export interface Props {
	children?: ReactNode;
	showBreadcrumb?: boolean;
	showUserAvatar?: boolean;
	showSchoolLogo?: boolean;
	pageTitle?: string;
	pageTitleHighlightText?: number | string;
	pageTitleChildren?: ReactNode;
	searchHelpText?: string;
	showSearch?: boolean;
	showMenu?: boolean;
	plusExclusive?: boolean;
	hideBanner?: boolean;
	searchContentType?: TContentType;
}

const mockData = {
	title: "Centro Integrado de Aprendizagem em Autismo",
	subtitle: "Ambiente de estudos e comunidade oficial da Academia do Autismo",
	username: "Vinícius Loureiro",
	userImageUrl: "https://gabrielbaptista.com.br/joinlearn-prototipo/static-assets/images/users/vinicius.png",
	notificationCount: 15,
	logoUrl: "https://meeting.academiadoautismo.com.br/images/nma-bd-jl.jpg",
};

const PageWrapper: React.FC<Props> = ({
	children,
	showBreadcrumb,
	pageTitle,
	pageTitleHighlightText,
	pageTitleChildren,
	showUserAvatar,
	showSchoolLogo,
	searchHelpText,
	showSearch,
	showMenu,
	searchContentType,
	...props
}: Props) => {
	const { isPlus, alunoABA } = useUserContext();
	const [showFeedbackButton, setShowfeedbackButton] = useState(true);
	useEffect(() => {
		if (localStorage.getItem("ciaautismo:feedback")) {
			setShowfeedbackButton(false);
		}
	}, []);
	const setFeedbackGiven = () => {
		setShowfeedbackButton(false);
		localStorage.setItem("ciaautismo:feedback", `true`);
	};
	return (
		<PageContainer {...props}>
			{showMenu && <Sidebar logoUrl={isPlus() ? logoNMAPlus : logoNMA} />}
			<MainContent className={showMenu ? "content" : "content noMenu"}>
				<Header
					background='primary'
					title={mockData.title}
					subTitle={mockData.subtitle}
					username={mockData.username}
					userImageUrl={mockData.userImageUrl}
					notificationCount={mockData.notificationCount}
					logoUrl={isPlus() ? logoNMAPlus : logoNMA}
					showUserAvatar={showUserAvatar}
					showSchoolLogo={showSchoolLogo}
				/>
				{props.plusExclusive && <PlusExclusiveBar />}
				{/* {!props.hideBanner && (
					<>
						{!alunoABA() && (
							<a className='bannerLargo' style={{ width: "100%" }} href='https://chat.whatsapp.com/DmQ7zMA7u6cAhiBa1X5tiv' target='_blank'>
								<img src={bannerABA22} alt='Curso ABA 2022' style={{ width: "100%" }} />
							</a>
						)}
					</>
				)} */}
				{(pageTitle || pageTitleChildren || pageTitleHighlightText) && (
					<PageTitle showBreadcrumb={showBreadcrumb} title={pageTitle} highlightText={pageTitleHighlightText}>
						{pageTitleChildren}
					</PageTitle>
				)}
				{showSearch && <SearchBar contentType={searchContentType ? searchContentType : 'all'} helpText={searchHelpText} />}
				{children}

				<footer>
					<span>
						{new Date().getFullYear()} - Academia do Autismo®{!isMobile.any() && <> -&nbsp;</>}
					</span>
					<a href='https://s3.us-east-1.amazonaws.com/materiais.academiadoautismo.com/politica-de-privacidade-cia-autismo-academia-do-autismo.pdf'>Política de Privacidade</a>
					<span className='divider'>&nbsp;|&nbsp;</span>
					<a href='https://academiadoautismo.com.br/contratos/contrato_cursos_longa_duracao.pdf'>Contrato dos Cursos Livres</a>
					{isPlus() && (
						<>
							<span className='divider'>&nbsp;|&nbsp;</span>
							<a href='https://academiadoautismo.com.br/contratos/contrato_assinatura_nma.pdf'>Termos da Assinatura</a>
						</>
					)}
				</footer>
			</MainContent>
			{showMenu && showFeedbackButton && (
				<FeedBackButton onClick={setFeedbackGiven}>
					<div className='feedbackTip'>Conta pra gente que tipo de conteúdos você gostaria de ver aqui no CIA Autismo.</div>
					<a href='https://docs.google.com/forms/d/1jIJqGEjijFlnF2JMY7xVIP1oo-t6d9qnhvh-1dYtb54' target='_blank' rel='nofollow'>
						O que você quer ver no CIA Autismo?&nbsp;
						<FaBullhorn size={isMobile.any() ? 14 : 20} />
					</a>
				</FeedBackButton>
			)}
		</PageContainer>
	);
};
PageWrapper.defaultProps = {
	showBreadcrumb: true,
	showUserAvatar: true,
	showSchoolLogo: true,
	showSearch: false,
	showMenu: true,
};

export { PageWrapper as PageContainer };
