import styled from "styled-components";
import { Link } from "react-router-dom";
import { darken } from "polished";

const FavoritosContainer = styled.div``;

const FavCategories = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	width: 100%;
	margin-bottom: 2rem;
`;

const TagFavType = styled.button`
	text-decoration: none;
	white-space: nowrap;
	border-radius: 100px;
	font-weight: 400;
	text-transform: capitalize;
	height: 35px;
	padding: 0 20px;
	line-height: 30px;
	color: #222b37;
	cursor: pointer;
	box-sizing: border-box;
	background-color: #f2f2f2;
	border: 1px solid #e0e0e0;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	&:focus {
		border: 1px solid #e0e0e0;
	}
	&:hover {
		background-color: ${darken(0.2, "#E0E0E0")};
	}
	&.active {
		background-color: ${darken(0.2, "#E0E0E0")};
		font-weight: 600;
	}
`;

const FavList = styled.div`
	a {
		display: block;
		padding: 1rem 3.6rem 1rem 1rem;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 6px;
		color: ${(props) => props.theme.color.primary};
		text-decoration: none;
		background-color: white;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
		transition: 0.3s;
		&:not(:last-child) {
			margin-bottom: 1.2rem;
		}
		&:hover {
			background: rgba(0, 0, 0, 0.1);
			box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0);
		}
		small {
			display: block;
			font-size: 0.7rem;
			margin-bottom: 4px;
			/* font-weight: 700; */
		}
		big {
			font-weight: 600;
			font-size: 1rem;
		}
		.meta {
			width: 100%;
			border-top: 1px solid rgba(0, 0, 0, 0.1);
			margin-top: 10px;
			padding-top: 10px;
			span {
				font-size: 0.8rem;
				display: block;
				&:not(:last-child) {
					margin-bottom: 4px;
				}
				color: rgba(0, 0, 0, 0.6);
			}
		}
	}
`;

const FavListItem = styled(Link)``;

const FavItemWrapper = styled.div`
	position: relative;
	button {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;
		background: none;
		cursor: pointer;
	}
	&:hover {
		a {
			background: rgba(0, 0, 0, 0.1);
			box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0);
		}
	}
`;

export {
	FavoritosContainer, //
	FavCategories,
	TagFavType,
	FavList,
	FavListItem,
	FavItemWrapper,
};
