import styled from "styled-components";
import media from "../../../../styles/media";
import { fade } from "@material-ui/core/styles/colorManipulator";

const DebateHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	min-height: 60px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	background: ${(props) => fade(props.theme.color.primary, 0.15)};

	${media.lessThan("medium")`
    padding: 10px;
    min-height: 60px;
  `}
	.link {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
	}
	a {
		text-decoration: none;
	}
`;

const DebateContent = styled.div`
	padding: 20px 30px 30px 40px;
	${media.lessThan("medium")`
    padding: 20px;
  `}
`;

export { DebateHeader, DebateContent };
