import React, {ReactNode, useRef, useEffect} from 'react';
import { CircularProgressIndicatorContainer } from './CircularProgressIndicatorStyled'
import { circleRadius, strokeWidth, circlePercentage, describeArc } from "./utils";

export interface Props {
  children?: ReactNode,
  percentage: number,
  size: number,
}

const CircularProgressIndicatorWrapper: React.FC<Props> = ({
  children,
  ...props
}: Props) => {
  const pathToMeasure = useRef<SVGPathElement>(null)
  const animaCirc = useRef<SVGAnimateElement>(null)
  let pathLength = () => {
    if(pathToMeasure.current) {
      return pathToMeasure.current.getTotalLength();
    }
    return 660
  }
  useEffect(()=>{
    if(pathToMeasure.current && animaCirc.current){
      if(pathLength() === 0) {
        pathToMeasure.current.setAttribute('stroke-dasharray', '0')
        animaCirc.current.setAttribute('from', '0')
      } else {
        pathToMeasure.current.setAttribute('stroke-dasharray', pathLength().toString())
        animaCirc.current.setAttribute('from', pathLength().toString())
      }
    }else {
      //console.log('null path')
    }
  },[])
  return (
    <CircularProgressIndicatorContainer {...props} className="radialProgress">
      <div className="percent">
        <svg>
          <circle //círculo cinza
            cx={props.size ? props.size/2 : 22.5}
            cy={props.size ? props.size/2 : 22.5}
            r={props.size ? circleRadius(props.size) : 22.5}
            strokeWidth={props.size ? strokeWidth(props.size) : 5}></circle>
          <path //arco de círculo que marca o percentual
            ref={pathToMeasure}
            fill='none'
            d={
              describeArc(
                  props.size/2, //x
                  props.size/2, //y
                  circleRadius(props.size), //raio
                  0, //ângulo inicial
                  circlePercentage(props.percentage) //ângulo final baseado no percentual
                )
            } 
            strokeWidth={props.size ? strokeWidth(props.size) : 5}>
            <animate  ref={animaCirc} attributeName='stroke-dashoffset' to='0' dur='1s' repeatCount='1'/> 
            
          </path>
          {/* <circle
            cx={props.size ? props.size/2 : 22.5}
            cy={props.size ? props.size/2 : 22.5}
            r={props.size ? circleRadius(props.size) : 22.5}
            strokeWidth={props.size ? strokeWidth(props.size) : 5}></circle> */}
        </svg>
        <div className="number">
          <span>{props.percentage ? props.percentage : 0}</span>
        </div>
      </div>
    </CircularProgressIndicatorContainer>
  );
};

export { CircularProgressIndicatorWrapper  as CircularProgressIndicator };
