/* eslint-disable class-methods-use-this */
import BaseService from "./base.service";

interface ICreateCategory {
	name: string;
	slug: string;
}

interface ICreateCategoryApi {
	nome: string;
	slug: string;
}

interface ICreateCategoryApiResponse {
	status: number;
	data: {
		id: number;
		name: string;
		slug: string;
		created: Date;
	};
}

interface IListCategoryResponse {
	status: number;
	data: IListCategory[];
}

interface IListCategoryApiResponseData {
	id: number;
	nome: string;
	slug: string;
	created: string;
}

export interface IListCategory {
	id: number;
	name: string;
	slug: string;
	created: Date;
}

const categoryService = new BaseService("comunidade/categorias");

function formatCategoryToApi(category: ICreateCategory): ICreateCategoryApi {
	const { name: nome, slug } = category;
	return { nome, slug };
}

function formatCategoryFromApi(category: IListCategoryApiResponseData): IListCategory {
	const { id, nome: name, slug } = category;
	const created = new Date(category.created);
	return {
		id,
		name,
		slug,
		created,
	};
}

export default class DebateService extends BaseService {
	constructor() {
		super("comunidade/topicos");
	}

	async createCategory(category: ICreateCategory): Promise<ICreateCategoryApiResponse> {
		const response = await categoryService.create(formatCategoryToApi(category));
		const { data } = response;
		const { id, nome: name, slug } = data;
		const created = new Date(data.created);
		return {
			status: 201,
			data: {
				id,
				name,
				slug,
				created,
			},
		};
	}

	async listCategories(): Promise<IListCategoryResponse> {
		const response = await categoryService.list();
		const data: IListCategory[] = response.map((category: IListCategoryApiResponseData) => formatCategoryFromApi(category));

		return { status: response.status, data };
	}

	async createDebate(
		categoryId: number,
		title: string,
		description?: string
	): Promise<{
		status?: number | any;
		data?: any;
		error?: undefined | boolean;
	}> {
		const createDebateService = new BaseService("comunidade/topico");
		const response = await createDebateService.create({ categoria: categoryId, titulo: title, descricao: description });

		return response;
	}
}
