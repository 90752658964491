import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@material-ui/core";
import { Typography, PageContainer, GreyContainer, InnerContentContainer } from "../../components";
import { ContentLoadingError } from "../../components/ContentLoadingError";
import { HomePageFeatureSlider } from "../../components/features/HomePageFeatureSlider";
import {
	CourseCardLoading,
	SubscriptionBanner
} from "../../components/elements";
import { CoursesContainer } from "./CoursesStyled";
import { CategoriesContainer, FilterTitle } from "../Articles/ArticlesStyled";

import clsx from "clsx";

import CourseService from "../../services/course.service";

import { CourseCategorySlider } from "../../components/features/CourseCategorySlider";
import { CourseCardsBoxLayout } from "../../helpers/coursesCardBox";

const Courses: React.FC = ({ ...props }) => {
	const [courses, setCourses] = useState([]);
	const [premiumCourses, setPremiumCourses] = useState([]);
	const [availableCourses, setAvailableCourses] = useState([]);
	const [loadingCourses, setLoadingCourses] = useState(true);
	const [courseCategories, setCourseCategories] = useState<string[]>([]);
	const [categoryFilter, setCategoryFilter] = useState("");
	const [filteredCourses, setFilteredCourses] = useState([]);
	const [error, setError] = useState(false);

	const filterOutCoursesWOAccess = (categorias: any[]) => {
		// Remove cursos sem acesso do array de cursos do usuário
		return categorias.map((categoria: any) => {
			const nCursos = categoria.cursos.filter((c: any) => c.tem_acesso)
			return { ...categoria, cursos: nCursos }
		})
	}

	const getCategories = (response: any) => {
		const categories = response
			.map((categoria: any) => {
				if (categoria.cursos.length > 0) {
					return categoria.nome;
				} else {
					return;
				}
			})
			.filter((categoria: any) => {
				return categoria !== undefined;
			});

		setCourseCategories(categories.filter((c: any) => c !== "Pós Graduação"));
	}

	const loadCursos = useCallback(async () => {
		try {
			const courseService = new CourseService();
			const response = await courseService.list();

			getCategories(response)

			let premium = response.filter((categoria: any) => {
				return categoria.nome === "Cursos Premium";
			})

			let normal = response.filter((categoria: any) => {
				return categoria.nome !== "Cursos Premium" && categoria.nome !== "Pós Graduação";
			})

			premium = filterOutCoursesWOAccess(premium)
			normal = filterOutCoursesWOAccess(normal)

			let available: any = [{
				cursos: [],
				nome: "Cursos com Inscrições Abertas"
			}]

			for (let catIndex in response) { // Gera a lista de cursos disponíveis para compra
				for (let cursoIndex in response[catIndex].cursos) {
					if (
						!response[catIndex].cursos[cursoIndex].tem_acesso &&
						response[catIndex].cursos[cursoIndex].link_vendas &&
						response[catIndex].cursos[cursoIndex].inscricoes_abertas
					) {
						available[0].cursos.push(response[catIndex].cursos[cursoIndex])
					}
				}
			}


			setAvailableCourses(available)
			setCourses(normal);
			setPremiumCourses(premium);
			setLoadingCourses(false);
		} catch (error) {
			setError(true);
			setLoadingCourses(false);
		}
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		loadCursos();
	}, []);

	useEffect(() => {
		if (categoryFilter === "Cursos Premium") {
			setFilteredCourses(premiumCourses);
		} else if (categoryFilter) {
			const filtered = courses.filter((category: any) => {
				return category.nome === categoryFilter;
			});
			setFilteredCourses(filtered);
		} else {
			return;
		}
	}, [categoryFilter]);

	return (
		<PageContainer showBreadcrumb={false} pageTitle='Cursos' showSearch={false}>
			<FilterTitle>Categorias:</FilterTitle>
			<CategoriesContainer>
				{courseCategories.map((category) => (
					<span
						key={category}
						className={clsx({
							"active": categoryFilter === category,
							"filterTag": true,
						})}
						onClick={() => {
							categoryFilter === category ? setCategoryFilter("") : setCategoryFilter(category);
						}}
						title={categoryFilter === category ? "Limpar Filtro" : category}>
						{category}
					</span>
				))}
			</CategoriesContainer>
			<GreyContainer>
				<InnerContentContainer>
					{/* <SubscriptionBanner /> */}
					<HomePageFeatureSlider location="CURSOS" />

					<CoursesContainer>
						{loadingCourses && (
							<>
								<Box
									width='100%'
									display='grid'
									gridTemplateColumns={{
										xs: "100%",
										sm: "100%",
										md: "24% 24% 24% 24%",
									}}
									gridRowGap='15px'
									gridColumnGap='1%'>
									<CourseCardLoading /> <CourseCardLoading />
									<CourseCardLoading /> <CourseCardLoading />
								</Box>
							</>
						)}

						{error ? (
							<>
								<ContentLoadingError
									refresh={() => {
										loadCursos();
									}}
									loadedResourceErrorMsg='a lista de cursos disponíveis'
								/>
							</>
						) : (
							<>
								{categoryFilter ? (
									<>
										{filteredCourses?.length > 0 &&
											filteredCourses.map((result: any) => (
												<React.Fragment key={result.slug}>
													{CourseCardsBoxLayout(result)}
												</React.Fragment>
											))}
									</>
								) : (
									<>
										{premiumCourses?.length > 0 &&
											premiumCourses.map((result: any) => (
												<React.Fragment key={result.slug}>
													{CourseCardsBoxLayout(result)}
												</React.Fragment>
											))}
										{courses?.length > 0 &&
											courses.map((result: any) => (
												<React.Fragment key={result.slug}>
													{result.cursos.length > 0 && (
														<>
															<div key={result.slug} className='resultDiv'>
																<Typography margin='0 0 30px 0' variant='title' color='#014491'>
																	{result.nome}
																	<span className='coursesCounter'>{result.cursos.length}</span>
																</Typography>
																<CourseCategorySlider isOpenForSalesSlider={false} courseList={result.cursos} />
															</div>
														</>
													)}

												</React.Fragment>
											))}
									</>
								)}
							</>
						)}
						{availableCourses?.length > 0 &&
							availableCourses.map((result: any, index: number) => (
								<React.Fragment key={index}>
									{result.cursos.length > 0 && (
										<>
											<div key={index} className='resultDiv'>
												<Typography margin='0 0 30px 0' variant='title' color='#014491'>
													{result.nome}
													<span className='coursesCounter'>{result.cursos.length}</span>
												</Typography>
												<CourseCategorySlider isOpenForSalesSlider courseList={result.cursos} />
											</div>
										</>
									)}

								</React.Fragment>
							))}
					</CoursesContainer>
				</InnerContentContainer>
			</GreyContainer>
		</PageContainer>
	);
};

export { Courses };
