import styled from "styled-components";
import { Props } from "./index";

const PageTitleContainer = styled.div<Props>`
	display: flex;
	height: 70px;
	background: #fff;
	justify-content: center;
	flex-direction: column;
	position: relative;
	z-index: 99;
`;
export { PageTitleContainer };
