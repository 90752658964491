/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import BaseService from "./base.service";

export interface IStudent {
	id: number;
	userId: number;
	name: string;
	avatar: string;
	address: {
		number: string;
		complement: string;
		district: string;
		city: string;
		state: string;
		postalCode: string;
	};
	cellphone?: string;
	cpf: string;
	email: string;
	showCellphone: boolean;
	showPresentation: boolean;
	showEmail: boolean;
	showAddress: boolean;
	showPhone: boolean;
	tenantId: string;
	recorte: string;
	check: [];
}

interface ICursoConcluido {
	id: number;
	curso: string;
	usuario: number;
	created: string;
}

interface ICertificadoEmitido {
	curso: string;
	id: number;
	uniqueidentifier: string;
	usuario: number;
}
export interface IStudentApiResponse {
	id: number;
	nome: string;
	bairro: string;
	celular?: string;
	cep: string;
	check: [];
	cidade: string;
	complemento: string;
	cpf: string;
	email: string;
	exibir_celular: boolean;
	exibir_dados_profissionais: boolean;
	exibir_email: boolean;
	exibir_endereco: boolean;
	exibir_telefone: boolean;
	numero: string;
	tenant_id: string;
	uf: string;
	usuario_id: number;
	foto: string;
	recorte: string;
	cursos_concluidos: ICursoConcluido[];
	certificados: ICertificadoEmitido[];
	lng: number;
	ltd: number;
	logradouro: string;
}

export default class PublicProfileService extends BaseService {
	constructor() {
		super(`usuarios/perfil-publico`);
	}

	formatDetailData(student: IStudentApiResponse): IStudent {
		return {
			id: student.id,
			userId: student.usuario_id,
			name: student.nome,
			avatar: student.foto || "",
			address: {
				number: student.numero || "",
				complement: student.complemento || "N/I",
				district: student.bairro || "N/I",
				city: student.cidade || "N/I",
				state: student.uf || "N/I",
				postalCode: student.cep || "N/I",
			},
			cellphone: student.celular || "N/I",
			cpf: student.cpf || "N/I",
			email: student.email || "N/I",
			showCellphone: student.exibir_celular,
			showPresentation: student.exibir_dados_profissionais,
			showEmail: student.exibir_email,
			showAddress: student.exibir_endereco,
			showPhone: student.exibir_telefone,
			tenantId: student.tenant_id,
			recorte: student.recorte || "N/I",
			check: [],
		};
	}
}
