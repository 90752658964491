import styled from 'styled-components'
import { Props } from './index';

const DataChartContainer = styled.div<Props>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
`

const DataChartHandlers = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`
export { DataChartContainer, DataChartHandlers }