import React from 'react';

import { IoMdCreate } from 'react-icons/io';
import { IconWrapper } from '../IconWrapper';

export interface Props extends React.HTMLAttributes<HTMLElement> {
    size?: string
    rounded?: boolean
    primaryBorder?: boolean
}

const EditIconWrapper: React.FC<Props> = ({
  children,
  rounded,
  primaryBorder,
  ...props
}: Props) => (
  <IconWrapper
    {...props}
    size={props.size}
    rounded={rounded}
    primaryColor
    filled={false}
    primaryBorder={primaryBorder}
    tooltipText="Editar"
    icon={<IoMdCreate size={16} />}
  />
);

EditIconWrapper.defaultProps = {
  size: '28px',
  rounded: true,
  primaryBorder: false
};

export { EditIconWrapper as EditIcon };
