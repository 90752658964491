import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MainCoursesHomeSliderContainer, FeaturedCourse } from "./MainCoursesHomeSliderStyled";
import Swiper, { Pagination, Autoplay } from "swiper";
import { Swiper as Slider, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { isMobile } from "../../../../isMobile";
import CourseService from "../../../../services/course.service";
import { ContentLoadingError } from "../../../../components/ContentLoadingError";

import { Box } from "@material-ui/core";
import { CourseCardLoading } from "../../../elements/";

export interface Props { }

Swiper.use([Pagination, Autoplay]);

const MainCoursesHomeSliderWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const [courses, setCourses] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [slidesNumber, setSlidesNumber] = useState(1);

	const rng = useCallback((min, max) => {
		return Math.round(Math.random() * (max - min) + min);
	}, []);

	const loadCursos = useCallback(async () => {
		try {
			if (!loading) {
				setLoading(true);
			}

			const courseService = new CourseService();

			const response = await courseService.list();

			const listaCursos = response
				.filter((categoria: any) => {
					return categoria.nome === "Cursos Premium";
				})
				.map((curso: any) => {
					return curso.cursos;
				})
				.flat()
				.filter((curso: any) => {
					return curso.tem_acesso
				});

			const qtdPremium = listaCursos.length;

			if (qtdPremium >= 3) {
				setCourses(listaCursos);
			} else {
				const other = response
					.filter((categoria: any) => {
						return categoria.nome !== "Cursos Premium";
					})
					.map((curso: any) => {
						return curso.cursos;
					})
					.flat()
					.filter((curso: any) => {
						return curso.tem_acesso
					});

				const howManyToThree = 3 - qtdPremium;
				const indexesAlreadySorted: any[] = [];
				const includeInCourses = [];

				for (let i = 0; i < howManyToThree; i++) {
					let index = rng(0, other.length);
					if (indexesAlreadySorted.includes(index)) {
						indexesAlreadySorted.push(index + 2);
						includeInCourses.push(other[index + 2]);
					} else {
						indexesAlreadySorted.push(index);
						includeInCourses.push(other[index]);
					}
				}

				setCourses([...listaCursos, ...includeInCourses]);
			}

			setLoading(false);
		} catch (error) {
			setError(true);
			setLoading(false);
		}
	}, [loading, rng]);

	useEffect(() => {
		isMobile.any() ? setSlidesNumber(1) : setSlidesNumber(3);
		loadCursos();
		// eslint-disable-next-line
	}, []);

	return (
		<MainCoursesHomeSliderContainer {...props}>
			{loading ? (
				<>
					<>
						<Box
							width='100%'
							display='grid'
							gridTemplateColumns={{
								xs: "100%",
								sm: "100%",
								md: "33% 33% 33%",
							}}
							gridRowGap='15px'
							gridColumnGap='1%'>
							<CourseCardLoading /> <CourseCardLoading />
							<CourseCardLoading />
						</Box>
					</>
				</>
			) : (
				<>
					{error ? (
						<>
							<ContentLoadingError
								refresh={() => {
									loadCursos();
								}}
								loadedResourceErrorMsg='os seus cursos principais'
							/>
						</>
					) : (
						<>
							{courses.length > 0 ? (
								<>
									{courses.length <= 3 ? (
										<>
											<Slider autoplay={isMobile.any() ? true : false} slidesPerView={slidesNumber} spaceBetween={30}>
												{courses.map((course: any) => {
													return (
														<SwiperSlide key={course.id}>
															<FeaturedCourse>
																<img src={course.imagem} alt={course.nome} />
																<Link to={`/cursos/${course.slug}`} title={course.nome}>
																	Acesse Aqui
																</Link>
															</FeaturedCourse>
														</SwiperSlide>
													);
												})}
											</Slider>
										</>
									) : (
										<>
											<Slider loop pagination={{ clickable: true }} autoplay={isMobile.any() ? true : false} slidesPerView={isMobile.any() ? 1 : 3} spaceBetween={30}>
												{courses.map((course: any) => {
													return (
														<SwiperSlide key={course.id}>
															<FeaturedCourse>
																<img src={course.imagem} alt={course.nome} />
																<Link to={`/cursos/${course.slug}`} title={course.nome}>
																	Acesse Aqui
																</Link>
															</FeaturedCourse>
														</SwiperSlide>
													);
												})}
											</Slider>
										</>
									)}
								</>
							) : (
								<>
									<ContentLoadingError
										refresh={() => {
											loadCursos();
										}}
										loadedResourceErrorMsg='os seus cursos principais'
									/>
								</>
							)}
						</>
					)}
				</>
			)}
		</MainCoursesHomeSliderContainer>
	);
};

MainCoursesHomeSliderWrapper.defaultProps = {};

export { MainCoursesHomeSliderWrapper as MainCoursesHomeSlider };
