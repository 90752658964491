import React from 'react';

import { IconWrapper } from '../IconWrapper';


export interface Props extends React.HTMLAttributes<HTMLElement> {
    rounded?: boolean;
    filled?: boolean;
    backgroundColor?: string;
    color?: string;
    size?: string;
    tooltipText?: string;
    primaryColor?: boolean;
}


const CourseInProgressIconWrapper: React.FC<Props> = ({
  children,
  ...props
}: Props) => (
  <IconWrapper
    {...props}
    tooltipText={props.tooltipText}
    backgroundColor={props.backgroundColor}
    size={props.size}
    rounded={props.rounded}
    primaryColor={props.primaryColor}
    icon={(
      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="23" viewBox="0 0 33 23" fill={props.color}>
        <path d="M9.96289 11.7167C9.96289 5.4939 15.0234 0.430664 21.2462 0.430664C27.469 0.430664 32.5294 5.4939 32.5294 11.7167C32.5294 17.9395 27.469 23 21.2462 23C15.0234 23 9.96289 17.9395 9.96289 11.7167Z" />
        <path d="M20.6681 3.27731C20.5691 3.27874 20.4715 3.29965 20.3806 3.33885C20.2898 3.37805 20.2075 3.43478 20.1386 3.50577C20.0697 3.57676 20.0154 3.66064 19.9789 3.75261C19.9424 3.84458 19.9244 3.94282 19.9259 4.04176V10.8217C19.926 11.0208 20.005 11.2117 20.1455 11.3527L24.6655 15.8727C24.7345 15.9463 24.8177 16.0052 24.91 16.0461C25.0023 16.0869 25.1018 16.1088 25.2028 16.1104C25.3037 16.112 25.4039 16.0934 25.4975 16.0555C25.591 16.0176 25.676 15.9613 25.7474 15.8899C25.8188 15.8185 25.8751 15.7335 25.9129 15.64C25.9508 15.5464 25.9695 15.4462 25.9679 15.3453C25.9663 15.2444 25.9444 15.1448 25.9036 15.0525C25.8627 14.9602 25.8037 14.877 25.7301 14.808L21.4325 10.5076V4.04176C21.434 3.94095 21.4153 3.84087 21.3774 3.74744C21.3395 3.65401 21.2833 3.56911 21.212 3.49782C21.1407 3.42653 21.0558 3.37031 20.9624 3.33243C20.869 3.29454 20.7689 3.27579 20.6681 3.27731V3.27731Z" fill="white" />
        <path d="M1.25919 10.9495C1.0594 10.9495 0.867784 11.0288 0.726506 11.1701C0.585228 11.3114 0.505859 11.503 0.505859 11.7028C0.505859 11.9026 0.585228 12.0942 0.726506 12.2355C0.867784 12.3768 1.0594 12.4561 1.25919 12.4561H8.03639C8.23618 12.4561 8.4278 12.3768 8.56908 12.2355C8.71035 12.0942 8.78972 11.9026 8.78972 11.7028C8.78972 11.503 8.71035 11.3114 8.56908 11.1701C8.4278 11.0288 8.23618 10.9495 8.03639 10.9495H1.25919Z" />
        <path d="M2.76603 6.42957C2.56623 6.42957 2.37462 6.50894 2.23334 6.65021C2.09206 6.79149 2.0127 6.9831 2.0127 7.1829C2.0127 7.38269 2.09206 7.5743 2.23334 7.71558C2.37462 7.85686 2.56623 7.93623 2.76603 7.93623H8.78989C8.98969 7.93623 9.1813 7.85686 9.32258 7.71558C9.46386 7.5743 9.54323 7.38269 9.54323 7.1829C9.54323 6.9831 9.46386 6.79149 9.32258 6.65021C9.1813 6.50894 8.98969 6.42957 8.78989 6.42957H2.76603Z" />
        <path d="M0.753335 15.4667C0.553538 15.4667 0.361924 15.546 0.220647 15.6873C0.0793689 15.8286 0 16.0202 0 16.22C0 16.4198 0.0793689 16.6114 0.220647 16.7527C0.361924 16.894 0.553538 16.9733 0.753335 16.9733H8.78979C8.98959 16.9733 9.1812 16.894 9.32248 16.7527C9.46375 16.6114 9.54312 16.4198 9.54312 16.22C9.54312 16.0202 9.46375 15.8286 9.32248 15.6873C9.1812 15.546 8.98959 15.4667 8.78979 15.4667H0.753335Z" />
        <path d="M5.18986 1.90955C4.99006 1.90955 4.79845 1.98892 4.65717 2.13019C4.51589 2.27147 4.43652 2.46308 4.43652 2.66288C4.43652 2.86267 4.51589 3.05428 4.65717 3.19556C4.79845 3.33684 4.99006 3.41621 5.18986 3.41621H11.803C12.0028 3.41621 12.1945 3.33684 12.3357 3.19556C12.477 3.05428 12.5564 2.86267 12.5564 2.66288C12.5564 2.46308 12.477 2.27147 12.3357 2.13019C12.1945 1.98892 12.0028 1.90955 11.803 1.90955H5.18986Z" />
        <path d="M5.77628 19.9867C5.57649 19.9867 5.38487 20.0661 5.2436 20.2073C5.10232 20.3486 5.02295 20.5402 5.02295 20.74C5.02295 20.9398 5.10232 21.1314 5.2436 21.2727C5.38487 21.414 5.57649 21.4934 5.77628 21.4934H11.8029C12.0027 21.4934 12.1943 21.414 12.3356 21.2727C12.4769 21.1314 12.5563 20.9398 12.5563 20.74C12.5563 20.5402 12.4769 20.3486 12.3356 20.2073C12.1943 20.0661 12.0027 19.9867 11.8029 19.9867H5.77628Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3.51801 16.2206C3.51801 16.3195 3.49853 16.4174 3.46068 16.5088C3.42283 16.6001 3.36736 16.6832 3.29742 16.7531C3.22748 16.8231 3.14446 16.8785 3.05308 16.9164C2.96171 16.9542 2.86377 16.9737 2.76486 16.9737C2.66596 16.9737 2.56802 16.9542 2.47665 16.9164C2.38527 16.8785 2.30224 16.8231 2.23231 16.7531C2.16237 16.6832 2.1069 16.6001 2.06905 16.5088C2.0312 16.4174 2.01172 16.3195 2.01172 16.2206C2.01172 16.0208 2.09107 15.8293 2.23231 15.688C2.37355 15.5468 2.56512 15.4674 2.76486 15.4674C2.96461 15.4674 3.15617 15.5468 3.29741 15.688C3.43866 15.8293 3.51801 16.0208 3.51801 16.2206Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.5307 2.66405C6.5307 2.76295 6.51122 2.86086 6.47338 2.95223C6.43553 3.04361 6.38005 3.12666 6.31011 3.1966C6.24018 3.26653 6.15715 3.32202 6.06578 3.35987C5.9744 3.39772 5.87646 3.41716 5.77756 3.41716C5.67865 3.41716 5.58072 3.39772 5.48934 3.35987C5.39797 3.32202 5.31494 3.26653 5.245 3.1966C5.17507 3.12666 5.11959 3.04361 5.08174 2.95223C5.04389 2.86086 5.02441 2.76295 5.02441 2.66405C5.02442 2.4643 5.10377 2.27273 5.24501 2.13149C5.38625 1.99025 5.57781 1.91089 5.77756 1.91089C5.9773 1.91089 6.16887 1.99025 6.31011 2.13149C6.45135 2.27273 6.5307 2.4643 6.5307 2.66405Z" />
      </svg>
            )}
  />
);

CourseInProgressIconWrapper.defaultProps = {
  rounded: true,
  size: '26px',
  filled: false,
  primaryColor: true,
};

export { CourseInProgressIconWrapper as CourseInProgressIcon };
