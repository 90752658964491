import styled from "styled-components";

const CourseCategorySliderContainer = styled.div`
	display: flex;
	position: relative;

	.swiper-container {
		min-height: 400px;
		padding-left: 40px;
	}

	.swiper-button-prev,
	.swiper-button-next {
		transform: translateY(-50%);
		top: 50%;
		width: 30px;
		height: 100%;
		font-weight: 900;
	}

	.swiper-button-prev {
		left: 0;
		background: linear-gradient(to right, rgba(245, 248, 249, 1), rgba(245, 248, 249, 0));
	}

	.swiper-button-next {
		right: 0;
		background: linear-gradient(to left, rgba(245, 248, 249, 1), rgba(245, 248, 249, 0));
	}

	a,
	.courseLink {
		min-width: 336px;
		text-decoration: none;
		position: relative;
		transition: 0.3s ease;
		top: 0;
		h1 {
			transition: 0.3s ease;
		}
		&:hover {
			top: -10px;
			h1 {
				color: ${(props) => props.theme.color.primary};
			}
		}
	}
	.resultDiv {
		margin: 0 0 50px;
	}
	.coursesCounter {
		color: #f2c94c;
		padding-left: 8px;
	}
`;
export { CourseCategorySliderContainer };
