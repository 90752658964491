import React from 'react';
import { Box } from '@material-ui/core';
import { Typography } from '../../..';
import { CircularAvatarPhoto, CardWrapper } from '../../../elements';

import { Activity } from '../../../../types/activity';


export interface Props {
  activity: Activity
  width?: string
}

const ActivityCardWrapper: React.FC<Props> = ({
  activity,
  width,
  ...props
}: Props) => (
  <CardWrapper width={width} height="100%" minHeight="190px">
    <Box display="flex" width="100%">
      <div>
        <CircularAvatarPhoto innerBorderSize="3px" outerBorderSize="3px" size="65px" borderType="single" avatarImg={activity.userImageUrl} avatarName={activity.userName} />
      </div>
      <Box marginLeft="20px" display="flex" flexDirection="column">
        <Typography variant="title" size="1rem">{activity.userName}</Typography>
        <Typography variant="text" size="0.625rem" primary>{activity.moduleName}</Typography>
        <Typography variant="text" size="0.625rem" primary>
          Aula:
          {' '}
          {activity.lessonName}
        </Typography>
        <Typography variant="subtitle" size="0.625rem" primary>{activity.date}</Typography>
        <Typography variant="title" size="1rem" margin="15px 0 0 0">{activity.text}</Typography>
      </Box>
    </Box>
  </CardWrapper>
);

ActivityCardWrapper.defaultProps = {
  width: '100%',
};

export { ActivityCardWrapper as ActivityCard };
