/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import BaseService from "./base.service";
import { stringToDate } from "../lib/date";

export interface ILesson {
	id: number;
	name: string;
	releaseDate: string;
	materialsCount: number;
	videoUrl: string;
	description: string;
	materials: any[];
	tags: string[] | null;
}

interface ILessonResponseApi {
	id: number;
	nome: string;
	created: string;
	video: null | string;
	descricao: string;
	materiais: any[];
	tags: string[] | null;
}

export default class ProdLessonService extends BaseService {
	constructor() {
		super("prod/aulas");
	}

	formatLessonData(lesson: ILessonResponseApi): ILesson {
		return {
			id: lesson.id,
			name: lesson.nome,
			releaseDate: lesson.created,
			materialsCount: lesson.materiais.length,
			videoUrl: lesson.video || "",
			description: lesson.descricao,
			materials: lesson.materiais,
			tags: lesson.tags,
		};
	}

	formatListData(lessons: ILessonResponseApi[]): ILesson[] {
		const lessons_formated = lessons.map((lesson) => this.formatLessonData(lesson));

		return lessons_formated;
	}
}
