import BaseService from "./base.service";

export default class ArticlesService extends BaseService {
	constructor(page) {
		if (page) {
			super(`artigos/?page=${page}`);
		} else {
			super("artigos");
		}
	}
}
