import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Emoji } from "emoji-mart";

import {
  Typography,
  PageContainer,
  InnerContentContainer,
  GreyContainer,
  Input,
} from "../../components";
import {
  Alert,
  CourseCard,
  Button,
  FeatureBanner,
  SquareCard,
} from "../../components/elements";
import {
  DashboardCourseAccordion,
  ActivityCard,
  SystemActivityCard,
  ReactionsActivityCard,
  MessageRepliedActivityCard,
  ResumeActivityCard,
} from "../../components/features/Common";

import { courseData, courseCardsInfo } from "../../mockData/courseDataMock";
import { activitiesData } from "../../mockData/activityDataMock";
import { userBadgerListByCourse } from "../../mockData/badgesMock";

const badgesToShow = userBadgerListByCourse.filter(
  (badge) => badge.scope === "course" // trazer apenas badges com escopo curso
);

// console.log('badgeslist', userBadgerListByCourse);
// console.log('badgeslist to show', badgesToShow);

const StudentDashboard: React.FC = () => (
  <PageContainer showBreadcrumb={false} searchHelpText="Em toda plataforma">
    <FeatureBanner
      titulo=""
      subTitle=""
      callToAction="Saiba Mais"
      image="https://meeting.academiadoautismo.com.br/images/banner-joinlearn.png"
    />
    <GreyContainer padding="20px 20px 40px 20px">
      <InnerContentContainer>
        <DashboardCourseAccordion course={courseData({})}>
          {/* <Alert startAdornment={<Emoji emoji="rocket" size={24} />} text="Esse é um alerta enviado pelo produtor ou sistema" variant="warning" /> */}
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <Grid container spacing={1}>
                {activitiesData().map((activity) => (
                  <Grid item xs={12} md={4}>
                    {activity.type === "system_message" && (
                      <SystemActivityCard activity={activity} />
                    )}
                    {activity.type === "reactions" && (
                      <ReactionsActivityCard activity={activity} />
                    )}
                    {activity.type === "comment" && (
                      <ActivityCard activity={activity} />
                    )}
                    {activity.type === "debate_replied" && (
                      <MessageRepliedActivityCard activity={activity} />
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <ResumeActivityCard
                badges={badgesToShow}
                activities={activitiesData().slice(0, 2)}
                progress={75}
                releaseDate={courseData({}).releaseDate}
              />
            </Grid>
          </Grid>
        </DashboardCourseAccordion>
      </InnerContentContainer>
    </GreyContainer>
    {/* <InnerContentContainer>
      <Box margin="40px 0 40px 0">
        <Box margin="0 0 40px 0">
          <Typography variant="title" size="1.25rem" align="center">#AcademiaDoAutismo também recomenda</Typography>
          <Typography variant="subtitle" size="0.875rem" align="center">Realize esses cursos para aprofundar ainda mais seus conhecimentos</Typography>
        </Box>
        <Grid container spacing={3} xs={12} style={{ margin: 0 }}>
          {' '}
          {courseCardsInfo().map((course) => <Grid item xs={12} lg={3}><CourseCard course={course} /></Grid>)}
        </Grid>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" color="primary" margin="30px 0 0 0">veja todos os cursos</Button>
        </Box>
      </Box>
    </InnerContentContainer> */}
  </PageContainer>
);

export { StudentDashboard };
