import styled from "styled-components";

const ClassRatingContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	.numberOfRatings {
		opacity: 0.6;
	}
`;
const RatingTag = styled.small`
	/* width: 100%; */
	display: block;
	text-align: center;
	font-size: 0.8rem;
	opacity: 0.6;
	text-transform: uppercase;
	font-weight: 700;
	/* margin-bottom: 0.3rem; */
`;

export { ClassRatingContainer, RatingTag };
